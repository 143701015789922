import { useContext, useState } from 'react'
import { SelectField } from '@jsluna/react'
import { AppContext } from '../../../context/AppContext'

const DropdownQuestion: React.FC<{
  questionDetails: any
  verificationTriggered: number
  passExtraVerification:(verified: boolean)=>void
}> = ({ questionDetails, verificationTriggered, passExtraVerification }): JSX.Element => {
  const context = useContext(AppContext)
  const [selectedOption, setSelectedOption] = useState('')

  const verify = () => {
    if (verificationTriggered && selectedOption === '') {
      passExtraVerification(false)
      return false
    }
    if (verificationTriggered === 0){
      passExtraVerification(false)
      return true
    }
    context!.createWO!.step2 = `${context!.createWO!.step2}. ${questionDetails.displayQuestion} ${selectedOption}`
    passExtraVerification(true)
    return true
  }

  return (
    <div>
      <SelectField
        name={questionDetails.displayQuestion}
        label={questionDetails.displayQuestion}
        options={questionDetails.displayAnswer}
        onChange={(val: any) => setSelectedOption(val.target.value)}
        value={selectedOption}
        error={
          verify()
            ? false
            : 'Please help us to help you by answering this question'
        }
      />
    </div>
  )
}

export default DropdownQuestion
