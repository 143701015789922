/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { ArrowLeft } from '@jsluna/icons'
import { BackLinkType } from '../types/BackLinkType'

const BackLink = ({ linkTo, className = '' }: BackLinkType) => {
  return (
    <div className={`backLink ${className}`}>
      {linkTo ? (
        <Link to={linkTo}>
          <ArrowLeft /> Back
        </Link>
      ) : (
        <a style={{ cursor: 'pointer' }} onClick={() => history.back()}>
          <ArrowLeft /> <span>Back</span>
        </a>
      )}
    </div>
  )
}

export default BackLink
