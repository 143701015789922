import { Container } from '@jsluna/react'
import { InfoCircle, Cancel } from '@jsluna/icons'
import { returnTimeDifference } from '../../utils/functions'
import { useState } from 'react'
import helpNumbers from '../../utils/helpNumbers'

const FailureNotification = ({notificationDetails}: any) => {
  const [closeNotification, setCloseNotification] = useState(false)

if(closeNotification){
  return null
}

  return (
    <div className="alert warning-alert" role="alert">
      <Container size="xs">
        <div className="notification-container warning-alert">
          <div>
            <span className="d-block">
              <InfoCircle className="ln-u-margin-left  icon-right" />
              {`Unable to process `}
              <strong><span>{notificationDetails.description} </span> </strong>
              {`problem sent ${returnTimeDifference(notificationDetails.maintenanceEndTime)}.`}
              <div className="second-div">
                {'Please call the FM Control Centre on '}
                <strong>
                  <span className="recall-rejected">{helpNumbers.FMControlCentre} </span>
                </strong>
                {` to report your problem.`}
              </div>
            </span>
          </div>
          <div>
            <Cancel
                onClick={() => setCloseNotification(true)}
              className="ln-u-margin-left"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FailureNotification
