import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { UserDetailsType } from '../types/UserDetails/UserDetailsType'
import { UpdateUserDetails } from '../types/UserDetails/UpdateUserDetails'

async function userValidate(
  apiClient: ApiClient,
  payload: string,
  siteId: string | null,
  referer = false
): Promise<UserDetailsType> {
  const response = await webApi.get<UserDetailsType>(apiClient, {
    url: `userManagement/${payload}?currentSite=${siteId}&referer=${referer}`,
    params: []
  })
  const responseData = await response
  return responseData
}

const updateStoreDetails = async (
  apiClient: ApiClient,
  siteID: string,
  siteName: string,
  referer = false
) => {
  const response = await webApi.get<UpdateUserDetails>(apiClient, {
    url: `userManagement/${siteID}/${siteName}?referer=${referer}`,
    params: []
  })

  const responseData = await response
  return responseData
}

const userActions = {
  userValidate,
  updateStoreDetails
}

export default userActions
