import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { NotificationType } from '../types/Notifications/NotificationsType'

const getNotifications = async (
  apiClient: ApiClient,
  siteId: string
): Promise<NotificationType[]> =>
  await webApi.get<NotificationType[]>(apiClient, {
    url: `userManagement/Notifications?siteNum=${siteId}`,
    params: []
  })

const notification = {
  getNotifications
}

export default notification
