import ReactGA from 'react-ga'
import {
  Form,
  FilledButton,
  SelectField,
  TextAreaField,
  OutlinedButton,
  GridWrapper,
  GridItem,
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/react'
import { WODetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { useContext, useState } from 'react'
import { AppContext, useApiClient } from '../../context/AppContext'
import workOrderDetailsApi from '../../api/workOrdersDetailsApi'
import { useNavigate } from 'react-router'
import ImageUploader from '../WOlogging/ImageUploader'
import imagesApi from '../../api/imagesApi'
import moment from 'moment'

const RecallForm: React.FC<{ workOrderDetails: WODetails }> = ({
  workOrderDetails
}): JSX.Element => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigateTo = useNavigate()
  const [clicked, setClicked] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [recalled, setRecalled] = useState(false)
  const [errorTextInput, setErrorTextInput] = useState(false)
  const [errorRecallStatus, setErrorRecallStatus] = useState(false)
  const [errorEquipmentStatus, setErrorEquipmentStatus] = useState(false)
  const [recallStatus, setRecallStatus] = useState('')
  const [equipmentStatus, setEquipmentStatus] = useState('')
  const [imagesVerified, setImagesVerified] = useState(false)
  const [imageFileName, setImageFileName] = useState('')

  const RECALL_STATUS = [
    { label: 'Work not completed', value: 'Work not completed' },
    {
      label: 'Work not completed satisfactorily',
      value: 'Work not completed satisfactorily'
    }
  ]
  const EQUIPMENT_STATUS = [
    { label: 'Working', value: 'Working' },
    { label: 'Not working', value: 'Not Working' }
  ]

  const trackGA = () => {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Recall Requested',
        action: 'Requesting a recall',
        label: 'Recall Requested'
      })
    }
  }

  const validateInputs = () => {
    if (recallStatus === '') {
      setErrorRecallStatus(true)
    }
    if (workOrderDetails.isEquipmentWorking && equipmentStatus === '') {
      setErrorEquipmentStatus(true)
    }
    if (textInput === '') {
      setErrorTextInput(true)
    }

    if (
      recallStatus === '' ||
      (workOrderDetails.isEquipmentWorking && equipmentStatus === '') ||
      textInput === ''
    ) {
      return false
    }

    return true
  }

  const triggerCancel = () => {
    setClicked(false)
    setRecallStatus('')
    setErrorRecallStatus(false)
    setEquipmentStatus('')
    setErrorEquipmentStatus(false)
    setTextInput('')
    setErrorTextInput(false)
  }

  const uploadImages = async (
    imageArray: ImageData[] | Object[],
  ) => {
    let siteName = workOrderDetails.siteName!
    imageArray.map(async (data: any, index) => {
      const formData = new FormData()
      const date = new Date()
      const currentTime = moment(date).format('YYYYMMDDHHmmss')
      const filename = `#FMassist_${siteName}_${workOrderDetails.workOrderNumber}_R${index + 1}_${currentTime}.${data.name.split('.').pop()}`
      setImageFileName(filename)
      formData.append('file', data, filename)
      await imagesApi
        .uploadImages(apiClient, formData, workOrderDetails.workOrderNumber!, siteName)
        .then(response => {
          if (response.status === 200) {
            return 'uploaded'
          }
          return 'failed'
        })
    })
  }

  const formattedComment = (comment: string) => {
    if (imagesVerified && imageFileName !== '') {
      return `${comment}  Image Added: ${imageFileName}`
    }
    return comment
  }

  const triggerRecall = async () => {
    const pass = validateInputs()

    if (pass) {
      setRecalled(true)
      trackGA()

      if (context?.createWO?.images && context?.createWO?.images.length > 0) {
        await uploadImages(context?.createWO?.images)
      }

      let recallWorkOrderData = {
        userName: `${context?.user?.firstName} ${context?.user?.lastName}`,
        reviewAction: 'Reject',
        workOrderNumber: workOrderDetails.workOrderNumber,
        comments: formattedComment(textInput),
        recallReason: recallStatus,
        operationalStatus: workOrderDetails.isEquipmentWorking
          ? equipmentStatus
          : 'Working',
        additionalWorkRequired: '0',
        siteName: context!.store!.storeCode
      }

      let result = await workOrderDetailsApi
        .recallWorkOrder(apiClient, recallWorkOrderData)
        .then(response => {
          return response
        })

      if (result.status !== 200) {
        navigateTo('/error')
      } else {
        navigateTo('/problem_overview')
      }
    }
  }

  if (clicked) {
    return (
      <div
        className="ln-u-soft ln-u-border-ends opp-btb ln-u-text-align-center"
        id="escalate"
      >
        <div className="rw-container">
          <Form
            onSubmit={(e: Event) => {
              e.preventDefault()
            }}
          >
            <SelectField
              name="Why are you recalling this problem?"
              label="Why are you recalling this problem?"
              onChange={(val: any) => {
                setRecallStatus(val.target.value)
                setErrorRecallStatus(false)
              }}
              disabled={recalled}
              options={RECALL_STATUS}
              error={
                errorRecallStatus
                  ? 'Let us know why you need to recall this problem, so we can understand if there is a wider problem'
                  : false
              }
              required
            />

            {workOrderDetails.isEquipmentWorking ? (
              <SelectField
                name="Is the equipment working?"
                label="Is the equipment working?"
                onChange={(val: any) => {
                  setEquipmentStatus(val.target.value)
                  setErrorEquipmentStatus(false)
                }}
                disabled={recalled}
                options={EQUIPMENT_STATUS}
                error={
                  errorEquipmentStatus
                    ? 'Please select if the equipment is working or not, so we can understand the status of the equipment now that the reported problem is being recalled'
                    : false
                }
                required
              />
            ) : null}

            <TextAreaField
              name="Tell us a little more"
              label="Tell us a little more"
              placeholder="Add your update or comment ..."
              onChange={(entry: { target: { value: string } }) => {
                setTextInput(entry.target.value)
                setErrorTextInput(false)
              }}
              characterCount
              minLength={0}
              maxLength={256}
              disabled={recalled}
              error={
                errorTextInput
                  ? "Tell us if there's anything else we need to know"
                  : false
              }
              required
            />
            {recalled && (
              <ProgressIndicator
                className="ln-u-justify-content-center"
                loading
                preventFocus
              >
                <ProgressSpinner size="small" className="ln-u-push-right-sm" />{' '}
                Loading...
              </ProgressIndicator>
            )}
            <GridWrapper matrix>
              <GridItem size={{ sm: '12/12' }}>
                <ImageUploader imageType='recall' verificationTriggered={0} passImagesVerification={setImagesVerified} />
              </GridItem>
              <GridItem size={{ sm: '6/12' }}>
                <OutlinedButton
                  fullWidth
                  onClick={() => triggerCancel()}
                  disabled={recalled}
                  className='cancelButton ln-u-padding*2'
                >
                  Cancel
                </OutlinedButton>
              </GridItem>
              <GridItem size={{ sm: '6/12' }}>
                <FilledButton
                  fullWidth
                  area-label="Recall recall"
                  onClick={() => {
                    triggerRecall()
                  }}
                  disabled={recalled}
                  className='requestButton ln-u-padding*2'
                >
                  Request recall
                </FilledButton>
              </GridItem>
            </GridWrapper>
          </Form>
        </div>
      </div>
    )
  }

  return (
    <div
      className="ln-u-soft ln-u-border-ends opp-btb ln-u-text-align-center"
      id="escalate"
    >
      <FilledButton
        aria-label="Recall recall"
        className={`rw-width`}
        onClick={() => setClicked(true)}
      >
        Request recall
      </FilledButton>
    </div>
  )
}

export default RecallForm
