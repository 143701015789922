import { Link } from 'react-router-dom'
import { Container, FilledButton } from '@jsluna/react'
import WarningIcon from '../../../assets/WarningIcon'

const WarningMessage: React.FC<{
  errorMessage: string
}> = ({ errorMessage }): JSX.Element => {
  return (
    <div>
      <Container size="xs">
        <div className="generic-container">
          <WarningIcon />
          <div className="ln-u-text-align-left margin-top-small generic-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
            <p>
              {errorMessage}
            </p>
          </div>
          <Link to="/fmassist_landing">
            <FilledButton className="generic-home-button">Home</FilledButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default WarningMessage
