import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const StoreMaintenance: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  function trackGAVisits() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Visits from Overview Dashboard',
        action: 'Visits from Overview Dashboard',
        label: 'Visits from Overview Dashboard'
      })
    }
  }

  function trackGAFollowUpWork () {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Follow up work from Overview Dashboard',
        action: 'Follow up work from Overview Dashboard',
        label: 'Follow up work from Overview Dashboard'
      })
    }
  }

  return (
    <div className="ln-c-card ln-c-card--soft card-tiles">
      <h5 style={{ marginBottom: '1rem' }}> Store maintenance </h5>
      <div className="ln-o-grid ml-spacer">
        <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 OverDueProb">
          <Link to="/problem_overview/visits">
            <div
              className="ln-u-soft ln-u-bg-color-black"
                onClick={() => trackGAVisits()}
            >
              <span className="label">
                {workOrdersDashboard!.totalCountOfPPMWorkOrders}
                  {' '}
              </span>
              <p> Visits </p>
            </div>
          </Link>
        </div>
        <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 EscalatedProb">
          <Link to="/problem_overview/follow_up_work">
            <div
              className="ln-u-soft ln-u-bg-color-lg-grey"
                onClick={() => trackGAFollowUpWork()}
            >
              <span className="label">
              {workOrdersDashboard!.totalCountOfPPMRemedialWorkOrders}
                  {' '}
              </span>
              <p> Follow up work </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default StoreMaintenance
