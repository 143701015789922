import { useState } from 'react'
import { OutlinedButton } from '@jsluna/react'
import NoAssetTagForm from './NoAssetTagForm'

const NoAssetTagButton = () => {
  const [noAsset, setNoAsset] = useState(false)

  return (
    <div className="nat-container">
      <OutlinedButton
        fullWidth
        onClick={() => setNoAsset(!noAsset)}
        style={{ marginBottom: '2rem', background: 'white' }}
      >
        I don&apos;t have an asset tag
      </OutlinedButton>
      {noAsset ? <NoAssetTagForm /> : null}
    </div>
  )
}

export default NoAssetTagButton
