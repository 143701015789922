import { useEffect, useContext } from 'react'
import { Container, Display2, ProgressSpinner } from '@jsluna/react'
import OpenProblems from '../components/problemsOverview/OpenProblems'
import RecentlyClosedProblems from '../components/problemsOverview/RecentlyClosedProblems'
import ProblemsToChase from '../components/problemsOverview/ProblemsToChase'
import workOrderHooks from '../hooks/useWorkOrderDetails'
import { AppContext } from '../context/AppContext'
import { WorkOrdersDashboardType } from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import ErrorMessage from '../components/errors/ErrorMessage'
import Separator from '../components/Separator'
import StoreMaintenance from '../components/problemsOverview/StoreMaintenance'
import ClosedProblems from '../components/problemsOverview/ClosedProblems'
import WorkRequests from '../components/problemsOverview/WorkRequests'
import AutomatedWO from '../components/problemsOverview/AutomatedWO'
import BackLink from '../components/BackLink'

const Overview = () => {
  const context = useContext(AppContext)
  var workOrdersDetails: WorkOrdersDashboardType | undefined
  const [response, loader] = workOrderHooks.useWorkOrdersDashboard(
    context!.store!.storeCode
  )
  useEffect(() => {
    loader.current()
  }, [context, loader])



  if (response.data) {
    workOrdersDetails = response.data.at(0)
  }

  if (response.hasError) {
    return <ErrorMessage />
  }

  return (
    <div>
      <Container size="xs" className="overview-container">
      <BackLink linkTo={'/fmassist_landing'} />
        {response.status !== 'success' ? (
          <div className="ln-u-text-align-center">
            <ProgressSpinner />
          </div>
        ) : (
          <div>
            <div className="ln-u-padding-bottom*3">
              <Display2 className="overview-title">
                Overview of problems
              </Display2>
            </div>
            <OpenProblems workOrdersDashboard={workOrdersDetails} />
            <RecentlyClosedProblems workOrdersDashboard={workOrdersDetails} />
            <ProblemsToChase workOrdersDashboard={workOrdersDetails} />
            <Separator />
            <StoreMaintenance workOrdersDashboard={workOrdersDetails} />
            <ClosedProblems workOrdersDashboard={workOrdersDetails} />
            <WorkRequests workOrdersDashboard={workOrdersDetails} />
            <AutomatedWO workOrdersDashboard={workOrdersDetails} />
          </div>
        )}
      </Container>
    </div>
  )
}

export default Overview
