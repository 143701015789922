import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga'
import {
  Form,
  FilledButton,
  SelectField,
  TextAreaField,
  OutlinedButton,
  GridWrapper,
  GridItem,
  ProgressIndicator,
  ProgressSpinner
} from '@jsluna/react'
import { WODetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { useContext, useState } from 'react'
import { AppContext, useApiClient } from '../../context/AppContext'
import workOrderDetailsApi from '../../api/workOrdersDetailsApi'
import { encryptString } from '../../utils/functions'
import ErrorMessage from '../errors/ErrorMessage'

const DeleteForm: React.FC<{ workOrderDetails: WODetails }> = ({
  workOrderDetails
}): JSX.Element => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigateTo = useNavigate()
  const [clicked, setClicked] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [textInput, setTextInput] = useState('')
  const [errorTextInput, setErrorTextInput] = useState(false)
  const [deleteReason, setDeleteReason] = useState('')
  const [errorDeleteReason, setErrorDeleteReason] = useState(false)
  const [equipmentStatus, setEquipmentStatus] = useState('')
  const [errorEquipmentStatus, setErrorEquipmentStatus] = useState(false)
  const [submitStatus, setSubmitStatus] = useState('')

  const DELETE_REASON = [
    { label: 'Duplicate WO', value: 'Duplicate WO' },
    {
      label: 'Raised in Error',
      value: 'Raised in Error'
    },
    {
      label: 'Store No Longer Requires Service',
      value: 'Store No Longer Requires Service'
    }
  ]
  const EQUIPMENT_STATUS = [
    { label: 'Working', value: 'Working' },
    { label: 'Not working', value: 'Not Working' }
  ]

  const trackGA = () => {
    if (!context?.user?.isAdmin) {
        ReactGA.event({
          category: 'FM Assist – Problem Deleted ',
          action: 'Deleting a problem',
          label: 'Problem Deleted',
        })
      }
  }

  const validateInputs = () => {
    if (deleteReason === '') {
      setErrorDeleteReason(true)
    }
    if (workOrderDetails.isEquipmentWorking && equipmentStatus === '') {
      setErrorEquipmentStatus(true)
    }
    if (textInput === '') {
      setErrorTextInput(true)
    }

    if (deleteReason === '' || (workOrderDetails.isEquipmentWorking && equipmentStatus === '') || textInput === '') {
      return false
    }

    return true
  }

  const triggerCancel = () => {
    setClicked(false)
    setDeleteReason('')
    setErrorDeleteReason(false)
    setEquipmentStatus('')
    setErrorEquipmentStatus(false)
    setTextInput('')
    setErrorTextInput(false)
  }

  const triggerDelete = async () => {
    const pass = validateInputs()

    if (pass) {
      setDeleted(true)
      trackGA()

      let cancelWorkOrderData = {
        userName: '',
        site: context!.store!.storeCode,
        workOrderNumber: workOrderDetails.workOrderNumber,
        comments: textInput,
        cancelReason: deleteReason,
        operationalStatus: workOrderDetails.isEquipmentWorking
          ? equipmentStatus
          : 'Working'
      }

      if (context?.user?.isPayloadDataEncrypt) {
        const tempData = JSON.stringify(cancelWorkOrderData)
        const encryptedData = encryptString(tempData)
        cancelWorkOrderData = encryptedData
      }

      let result = await workOrderDetailsApi
        .deleteWorkOrder(apiClient, cancelWorkOrderData)
        .then(response => {
          return response
        })

      if (result.status !== 200) {
        setSubmitStatus('failed')
        // navigateTo('/error')
      } else {
        setSubmitStatus('success')
        navigateTo('/problem_overview')
      }
    }
  }

  if (submitStatus === 'failed') {
    return <ErrorMessage />
  }

  if (clicked) {
    return (
      <div
        className="ln-u-soft ln-u-border-ends opp-btb ln-u-text-align-center"
        id="escalate"
      >
        <div className="rw-container">
          <Form
            onSubmit={(e: Event) => {
              e.preventDefault()
            }}
          >
            <SelectField
              name="Why are you deleting this problem?"
              label="Why are you deleting this problem?"
              onChange={(val: any) => {
                setDeleteReason(val.target.value)
                setErrorDeleteReason(false)
              }}
              disabled={deleted}
              options={DELETE_REASON}
              error={
                errorDeleteReason
                  ? 'Let us know why you need to delete this problem, so we can understand if there is a wider problem'
                  : false
              }
              required
            />

            {workOrderDetails.isEquipmentWorking ? (
              <SelectField
                name="Is the equipment working?"
                label="Is the equipment working?"
                onChange={(val: any) => {
                  setEquipmentStatus(val.target.value)
                  setErrorEquipmentStatus(false)
                }}
                disabled={deleted}
                options={EQUIPMENT_STATUS}
                error={
                  errorEquipmentStatus
                    ? 'Please select if the equipment is working or not, so we can understand the status of the equipment now that the reported problem is being deleted'
                    : false
                }
                required
              />
            ) : null}

            <TextAreaField
              name="Tell us a little more"
              label="Tell us a little more"
              placeholder="Add your update or comment ..."
              onChange={(entry: { target: { value: string } }) => {
                setTextInput(entry.target.value)
                setErrorTextInput(false)
              }}
              characterCount
              minLength={0}
              maxLength={256}
              disabled={deleted}
              error={
                errorTextInput
                  ? "Tell us if there's anything else we need to know"
                  : false
              }
              required
            />
            {deleted && (
              <ProgressIndicator
                className="ln-u-justify-content-center"
                loading
                preventFocus
              >
                <ProgressSpinner size="small" className="ln-u-push-right-sm" />{' '}
                Loading...
              </ProgressIndicator>
            )}
            <GridWrapper matrix>
              <GridItem size={{ sm: '3/12' }} />
              <GridItem size={{ sm: '4/12' }}>
                <OutlinedButton
                  fullWidth
                  onClick={() => triggerCancel()}
                  disabled={deleted}
                >
                  Cancel
                </OutlinedButton>
              </GridItem>
              <GridItem size={{ sm: '5/12' }}>
                <FilledButton
                  fullWidth
                  areia-label="Delete"
                  onClick={() => {
                    triggerDelete()
                  }}
                  disabled={deleted}
                >
                  Delete
                </FilledButton>
              </GridItem>
            </GridWrapper>
          </Form>
        </div>
      </div>
    )
  }

  return (
    <div
      className="ln-u-soft ln-u-border-ends opp-btb ln-u-text-align-center"
      id="escalate"
    >
      <OutlinedButton
        aria-label="Delete"
        className={`rw-width`}
        onClick={() => setClicked(true)}
      >
        Delete
      </OutlinedButton>
    </div>
  )
}

export default DeleteForm
