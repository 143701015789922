import { useContext, useState } from 'react'
import { TextAreaField } from '@jsluna/react'
import { AppContext } from '../../../context/AppContext'

const TextInputQuestion: React.FC<{
  questionDetails: any
  verificationTriggered: number
  passExtraVerification:(verified: boolean)=>void
}> = ({ questionDetails, verificationTriggered, passExtraVerification }): JSX.Element => {
  const context = useContext(AppContext)
  const [selectedOption, setSelectedOption] = useState('')

  const verify = () => {
    if (verificationTriggered && selectedOption === '') {
      passExtraVerification(false)
      return false
    }
    if (verificationTriggered === 0){
      passExtraVerification(false)
      return true
    }
    context!.createWO!.step2 = `${context!.createWO!.step2}. ${questionDetails.displayQuestion} ${selectedOption}`
    passExtraVerification(true)
    return true
  }

  return (
    <div>
      <TextAreaField
        name={questionDetails.displayQuestion}
        label={questionDetails.displayQuestion}
        placeholder="Write more…"
        onChange={(option: { target: { value: string } }) => {
          setSelectedOption(option.target.value)
        }}
        // value={questionDetails.selectedAns ? questionDetails.selectedAns : ''}
        error={
          verify()
            ? false
            : 'Please help us to help you by answering this question'
        }
        characterCount
        maxLength={256}
      />
    </div>
  )
}

export default TextInputQuestion
