import React, { useEffect, useState, useContext } from 'react'
import { Container, Heading3, Heading4 } from '@jsluna/react'
import { Modal, ModalHeading } from '@jsluna/modal'
import { Pagination } from '@jsluna/pagination'
import FilterPills from '../../components/SearchWorkOrder/FilteringPills'
import { AppContext, useApiClient } from "../../context/AppContext";
import assetsApi from '../../api/assetsApi';
import NoProblemFound from './NoProblemFoundPage';
import { Filters } from '../../types/SearchWorkOrdersTypes';
import workOrderDetails from '../../api/workOrdersDetailsApi';
import FilteringPanel from '../../components/SearchWorkOrder/FilteringPanel';
import SearchWorkOrder from '../../components/SearchWorkOrder/SearchWorkOrder';
import { WorkOrderDetailsType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType';
import AllWOPage from '../ProblemsPages/AllWOsPage'
import Loading from '../../components/Loading/Loading'

interface SearchWithFiltersProps {
    filters: Filters,
    currentPageFromHistory: any,
    searchCurrentPageFromHistory: any
}

const SearchWithFilters: React.FC<SearchWithFiltersProps> = ({filters, currentPageFromHistory, searchCurrentPageFromHistory}) => {
    const context = useContext(AppContext)
    const siteCode = context?.store?.storeCode;
    const apiClient = useApiClient();
    const [workOrderData, setWorkOrderData] = useState<WorkOrderDetailsType>();
    const [currentPage, setCurrentPage] = useState(currentPageFromHistory ?? 1);
    const [searchCurrentPage, setSearchCurrentPage] = useState(searchCurrentPageFromHistory ?? 1);
    const [openFilterPanel, setOpenFilterPanel] = useState(false);
    const [filtersList, setFiltersList] = useState(filters);
    const [categoryData, setCategoryData] = useState<string[]>([]);
    const [locationData, setLocationData] = useState<string[]>([]);
    const arrCategoryTag: any[] = [];
    const arrLocationTag: any[] = [];
    const itemsPerPage = 10;

    useEffect(() => {
        window.scroll(0, 0)
            if (siteCode !== undefined && (/^(WO)?\d{3,}/i.test(filtersList.workOrderNumber) || filtersList?.workOrderNumber?.length === 0)) {
                workOrderDetails.searchWorkOrders(apiClient, Object.values(filtersList).some((value) => { return value !== undefined && value !== null && value !== "" }) ? searchCurrentPage : currentPage, itemsPerPage, siteCode, filtersList).then (
                    response => {
                        if (response){
                        let receivedResponse: WorkOrderDetailsType = {...response?.response}
                        receivedResponse.responseStatus = response?.responseStatus;
                        setWorkOrderData(receivedResponse);
                        }
                    }
                )
            }
    }, [siteCode, currentPage, searchCurrentPage, filtersList])

    useEffect(() => {
        if (siteCode) {
            assetsApi.getAssetCategoriesForSearch(apiClient, siteCode).then(response => {
                if (response) {
                    for (let i = 0; i < response.length; i += 1) {
                        let inputData = response[i].toLowerCase()
                        arrCategoryTag.push({
                            label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
                            value: inputData.charAt(0).toUpperCase() + inputData.slice(1)
                        })
                    }
                    setCategoryData(arrCategoryTag)
                }
        });
        }
    }, [siteCode])

    useEffect(() => {
        if (siteCode) {
            assetsApi.getAssetLocationsForSearch(apiClient, siteCode).then(response => {
                if (response) {
                    for (let i = 0; i < response.length; i += 1) {
                        let inputData = response[i].toLowerCase()
                        arrLocationTag.push({
                            label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
                            value: inputData.charAt(0).toUpperCase() + inputData.slice(1)
                        })
                    }
                    setLocationData(arrLocationTag);
                }
            }
            )
        }
    }, [siteCode])

    const updateFiltersList = (filters: Filters) => {
        const updatedFiltersList: Filters = { ...filters }
        setFiltersList(updatedFiltersList);
        context!.filters = updatedFiltersList;
        clearCurrentPage();
    }

    const handlePageChangeEvent = (page: number, event: Event) => {
        event.preventDefault();
        Object.values(filtersList).some((value) => { return value !== undefined && value !== null && value !== "" })
            ? setSearchCurrentPage(page) :
            setCurrentPage(page)
    }

    const clearCurrentPage = () => {
        setCurrentPage(1);
        setSearchCurrentPage(1);
    }

    const handleWorkOrderChange = (event: Event) => {
        setSearchCurrentPage(1);
        const updatedFiltersList = { ...filtersList, workOrderNumber: (event?.target as HTMLInputElement).value }
        setFiltersList(updatedFiltersList);
        context!.filters = updatedFiltersList;
    }

    const handleModal = (value: boolean) => {
        setOpenFilterPanel(value)
    }

    if (openFilterPanel) {
        return (
            <Container id="r-filter-panel-mobile">
                <Modal
                    fullScreen
                    open={openFilterPanel}
                    handleClose={() => handleModal(false)}
                >
                    <ModalHeading element="h2">Filters</ModalHeading>
                    <FilteringPanel
                        categoryData={categoryData}
                        locationData={locationData}
                        filters={filtersList}
                        passOpenFilterPanel={handleModal}
                        onFiltersChange={updateFiltersList}
                        clearCurrentPage={clearCurrentPage}
                    />
                </Modal>
            </Container>
        )
    }

    return (
        <>
            <Container
                className="r-findworkorder-overview-container search-workorder-container ln-u-flush-left"
                id="r-workorders-overview-page"
            >
                <div className='dm__filter-panel-wrapper ln-u-grid'>
                    <Container className="r-filter-panel" id="r-filter-panel">
                        <FilteringPanel filters={filtersList} onFiltersChange={updateFiltersList} passOpenFilterPanel={handleModal} categoryData={categoryData}
                            locationData={locationData}
                            clearCurrentPage={clearCurrentPage} />
                    </Container>
                </div>
                <Container className="container-width" >
                    <Heading4
                        className="search-workorder-title"
                    >
                        Search for a work order number
                    </Heading4>
                    <SearchWorkOrder 
                        id="search-workorder"
                        name="search-workorder"
                        label="Search work orders"
                        placeholder="Enter Work Order Number"
                        value={filtersList.workOrderNumber} 
                        handleChange={handleWorkOrderChange} />
                    <Heading3>
                        Reported FM problems in store
                    </Heading3>
                    <Container className="r-pageName r-filter-mobile" size="xs">
                        <FilterPills
                            handleModal={handleModal}
                            filters={filtersList}
                            removeFilter={updateFiltersList}
                        />
                    </Container>

                    {
                        workOrderData?.responseStatus === 200 || workOrderData?.responseStatus === 204 ? '' : <Loading />
                    }
                    {
                        workOrderData && workOrderData?.allWorkOrdersViewModel?.length !== 0 &&
                        <AllWOPage workOrders={workOrderData}/>
                    }

                    {
                        workOrderData?.allWorkOrdersViewModel?.length === 0 || workOrderData?.responseStatus === 204 ? (
                            <>
                            <NoProblemFound />
                            </>
                        ) : ('')
                    }
                    {(workOrderData && workOrderData?.totalCountOfWorkOrders > itemsPerPage) && (
                        <Pagination
                            showFirstAndLast
                            current={Object.values(filtersList).some((value) => { return value !== undefined && value !== null && value !== "" }) ? searchCurrentPage : currentPage}
                            total={
                                workOrderData?.numberOfPages
                            }
                            onChange={(page: number, e: Event) => {
                                handlePageChangeEvent(page, e)
                            }}
                            className="fp-pagRight"
                        />
                    )}
                </Container>

            </Container>
        </>
    )
}

export default SearchWithFilters;