import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { Display3 } from '@jsluna/typography'
import { FilledButton, Button } from '@jsluna/button'
import {
  AutocompleteField,
  GridItem,
  GridWrapper,
  Heading6,
  TextInputField
} from '@jsluna/react'
import { Scan } from '@jsluna/icons'
import moment from 'moment'
import { DateRangePicker } from '@jsluna/date-picker'
import '@jsluna/date-picker/react-dates-initialize'
import { Filters } from '../../types/SearchWorkOrdersTypes'

interface filteringPanelProps {
  categoryData: string[]
  locationData: string[]
  filters: Filters
  onFiltersChange: (value: Filters) => void
  passOpenFilterPanel: (value: boolean) => void
  clearCurrentPage: () => void
}

const FilteringPanel: React.FC<filteringPanelProps> = ({
  categoryData,
  locationData,
  filters,
  onFiltersChange,
  passOpenFilterPanel,
  clearCurrentPage
}) => {
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedAsset, setSelectedAsset] = useState('')
  const [startDate, setStartDate] = useState<moment.Moment | string>('')
  const [endDate, setEndDate] = useState<moment.Moment | string>('')
  const [focusedInput, setFocusedInput] = useState(null)
  const navigate = useNavigate()
  const filterWorkOrders = () => {
    clearCurrentPage()
    onFiltersChange({
      workOrderNumber: '',
      category: selectedCategory,
      location: selectedLocation,
      startDate: startDate,
      endDate: endDate,
      assetNumber: selectedAsset
    })
    passOpenFilterPanel(false)
  }

  const clearFilters = () => {
    setSelectedCategory('')
    setSelectedAsset('')
    setSelectedLocation('')
    setStartDate('')
    setEndDate('')

    onFiltersChange({
      workOrderNumber: '',
      category: '',
      location: '',
      startDate: '',
      endDate: '',
      assetNumber: ''
    })
    passOpenFilterPanel(false)
    clearCurrentPage()
  }

  const openCameraScanner = () => {
    navigate('/find_workorder/camera_scanner')
  }

  useEffect(() => {
    if (filters.category !== selectedCategory) {
      setSelectedCategory(filters.category)
    }
    if (filters.assetNumber !== selectedAsset) {
      setSelectedAsset(filters.assetNumber)
    }
    if (filters.startDate !== startDate) {
      setStartDate(filters.startDate)
    }
    if (filters.endDate !== endDate) {
      setEndDate(filters.endDate)
    }
    if (filters.location !== selectedLocation) {
      setSelectedLocation(filters.location)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <Card id="r-filterBar">
      <GridWrapper>
        <GridItem>
          <Display3
            className="r-filter-header-desktop"
            id="r-filter-header-desktop"
          >
            Filters
          </Display3>
          <TextInputField
            name="assetId"
            label="Scan or enter an asset tag"
            placeholder="Enter asset tag ..."
            onChange={(event: any) => setSelectedAsset(event.target.value)}
            value={selectedAsset}
            maxLength={30}
            action={
              <Button
                variant="filled"
                color="dark"
                className="scan"
                onClick={openCameraScanner}
              >
                <span className="ln-u-visually-hidden">Scan Barcode</span>
                <Scan aria-label="Scanner Button" />
              </Button>
            }
          />
          <Heading6>Select a date range</Heading6>
          <DateRangePicker
            startDate={startDate}
            startDateId="drp-start-date"
            endDate={endDate}
            endDateId="drp-end-date"
            orientation={
              window.matchMedia('(max-width: 700px)').matches
                ? 'vertical'
                : 'horizontal'
            }
            onDatesChange={({ startDate, endDate }: any) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            focusedInput={focusedInput}
            onFocusChange={(focused: any) => setFocusedInput(focused)}
            numberOfMonths={1}
            verticalHeight={350}
            isOutsideRange={(day: moment.Moment) =>
              day.isBefore(moment().subtract(1, 'year')) ||
              day.isAfter(moment())
            }
            displayFormat="DD/MM/YYYY"
            keepOpenOnDateSelect={false}
          />

          <br />

          <div>
            <AutocompleteField
              key={selectedCategory}
              name="What sort of asset is it?"
              label="What sort of asset is it?"
              placeholder="Choose an asset"
              options={categoryData}
              onChange={(asset: any) => setSelectedCategory(asset?.value)}
              onSelect={(asset: any) => setSelectedCategory(asset?.value)}
              value={selectedCategory}
            />
          </div>
          <div>
            <AutocompleteField
              key={selectedLocation}
              name="Where is the asset located?"
              label="Where is the asset located"
              placeholder="Choose the location"
              options={locationData}
              onSelect={(location: any) => setSelectedLocation(location?.value)}
              onChange={(location: any) => setSelectedLocation(location?.value)}
              value={selectedLocation}
            />
          </div>

          <FilledButton
            id="r-filters-apply-button"
            fullWidth
            onClick={() => filterWorkOrders()}
          >
            Apply
          </FilledButton>

          <Button
            id="r-filters-clear-button"
            className="ln-u-margin-ends*2"
            fullWidth
            variant="outlined"
            onClick={() => clearFilters()}
          >
            Clear filters
          </Button>
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

export default FilteringPanel
