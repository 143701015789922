  import { Link } from 'react-router-dom'
import LikeIcon from '../../../assets/LikeIcon'
import helpNumbers from '../../../utils/helpNumbers'

const SuccessMessage = () => {
  return (
    <div className="awoinfo-container">
      <LikeIcon aria-label="Thumb Icon" />
      <div className="margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
        <h4 className="ln-u-text-align-left">
          Your problem is being reported ...
        </h4>
        <p className="text-align">
          We're sending the information to the FM Control Centre. This might take a
          few moments, check back to see your new problem in the{' '}
          <Link to="/problem_overview">
            <strong
              style={{
                textDecorationLine: 'underline',
                cursor: 'pointer'
              }}
            >
              problem overview
            </strong>
          </Link>
          {/* <strong
                    style={{
                      textDecorationLine: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={this.handleSubmit}
                  >
                    problem overview
                  </strong> */}
        </p>
        <h4 className="ln-u-text-align-left">
          Let us know if anything changes!
        </h4>
        <p className="text-align">
          If the problem resolves itself, either please cancel in FM Assist or
          ring the FM Control Centre to cancel on {' '}
          <strong>{helpNumbers.FMControlCentre}</strong>
          {' '}to avoid your store being charged for a wasted visit.
        </p>
      </div>
    </div>
  )
}

export default SuccessMessage
