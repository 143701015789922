import { Container } from '@jsluna/react'
import React from 'react'
import { WOdetailsType, WorkOrderDetailsType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import WorkOrdersList from '../../components/WorkOrdersList'
import NoWorkOrdersMessage from '../../components/NoWorkOrdersMessage'

const AllWOPage: React.FC<{workOrders: WorkOrderDetailsType}> = ({workOrders}) => {
  var workOrdersDetails: WOdetailsType
  var workOrdersType = 'All'
  if (workOrders?.allWorkOrdersViewModel) {
    workOrdersDetails = {
      numberOfWorkOrders: workOrders?.totalCountOfWorkOrders,
      workOrders: workOrders?.allWorkOrdersViewModel,
      workOrderType: workOrdersType
    }
  }

  const showWOList = () => {
    if (
      workOrders?.allWorkOrdersViewModel &&
      workOrders.allWorkOrdersViewModel.length > 0
    ) {
      return (
        <div>
          <WorkOrdersList workOrdersDetails={workOrdersDetails} />
        </div>
      )
    }
    return <NoWorkOrdersMessage />
  }

  return (
    <Container size="xs">
      {showWOList()}
    </Container>
  )
}

export default AllWOPage