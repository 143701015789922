import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import userActionsApi from '../api/userActionsApi'
import { UserDetailsType } from '../types/UserDetails/UserDetailsType'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadUserDetails } from '../types/UserDetails/ILoadUserDetails'
import { FetchUserDetails } from '../types/UserDetails/FetchUserDetails'

const useUserActions = (): [
  IResourceLoader<UserDetailsType>,
  ILoadUserDetails
] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<UserDetailsType>(
    'Unable to load user details'
  )

  const loader = useCallbackRef<FetchUserDetails>(
    (payload?: string, siteId?: string, referer?: boolean) =>
      response.load(
        async () =>
          await userActionsApi.userValidate(
            apiClient,
            payload ?? 'fmassist',
            siteId ?? null,
            referer
          )
      )
  )

  return [response, loader]
}

export default useUserActions
