import { FilledButton, Container } from '@jsluna/react'
import configuration from '../../../src/utils/configuration'
import ErrorIcon from '../../assets/ErrorIcon'

const InternalServerErrorMessage = () => {
  const redirect = () => {
    window.location.href.indexOf('https://fmassist.sainsburys.co.uk') > -1 ? window.location.replace(configuration.hubProdURL) : window.location.replace(configuration.hubtest_link)
  }

    return (
      <div>
        <Container size="xs">
          <div className="fivehundred-container">
            <ErrorIcon />
            <div className="ln-u-text-align-left margin-top-small fivehundred-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
              <h5>There was a problem on FM Assist</h5>
              <p>
                There&apos;s been an internal error affecting the service for FM
                Assist only. Everything else should be working normally. Please
                give us a chance to fix this.
              </p>
              <h5>Having repeated problems or need urgent access?</h5>
              <p> Please contact the IT Service Desk.</p>
            </div>
            <FilledButton
              className="fivehundred-colleague-button"
              onClick={() => {
                redirect()
              }}
            >
              Colleague hub
            </FilledButton>
          </div>
        </Container>
      </div>
    )
}

export default InternalServerErrorMessage;
