import { Container } from '@jsluna/react'
import ReactGA from 'react-ga'
import LikeIcon from '../assets/LikeIcon'
import helpNumbers from '../utils/helpNumbers'
import { AppContext } from '../context/AppContext'
import { useContext } from 'react'

const NoWorkOrdersMessage = () => {
  const context = useContext(AppContext)

  const trackGA = () => {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Empty State Help Desk Number ',
        action: 'FM Assist - Empty State Help Desk Number Clicked ',
        label: 'FM Assist - Empty State Help Desk Number',
      })
    }
  }

  return (
    <Container size="xs">
      <div className="awoinfo-container">
        <LikeIcon aria-label="Thumb Icon" />
        <div className="margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
          <h5 className="ln-u-padding-bottom*2">
            Move along, there&apos;s nothing to see here!{' '}
          </h5>
          <div className="ln-u-text-align-left">
            <p>There are no problems to show you here.</p>
            <p>
              If this doesn&apos;t feel right you can call the FM Control Centre on{' '}
              <span>
                <strong>
                  <a
                    href={`tel:${helpNumbers.FMControlCentre}`}
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    onClick={() => trackGA()}
                  >
                    {helpNumbers.FMControlCentre}
                  </a>
                </strong>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default NoWorkOrdersMessage
