import { Link } from 'react-router-dom'
import { FilledButton, Container } from '@jsluna/react'
import ErrorIcon from '../../assets/ErrorIcon'

const PageNotFoundMessage = () => {
    return (
      <div>
        <Container size="xs">
          <div className="fourOfour-container">
            <ErrorIcon />
            <div className="ln-u-text-align-left margin-top-small fourOfour-container-four ln-u-padding-sides*2 ln-u-padding-bottom*4">
              <h5>We can&apos;t find what you&apos;re looking for </h5>
              <p>
                Something&apos;s gone wrong along the way and we can&apos;t find
                the page, image or document you&apos;re looking for. Don&apos;t
                worry let&apos;s get back to somewhere familiar.
              </p>
            </div>
            <Link to="/fmassist_landing">
              <FilledButton className="fourOfour-home-button">
                Home
              </FilledButton>
            </Link>
          </div>
        </Container>
      </div>
    )
}

export default PageNotFoundMessage