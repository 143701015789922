import SearchWithFilters from "./SearchWithFilters";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { Filters } from "../../types/SearchWorkOrdersTypes";

const FindWorkOrderPage = () => {
    const location = useLocation();
    const asset = location?.state?.asset;
    const context = useContext(AppContext)
    const filtersFromContext = context!.filters;

    let filters: Filters;

    filters = {
            ...filtersFromContext,
            startDate : filtersFromContext.startDate ? moment(filtersFromContext.startDate) : "",
            endDate: filtersFromContext.endDate ? moment(filtersFromContext.endDate) : ""
    }

    if (asset) context!.filters!.assetNumber = asset;

    window.history.replaceState(null, '');
    return (
        <>
            <SearchWithFilters
                filters={filters}
                currentPageFromHistory={location?.state?.currentPage?.currentPage}
                searchCurrentPageFromHistory={location?.state?.searchCurrentPage?.searchCurrentPage} 
            />
        </>
    )
}

export default FindWorkOrderPage;