import React, { useContext } from 'react'
import { Container, Display1 } from '@jsluna/react'
import BackLink from '../components/BackLink'
import AssetTagForm from '../components/AssetTagForm'
import NoAssetTagButton from '../components/NoAssetTagButton'
import { AppContext, useApiClient } from '../context/AppContext'
import userActions from '../api/userActionsApi'
import { useNavigate } from 'react-router'

const CheckAssetTag = () => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigateTo = useNavigate()

  const  loggingFromRefrigeration = async() =>{
    // const assetTag = this.props.match.params.id
    const url = new URL(window.location.href)
    const storeNumber = url.searchParams.get('storeNumber')
    const storeName = url.searchParams.get('storeName')

    const response = await userActions.userValidate(apiClient, 'fmassist', storeNumber, true).then((response) => {
      // if (response. response.payload!.status !== 200) {navigateTo('/error')}
      // if (response. response.payload!.status === 200 && context) {context.user = response.data}
    })
  }

  // loggingFromRefrigeration()

  if(window.location.href.indexOf('refrigeration') >=0) {
    loggingFromRefrigeration()
  }

  return (
    <div>
      <Container size="xs">
        {
          // enableCheckAsset ?
          <React.Fragment>
            <BackLink linkTo="/" />
            <Display1 className="pageTitle">Check equipment status</Display1>
            <AssetTagForm />
            <NoAssetTagButton />
          </React.Fragment>
          // :
          // ''
        }
        {/* {enableWoLists ?
            <div>
              <NoAssetListPage
                previousSteps={this.enableCheckAsset}
                enableSearchAsset={this.enableSearchAsset}
                storeWithoutAssetDetails={storeWithoutAssetData}
                checkAssetFlag={true}
                isReadOnlyFlag={isReadOnlyFlag}
                scrollNow={flag}
              />
            </div> : ''} */}
        {/* {enableSearchAsset ?
            <SearchAssetList
              previousSteps={this.enableWoLists}
              enableCheckAsset={this.enableCheckAsset}
              fromCheckAsset={true}
            />
            : ''
          } */}
      </Container>
    </div>
  )
}

export default CheckAssetTag
