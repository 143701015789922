import { Link } from 'react-router-dom'
import { Card, Display1 } from '@jsluna/react'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { reformatCapsMessage } from '../../utils/functions'

const AssetCard = () => {
  const context = useContext(AppContext)

  return (
    <Card className="ln-u-soft-xl margin-bottom-card">
      <div className="result-container">
        <Display1>
          {' '}
          {reformatCapsMessage(context?.assetPayload?.subcategory)}{' '}
        </Display1>
        <div className="ln-u-padding-bottom*2">
          in
          <b> {reformatCapsMessage(context?.assetPayload?.location)}</b>
        </div>
        <Link to="/check_equipment">Not the right asset?</Link>
      </div>
    </Card>
  )
}

export default AssetCard
