import { useContext } from 'react'
import { FilledButton } from '@jsluna/react'
import LikeIcon from '../assets/LikeIcon'
import { AppContext, useApiClient } from '../context/AppContext'
import { useNavigate } from 'react-router'
import { AssetDetailsType } from '../types/AssetCategories/AssetDetailsType'
import assetsApi from '../api/assetsApi'

const NoAssetMessage = () => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigate = useNavigate()

  const submitProblem = async () => {
    var foundAssetResponse: AssetDetailsType[]
    foundAssetResponse = await assetsApi
      .searchAsset(apiClient, context!.asset)
      .then(value => {
        return value
      })

    if (context) {
      context.assets = foundAssetResponse
    }

    navigate('/search_assets')
  }

  return (
    <div>
      <h5 className="pageTitle">No open problems</h5>
      <div className="awoinfo-container">
        <LikeIcon aria-label="Thumb Icon" />
        <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
          <h5>Nothing to see over here!</h5>
          <p>
            There are no open problems that have been reported for this asset.
          </p>
        </div>
      </div>
      {context?.user?.isReadOnly === '0' ? (
        <div className="fp-home-btn">
          <FilledButton
            fullWidth
            onClick={() => submitProblem()}
            style={{ marginBottom: '2rem' }}
            className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center nextMarginTop"
          >
            Report a Problem
          </FilledButton>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default NoAssetMessage
