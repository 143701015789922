import { useEffect, useContext } from 'react'
import {
  Container,
  Card,
  Form,
  Display1,
  Body1,
  OutlinedButton
} from '@jsluna/react'
import helpNumbers from '../utils/helpNumbers'
import BackLink from '../components/BackLink'
import { AppContext } from '../context/AppContext'
import useStoreCareTeam from '../hooks/useStoreCareTeam'

const listItems = [
  'Police cordon',
  'Gas leak',
  'Total store power failure',
  'Ram raid'
]

const UsefulNumbers = () => {
  const context = useContext(AppContext)

  const [response, loader] = useStoreCareTeam(context!.store!.storeCode)

  useEffect(() => {
    loader.current(context!.store!.storeCode)
  }, [context, loader])

  const storeCareTeamDetails = response.data

  return (
    <div>
      <Container size="xs">
        <BackLink linkTo={'/fmassist_landing'} />

        <Display1 className="pageTitle">Useful numbers</Display1>
        <Card className="ln-c-card ln-u-soft-xl ln-u-border">
          <Form
            onSubmit={(e: Event) => {
              e.preventDefault()
            }}
          >
            <Body1>
              <div className="ln-u-padding-ends*2">
                Here&apos;s a few numbers that might be useful while you&apos;re
                using FM Assist.
              </div>
            </Body1>
            <Display1>
              <div className="ln-u-padding-ends">
                Unable to trade or a serious emergency?
              </div>
            </Display1>
            <br />
            <Body1>
              If you&apos;re unable to trade at all or having a major incident
              similar to these, please call the Emergency Control Room:
            </Body1>
            <ul>
              {listItems.map(item => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            <OutlinedButton fullWidth>
              {helpNumbers.EmergencyDesk}
            </OutlinedButton>
            {storeCareTeamDetails?.storeCareManager ? (
              <div>
                <Display1>
                  <div className="ln-u-padding-ends">Store care manager</div>
                </Display1>
                <Body1>
                  <div>
                    Contact your store care manager if you have advised the
                    helpdesk of an emergency, you have escalated an overdue SLA
                    or you have logged a serious H&amp;S/Trading issue.
                  </div>
                  <div className="ln-u-padding-top*2">
                    Contact {''} {storeCareTeamDetails?.storeCareManager} at{' '}
                    {storeCareTeamDetails?.storeCareManagerEmail}
                    {storeCareTeamDetails?.storeCareManagerPhone ? 
                    (<Body1>
                        {' '} or give them a call on
                        <OutlinedButton fullWidth className='ln-u-margin-top*2'>
                          {storeCareTeamDetails?.storeCareManagerPhone}
                        </OutlinedButton>
                    </Body1>                    
                      
                    ) : null}
                  </div>
                </Body1>
              </div>
            ) : null}
          </Form>
        </Card>
      </Container>
    </div>
  )
}

export default UsefulNumbers
