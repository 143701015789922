import { useLocation, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import {
  Card,
  Form,
  OutlinedButton,
  TextInputField,
  Button,
  TextButton
} from '@jsluna/react'
import { Scan } from '@jsluna/icons'
import { AppContext, useApiClient } from '../context/AppContext'
import assetsApi from '../api/assetsApi'

const AssetTagForm = () => {
  const navigateTo = useNavigate()
  const location = useLocation()
  const apiClient = useApiClient();
  const context = useContext(AppContext);

  const [assetName, setAssetName] = useState(location?.state?.assetId ? location.state.assetId : '');

  const cancelAction = (ev: any) => {
    ev.preventDefault()
    navigateTo('/')
  }

  const submitAsset = () => {
    const siteId = context?.store?.storeCode;
    const assetTag = assetName.replace(/[^\w]/gi, '')
    if (assetName.length >= 1) {
      localStorage.setItem('assetCode', assetTag)
      const addSSLTag = /\d/.test(assetTag.charAt(0)) ? `SSL${assetTag}` : assetTag
      siteId && addSSLTag && assetsApi.getAssetDetails(apiClient, addSSLTag, siteId).then(data => {
      const path = window.location.pathname === '/check_equipment'
                  ? `/check_equipment/${assetTag}`
                  : '/report_problem/whats_happening'
        if (data?.responseStatus === 200 && data?.response) {
          localStorage.setItem('WorkOrderType', 'WOWithAsset')
          context!.createWO!.assetDetails = data?.response;
          const payload = {
            category: data?.response?.category,
            subcategory: data?.response?.subCategory,
            location: data?.response?.department,
            siteId: data?.response?.siteName,
            storeType: context!.user!.siteType,
          }
          context!.assetPayload = payload
          navigateTo(path);
        }
        else if (data?.responseStatus === 200 && !data?.response) {
          navigateTo('/location_not_matched')
        }
        else if (data?.responseStatus === 204) {
          navigateTo('/asset_not_present',{ state: {
            assetID: assetName,
            path: window.location.pathname
          },
        })
      } else if (data?.responseStatus === 500) {
        navigateTo('/apiError', {state: {errorCode: '500'}})
      } else {
        navigateTo('/apiError', {state: {errorCode: 'generic'}})
      }
    })
    }
  }

  const handleChangeAsset = (event: any) => {
    const regularExpression = /^[0-9a-zA-Z -]{0,}$/;
    const valid = regularExpression.test(event.target.value);

    if (valid) {
      if (event.target.value.length > 30) {
        const assetID = event.target.value.substring(0, 30);
        setAssetName(assetID);
      } else {
        setAssetName(event.target.value);
      }
    }
  }

  const openCameraScanner = () => {
    const path = window.location.pathname === '/check_equipment' ? '/check_equipment' : '/report_problem';
      navigateTo(`${path}/camera_scanner`);
  }

  return (
    <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft">
      <Form
        onSubmit={(e: Event) => {
          e.preventDefault()
        }}
      >
        <TextInputField
          name="assetID"
          label="Scan or enter an asset tag"
          placeholder="e.g. SSL001166059 ..."
          onChange={handleChangeAsset}
          value={assetName}
          maxLength={30}
          // error={
          //   error
          //     ? 'Please either scan an asset tag using the scan button in this field or enter an asset number using the onscreen keyboard'
          //     : false
          // }
          action={
            <Button
              variant="filled"
              color="dark"
              onClick={openCameraScanner}
              className="scan"
            >
              <span className="ln-u-visually-hidden">Scan Barcode</span>
              <Scan aria-label="Scanner Button" />
            </Button>
          }
        />

        <OutlinedButton
          type="submit"
          fullWidth
          className="ln-u-margin-bottom*2"
          onClick={() => {
            submitAsset()
          }}
        >
          Next
        </OutlinedButton>

        <TextButton className="withOAtag" fullWidth onClick={cancelAction}>
          Cancel
        </TextButton>
      </Form>
    </Card>
  )
}

export default AssetTagForm
