import { handleDateDesign, checkWOstatus } from '../../utils/functions'
import { WODetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import moment from 'moment'

const ExtraWODetailsMessage: React.FC<{ workOrder: WODetails }> = ({
  workOrder
}) => {
  let etaDate = moment(workOrder.eta)
  let presentDay = moment(new Date())
  let [overDueCheckStatus, dueDaysCount] = checkWOstatus(workOrder)

  const overSLAMessage = () => {
    return (
      <div>
        <div className="fm-red-text">{dueDaysCount} overdue</div>
        {workOrder.lastPauseDate !== '' && etaDate < presentDay ? (
          <div>
            Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}
          </div>
        ) : null}
        {workOrder.lastPauseDate !== '' && etaDate > presentDay ? (
          <div>
            Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}.
            Further visit needed {''}
            {handleDateDesign(etaDate, '#fm-black-text', 'furtherWork')}
          </div>
        ) : null}
        {workOrder.lastPauseDate === '' && !etaDate.isValid() ? (
          <div>Engineer visit date to be confirmed</div>
        ) : null}
        {workOrder.lastPauseDate === '' && etaDate < presentDay ? (
          <div>
            Engineer should have visited{' '}
            {handleDateDesign(etaDate, 'fm-red-text')}
          </div>
        ) : null}
        {workOrder.lastPauseDate === '' && etaDate > presentDay ? (
          <div>
            Engineer should visit {handleDateDesign(etaDate, 'fm-black-text')}
          </div>
        ) : null}
      </div>
    )
  }

  const overETAMessage = () => {
    if (workOrder.lastPauseDate !== '' && etaDate < presentDay) {
      return (
        <div>
          Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}
        </div>
      )
    }
    if (workOrder.lastPauseDate !== '' && etaDate > presentDay) {
      return (
        <div>
          Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}.
          Further visit needed by
          {handleDateDesign(etaDate, '#fm-black-text', 'furtherWork')}`
        </div>
      )
    }
    return (
      <div>
        Engineer should have visited {handleDateDesign(etaDate, 'fm-red-text')}
      </div>
    )
  }

  const WithinSLANoETAMessage = () => {
    if (workOrder.lastPauseDate !== '' && etaDate < presentDay) {
      return (
        <div>
          Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}
        </div>
      )
    }
    if (workOrder.lastPauseDate !== '' && etaDate > presentDay) {
      return (
        <div>
          Engineer visited {handleDateDesign(workOrder.lastPauseDate, '')}.
          Further visit needed by
          {handleDateDesign(etaDate, 'fm-black-text', 'furtherWork')}`
        </div>
      )
    }
    return (
      <div>
        Engineer is visiting {handleDateDesign(etaDate, 'fm-black-text')}
      </div>
    )
  }

  const showMessage = () => {
    switch (overDueCheckStatus) {
      case 'within sla':
        return (
          <div>
            Engineer needs to visit{' '}
            {handleDateDesign(etaDate, 'fm-black-text', 'noTime')}
          </div>
        )
      case 'only sla':
        return (
          <div>
            Engineer needs to visit {handleDateDesign(etaDate, '', 'noTime')}
          </div>
        )
      case 'over sla':
        return overSLAMessage()
      case 'over sla no eta':
        return overSLAMessage()
      case 'over eta no sla':
        return overETAMessage()
      case 'within eta no sla':
        return WithinSLANoETAMessage()
      case 'show eta':
        return (
          <div>
            Engineer is visiting {handleDateDesign(etaDate, 'fm-black-text')}
          </div>
        )
      case 'no sla no eta':
        return <div>Engineer visit date to be confirmed</div>
      case 'fix complete':
        return (
          <div>
            Fixed{' '}
            {handleDateDesign(workOrder.dateServiceCompleteORCancelled, '')}
          </div>
        )
      case 'cancelled':
        return (
          <div>
            Cancelled{' '}
            {handleDateDesign(workOrder.dateServiceCompleteORCancelled, '')}
          </div>
        )
      default:
        return null
    }
  }

  return <div className="ln-u-padding-top*2">{showMessage()}</div>
}

export default ExtraWODetailsMessage
