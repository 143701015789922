import React from 'react';
import PropTypes from 'prop-types';

const LikeIcon = ({ width, height, viewBox }) =>
  (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs />
      <g id="🚀-FM-Assist---User-stories" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Store---DT---No-connection" transform="translate(-696.000000, -196.000000)">
          <g id="Group-2" transform="translate(575.000000, 196.000000)">
            <g id="icon/like" transform="translate(121.000000, 0.000000)">
              <circle id="Oval" fill="#218234" cx="24" cy="24" r="24" />
              <svg className="ln-c-icon" role="img" aria-label="Thumb Icon" viewBox="-7 -7 40 40"><path d="M4.574 10a.074.074 0 0 0-.074.074v7.851c0 .041.033.074.074.074H6.5v-8H4.574zm0-2H8.5v12H4.574A2.074 2.074 0 0 1 2.5 17.924v-7.851c0-1.146.929-2.075 2.074-2.075zM8.5 9.326v8.636c3.266 1.008 6.03 1.276 8.292.825 1.512-.302 2.244-.855 2.537-1.605.152-.385.184-.755.165-1.406a9.029 9.029 0 0 1-.006-.313V9.999c0-.378-.716-1-1.339-1H13.5a1 1 0 0 1-1-.998l-.005-3.583a.074.074 0 0 0-.052-.07l-.985-.305a.943.943 0 0 0-1.16.565L8.5 9.327zm9.65-2.328c1.668 0 3.338 1.45 3.338 3v5.465c0 .053 0 .085.005.254.027.903-.025 1.49-.301 2.195-.557 1.421-1.862 2.408-4.01 2.836-2.797.558-6.128.18-9.998-1.108a1 1 0 0 1-.684-.949v-9.55a1 1 0 0 1 .066-.356l1.864-4.89a2.943 2.943 0 0 1 3.619-1.764l.985.304a2.074 2.074 0 0 1 1.461 1.98l.004 2.583h3.65z" fill="#FFFFFF" fillRule="nonzero"></path></svg>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );


LikeIcon.defaultProps = {
  width: 60,
  height: 60,
  viewBox: '0 0 50 50',
};

LikeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string,
};

export default LikeIcon;
