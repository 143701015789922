import { Card } from '@jsluna/react'
import { WODetails } from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import RecallForm from './ExtraFunctionsForms/RecallForm'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import DeleteForm from './ExtraFunctionsForms/DeleteForm'

const ExtraFunctions: React.FC<{ workOrderDetails: WODetails }> = ({
  workOrderDetails
}): JSX.Element => {
  const context = useContext(AppContext)

  const userFlag = context?.user?.isReadOnly === '0'

  const showRecallForm =
    userFlag &&
    workOrderDetails.criteria === 'ReviewFix' &&
    !workOrderDetails.isHelpdeskAvail &&
    workOrderDetails.fmAssistStatus === 'Review Fix'

  const showEscalateForm =
    userFlag &&
    workOrderDetails.criteria === 'OverSLA' &&
    !workOrderDetails.isHelpdeskAvail &&
    workOrderDetails.isEscalated !== '2' &&
    workOrderDetails.fmAssistStatus !== 'Fix Complete'

  const showDeleteForm =
    userFlag &&
    workOrderDetails.fmAssistStatus === 'New' &&
    userFlag &&
    !workOrderDetails.isHelpdeskAvail

  return (
    <Card className="opp-card-container opp-border-mob">
      {showRecallForm ? (
        <RecallForm workOrderDetails={workOrderDetails} />
      ) : null}
      {showDeleteForm ? (
        <DeleteForm workOrderDetails={workOrderDetails} />
      ) : null}
    </Card>
  )
}

export default ExtraFunctions
