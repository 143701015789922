import React from 'react'
import { Button } from '@jsluna/button'
import { Cancel } from '@jsluna/icons'
import { Caption } from '@jsluna/typography'
import moment from 'moment'
import { Filters } from '../../types/SearchWorkOrdersTypes'

interface filterPillsProps {
    filters: Filters,
    handleModal: (value: boolean) => void,
    removeFilter: (value: Filters) => void
}

const FilterPills: React.FC<filterPillsProps> = ({
    filters,
    handleModal,
    removeFilter,
})  => {

    const deleteFilter = (item: string) => {
        const updatedFilters = { ...filters, [item]: '' }
        removeFilter(updatedFilters)
    }
    return (
        <div className="ln-u-padding-ends">
            {Object.keys(filters).length !== 0 ? Object.entries(filters).map(([key, value]) => (
                value !== '' && value !== null && value !== undefined &&
                <Button
                    className="r-filter-pill"
                    id={`r-filter-pill-${moment.isMoment(value) ? value.format('DD-MM-YYYY') : value}`}
                    variant="filled"
                    onClick={() => deleteFilter(key)}
                >
                    <Caption>
                        {moment.isMoment(value) ? value.format('DD-MM-YYYY') : value} <Cancel />
                    </Caption>
                </Button>
            )) : ''}
            <Button
                className="r-more-filters-button"
                id="r-more-filters-button"
                onClick={() => handleModal(true)}
            >
                All filters
            </Button>
        </div>
    )
}

export default FilterPills