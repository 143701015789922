import { useContext } from "react"
import { WODetails } from "../types/WorkOrdersDetails/WorkOrderDetailsType"
import { AppContext } from "../context/AppContext"

const ProblemInfo: React.FC<{ workOrderDetails: WODetails }> = ({ workOrderDetails }) => {
    const context = useContext(AppContext)
  const siteName =
    workOrderDetails.siteName && workOrderDetails.siteName.charAt(0) === 'P'
      ? 'Petrol Filling Station (PFS)'
      : 'Store'
  const loggedInStoreName = context!.store!.storeCode
  return (
    <div className="ln-u-soft opp-btb">
      <h5> Problem Info </h5>
      <table className="ln-c-table ln-u-push-bottom">
        <tbody className="ln-c-table__body">
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Site </strong>
            </td>
            <td className="ln-c-table__cell">{siteName} &ndash; {loggedInStoreName} ({workOrderDetails?.siteName?.slice(1)})</td>
          </tr>
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Location </strong>
            </td>
            <td className="ln-c-table__cell">{workOrderDetails.location}</td>
          </tr>
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Location Details </strong>
            </td>
            <td className="ln-c-table__cell td-overflow-wrap">
              {workOrderDetails.equipLocationComments ? workOrderDetails.equipLocationComments : 'NA'}
            </td>
          </tr>
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Status </strong>
            </td>
            <td className="ln-c-table__cell">{workOrderDetails.operationalStatus}</td>
          </tr>
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Problem type </strong>
            </td>
            <td className="ln-c-table__cell">{workOrderDetails.problemType}</td>
          </tr>
          <tr className="ln-c-table__row">
            <td className="ln-c-table__cell">
              <strong> Contractor </strong>
            </td>
            <td className="ln-c-table__cell">
              {workOrderDetails.providerName ? workOrderDetails.providerName : 'NA'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
export default ProblemInfo
