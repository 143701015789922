const helpNumbers = {
  FMControlCentre: '0345 099 1000',
  HRHelpDesk: '0800 015 3030',
  EmergencyDesk: '0345 603 3763',
  ServiceDesk: '0345 603 2638',
  FoodProductionHelpDesk: '0161 804 0511',
  WilcomaticHelpDesk: '020 8649 5768'
}

export default helpNumbers
