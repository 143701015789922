/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { Card, GridWrapper, GridItem, ProgressSpinner } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import LinkedWOIcon from '../../assets/LinkedWOIcon'
import { AppContext } from '../../context/AppContext'
import { LinkedWorkOrderDetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { callFMAssistStatus, handleDateDesign } from '../../utils/functions'
import { useContext, useEffect } from 'react'
import workOrderHooks from '../../hooks/useWorkOrderDetails'

const LinkedWorkOrder = ({ woNumber }: any) => {
  const context = useContext(AppContext)
  let linkedWODetails: LinkedWorkOrderDetails[] | undefined

  const Header = (linkedWO: LinkedWorkOrderDetails[]) => {
    if (linkedWO.length > 1) {
      return 'Linked to the followup work orders'
    }
    if (linkedWO.length === 1 && linkedWO[0].isParentWo) {
      return 'Linked to the original work order'
    }
    if (linkedWO.length === 1 && linkedWO[0].isChildWo) {
      return 'Linked to the followup work order'
    }
    return ''
  }

  const [linkedWOData, linkedWOLoader] =
    workOrderHooks.useLinkedWorkOrdersDetails(woNumber)
  useEffect(() => {
    linkedWOLoader.current(woNumber)
  }, [context, linkedWOLoader, woNumber])

  if (linkedWOData.hasLoaded) {
    linkedWODetails = linkedWOData.data
  }

  if (!linkedWOData.hasLoaded) {
    return null
  }

  return (
    <Card className="opp-card-container opp-border-mob">
      <div className="ln-u-soft ln-u-border-ends opp-btb">
        <div className="icon-wrap">
          <span>
            <LinkedWOIcon aria-label={'linkedwo-icon'} />
          </span>
          {linkedWOData.hasLoaded ? (
            <span className="linked-stat">
              <h6>{Header(linkedWODetails!)}</h6>
            </span>
          ) : (
            <div className="ln-u-text-align-center">
              <ProgressSpinner />
            </div>
          )}
        </div>
        {linkedWODetails!.length < 1 ? <div>No linked work orders were found</div> : null}
        {linkedWODetails!.map(linkedData => (
          <Link
            className="a-no-link-style"
            to={{
              pathname: `/LWO/${linkedData.workOrderNumber}`
            }}
          >
            <div className="linked-wo-container">
              <GridWrapper matrix verticalAlign="middle">
                <GridItem size="9/10">
                  <div>
                    <b>{linkedData ? linkedData.subcategory : ''}</b>
                  </div>
                  <div>
                    {linkedData.assetNumber ? (
                      <b>{linkedData.assetNumber} / </b>
                    ) : null}
                    <b>WO {linkedData.workOrderNumber}</b>
                  </div>
                  <div className="ln-u-padding-ends*2">
                    Last updated {handleDateDesign(linkedData.dateModified, '')}
                  </div>
                  <div>
                    <span
                      className={callFMAssistStatus(linkedData.fmAssistStatus)}
                    >
                      {linkedData.fmAssistStatus}
                    </span>
                  </div>
                </GridItem>
                <GridItem size="1/10" verticalAlign="middle">
                  <div className="linked-content ln-u-text-align-right">
                    <ArrowRight aria-label="Right Arrow" />
                  </div>
                </GridItem>
              </GridWrapper>
            </div>
          </Link>
        ))}
      </div>
    </Card>
  )
}

export default LinkedWorkOrder
