import { useLocation, useNavigate } from "react-router-dom";

const APIError = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const apiErrorCode = location?.state?.errorCode; 
      if (!apiErrorCode) {
      navigate('/error')
    } else if (apiErrorCode === '401') {
      navigate('/unauthorised')
    } else if (apiErrorCode === '500') {
      navigate('/fivehundred')
    } else if (apiErrorCode === '404') {
      navigate('/fournotfour')
    } else {
      navigate('/error')
    }
    return <div>{null}</div>
  }
  
export default APIError