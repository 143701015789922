import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import { Container, FilledButton, OutlinedButton } from '@jsluna/react'
import Signs from '../assets/icons/FM_Assist_Illustration.png'
import mobileSigns from '../assets/icons/Mobile_Landing.png'
import Notification from '../components/notifications'

const FMAssistLanding = () => {
   const context = useContext(AppContext)

  if(context && context.user)
  return (
    <React.Fragment>
      <Notification />
      <Container size="xs" data-test="ReportProblem">
        <div className="ln-u-text-align-center report-container">
          <div className="wrapper">
            {/* {imageStatus ? ( */}
            <div className="contentWrap">
              <h1> Hello </h1>
              <p> How can we help you today? </p>
            </div>
            <img
              src={Signs}
              className="deskImage"
              width="100%"
              alt="Signs"
              // onLoad={handleImageLoaded}
            />
            <img
              src={mobileSigns}
              className="mobileImage"
              width="100%"
              alt="mobile signs"
              // onLoad={handleMobileImageLoaded}
            />
          </div>
          <Container>
            {context?.user?.isReadOnly !== '0' ? null : (
              <Link to={'/report_problem/enter_asset'}>
                <FilledButton
                  fullWidth
                  className="report-problem-button ln-u-margin-bottom*2"
                >
                  Report a problem
                </FilledButton>
              </Link>
            )}
            <br />
            <Link to={'/problem_overview'}>
              <OutlinedButton
                fullWidth
                className="view-existing-problem ln-u-margin-bottom*2"
              >
                Problem overview
              </OutlinedButton>
            </Link>
            <br />
            <Link to={'/find_workorder'}>
              <OutlinedButton
                fullWidth
                className="view-existing-problem ln-u-margin-bottom*2"
              >
                Find work order
              </OutlinedButton>
            </Link>
            <br />
            <Link to={'/check_equipment'}>
              <OutlinedButton
                fullWidth
                className="view-existing-problem ln-u-margin-bottom*2"
              >
                Check equipment status
              </OutlinedButton>
            </Link>
          </Container>
        </div>
      </Container>
      <Container className="rpo-footer-container" size="xs">
        <Link
          to={'/useful_numbers'}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
        >
          Unable to trade or a serious emergency?
        </Link>
      </Container>
    </React.Fragment>
  )
}

export default FMAssistLanding
