import { Container, Display1 } from '@jsluna/react'
import BackLink from '../components/BackLink'
import AssetTagForm from '../components/AssetTagForm'
import NoAssetTagButton from '../components/NoAssetTagButton'

const ReportProblem = () => {
  return (
    <div>
      {/* {!assetListPageFlag ? */}
      <Container size="xs">
        <BackLink linkTo={'/fmassist_landing'} />
        <div>
          <Display1 className="pageTitle">
            What&apos;s having the problem?
          </Display1>

          <AssetTagForm />
          <NoAssetTagButton />
        </div>
      </Container>
      {/* <SearchAssetList /> */}
      {/* } */}
    </div>
  )
}

export default ReportProblem
