import React, { useContext } from 'react'
import { FilledButton } from '@jsluna/react'
import LikeIcon from '../assets/LikeIcon'
import { AppContext, useApiClient } from '../context/AppContext'
import { useNavigate } from 'react-router'
import assetsApi from '../api/assetsApi'

const ZeroOpenProblems: React.FC<{assetId: string | undefined}> = (assetId) => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigate = useNavigate()

  const submitProblem = async () => {
    const siteId = context?.store?.storeCode;
    siteId && assetId && assetsApi.getAssetDetails(apiClient, assetId?.assetId, siteId).then(data => {
        if (data?.responseStatus === 200 && data?.response) {
          localStorage.setItem('WorkOrderType', 'WOWithAsset')
          context!.createWO!.assetDetails = data?.response;
          navigate('/report_problem/whats_happening', { state: { fromChckAst: 'checkasset', }, })
        } 
        else if (data?.responseStatus === 200 && !data?.response) {
          navigate('/location_not_matched')
        }
        else if (data?.responseStatus === 204) {
        navigate('/asset_not_present',{ state: {
            assetID: assetId,
            path: '/report_problem/enter_asset'
          },
        })
      } else if (data?.responseStatus === 500) {
        navigate('/apiError', {state: {errorCode: '500'}})
      } else {
        navigate('/apiError', {state: {errorCode: 'generic'}})
      }
    })
  }

  return (
    <div>
      <h5 className="pageTitle">No open problems</h5>
      <div className="awoinfo-container">
        <LikeIcon aria-label="Thumb Icon" />
        <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
          <h5>Nothing to see over here!</h5>
          <p>
            There are no open problems that have been reported for this asset.
          </p>
        </div>
      </div>
      {context?.user?.isReadOnly === '0' ? (
        <div className="fp-home-btn">
          <FilledButton
            fullWidth
            onClick={() => submitProblem()}
            style={{ marginBottom: '2rem' }}
            className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center nextMarginTop"
          >
            Report a Problem
          </FilledButton>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ZeroOpenProblems