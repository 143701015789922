import { OutlinedButton, GridWrapper, GridItem } from '@jsluna/react'
import { Cancel } from '@jsluna/icons'
import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import React from 'react'
import { checkMimeType } from '../../utils/functions'
import { ImageData } from '../../types/WorkOrdersDetails/Createworkorder'
import CameraIcon from '../../assets/CameraIcon'

const ImageUploader: React.FC<{
  imageType: string
  verificationTriggered: number
  passImagesVerification: (verified: boolean) => void
}> = ({
  imageType,
  verificationTriggered,
  passImagesVerification
}): JSX.Element => {
  const context = useContext(AppContext)
  let imageInput: any
  imageInput = React.createRef()
  const [emptyImageErrorMsg, setEmptyImageErrorMsg] = useState(false)
  const [imageBase64, setImageBase64] = useState<Array<any>>([])
  const [imageData, setImageData] = useState<Array<ImageData | Object>>([])
  const [imageDetails, setImageDetails] = useState<Array<any>>([])
  const buttonText = () => {
    switch (imageType) {
      case 'area': {
        return 'Upload image of area'
      }
      case 'fault': {
        return 'Upload image of fault'
      }
      case 'recall': {
        return <CameraIcon />
      }
      default: {
        return ''
      }
    }
  }

  const safeImages = () => {
    if (imageType === 'area') {
      context!.createWO!.imagesArea = imageDetails
    }
    if (imageType === 'fault') {
      context!.createWO!.imagesFault = imageDetails
    }
    if (imageType === 'recall') {
      context!.createWO!.images = imageDetails
    }
  }

  const verify = () => {
    if ((verificationTriggered > 0 || imageType === 'recall') && imageData.length > 0) {
      safeImages()
      passImagesVerification(true)
      return true
    }
    if (verificationTriggered === 0) {
      passImagesVerification(false)
      return true
    } else {
      passImagesVerification(false)
      return false
    }
  }

  const focusTextInput = () => {
    // Explicitly focus the text input using the raw DOM API
    // Note: we're accessing "current" to get the DOM node
    imageInput.current.click()
  }

  const setImage = (file: Object | ImageData) => {
    const areaImageItem = [file].filter(
      item => item !== undefined && item !== ''
    )
    setImageData([...imageData, areaImageItem[0]])
  }

  const compressImageSizeStep2 = (file: File) => {
    if (file.size / 1024 > 2000) {
      const fileName = file.name
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event: any) => {
        const img = new Image()
        img.src = event.target!.result
        img.onload = () => {
          const width = 1000
          const scaleFactor = width / img.width
          const elem = document.createElement('canvas')
          elem.width = width
          elem.height = img.height * scaleFactor
          const ctx = elem.getContext('2d')
          ctx!.drawImage(img, 0, 0, width, img.height * scaleFactor)
          ctx!.canvas.toBlob(
            blob => {
              const compressedFile = new File([blob!], fileName, {
                type: 'image/jpeg',
                lastModified: Date.now()
              })
              if (compressedFile) {
                setImage(compressedFile)
              }
            },
            'image/jpeg',
            1
          )
        }
      }
    } else {
      setImage(file)
      return file
    }
  }

  const getImagePreviewUrl = (file: File) => {
    let reader = new FileReader()
    reader.onloadend = () => {
      const input = reader.result
      const imageItem = [input].filter(
        item => item !== undefined && item !== ''
      )
      setImageBase64([...imageBase64, imageItem[0]])
    }
    reader.readAsDataURL(file)
  }

  const handleAddingImage = (target: any, type: string) => {
    if (target.target && target.target.type === 'file') {
      if (checkMimeType(target.target)) {
        if (target.target.files[0]) {
          setImageDetails([...imageDetails, target.target.files[0]])
          getImagePreviewUrl(target.target.files[0])
          compressImageSizeStep2(target.target.files[0])
          if (target.target.files[0].size !== 0) {
            setEmptyImageErrorMsg(false)
          } else {
            setEmptyImageErrorMsg(true)
          }
        }
      }
      target.value = ''
    }
  }

  const removeImage = (index: number, imageType: string) => {
    const areaImageBase64Temp = imageBase64
    const areaDataItem = [
      ...areaImageBase64Temp.slice(0, index),
      ...areaImageBase64Temp.slice(index + 1)
    ]
    setImageBase64(areaDataItem)
    const areaImageDataTemp = imageData
    const areaImage = [
      ...areaImageDataTemp.slice(0, index),
      ...areaImageDataTemp.slice(index + 1)
    ]
    setImageData(areaImage)
    if (areaDataItem.length !== 0) {
      const emptyImage2Flag = areaImage.every((item: any) => item.size > 0)
      setEmptyImageErrorMsg(!emptyImage2Flag)
    } else {
      setEmptyImageErrorMsg(false)
    }
  }

  const ImageArray =
    imageBase64 && imageBase64.length > 0
      ? imageBase64.filter((data: string) => data !== undefined)
      : []

  return (
    <div>
      <input
        type="file"
        id="areaImage"
        style={{ display: 'none' }}
        onChange={e => handleAddingImage(e, 'area')}
        accept=".jpg, .jpeg"
        ref={imageInput}
      />
      <GridWrapper matrix element="ul">
        {ImageArray &&
          ImageArray.map((data: string, index: number) => (
            <GridItem key={index} size={{ xs: '1/2' }} element="li">
              <div>
                <img
                  key={data}
                  src={data}
                  id="imgtag"
                  alt={`Image showing area around problem ${index + 1}`}
                  role="presentation"
                />
                <Cancel
                  className="ln-u-margin-left icon-top-left"
                  onClick={() => removeImage(index, 'area')}
                  aria-label="Close"
                />
              </div>
            </GridItem>
          ))}
      </GridWrapper>
      <OutlinedButton
        className="ln-u-margin-bottom*2 uploadMarginTop"
        fullWidth
        onClick={() => focusTextInput()}
        disabled={imageData.length >= 2}
      >
        {buttonText()}
      </OutlinedButton>
      {emptyImageErrorMsg ? (
        <React.Fragment>
          <div
            id="radio-button-field-5Validation"
            className="ln-c-field-info warning-info ln-c-field-info--error"
            role="alert"
          >
            The image you chose isn't valid. Please choose another image.
          </div>
        </React.Fragment>
      ) : (
        ''
      )}
      {verify() ? null : (
        <div
          id="radio-button-field-5Validation"
          className="ln-c-field-info warning-info ln-c-field-info--error ln-u-padding-bottom*2"
          role="alert"
        >
          You need to add at least one photo.
        </div>
      )}
    </div>
  )
}

export default ImageUploader
