import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const ProblemsToChase: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  function trackGAOverdue() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Overdue from Overview Dashboard',
        action: 'Overdue from Overview Dashboard',
        label: 'Overdue from Overview Dashboard',
      })
    }
  }

  function trackGAEscalated() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Escalated from Overview Dashboard',
        action: 'Escalated from Overview Dashboard',
        label: 'Escalated from Overview Dashboard',
      })
    }
  }

  return (
    <div className="ln-c-card ln-c-card--soft card-tiles">
      <h5 style={{ marginBottom: '1rem' }}>
        {' '}
        Problems taking longer than expected{' '}
      </h5>
      <div className="ln-o-grid ml-spacer">
        <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 OverDueProb">
          <Link to="/problem_overview/overdue">
            <div
              className="ln-u-soft ln-u-bg-color-black"
              onClick={() => trackGAOverdue()}
            >
              <span className="label">
                {workOrdersDashboard!.totalCountOfOverSLAWorkOrders}
              </span>
              <p> Overdue </p>
            </div>
          </Link>
        </div>
        <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 EscalatedProb">
          <Link to={'/problem_overview/escalated'}>
            <div
              className="ln-u-soft ln-u-bg-color-lg-grey"
              onClick={() => trackGAEscalated()}
            >
              <span className="label">
                {workOrdersDashboard!.totalCountOfEscalatedWorkOrders}
              </span>
              <p> Escalated </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ProblemsToChase
