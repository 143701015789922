import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import IResourceLoader from '../types/IResourceLoader'
import {
  LinkedWorkOrderDetails,
  WODetails,
  WorkOrderDetailsType,
  WorkOrderHistory,
  WorkOrdersDashboardType
} from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import {
  ILoadDuplicateWorkOrders,
  ILoadLinkedWorkOrderDetails,
  ILoadWorkOrderDetails,
  ILoadWorkOrderDetailsByWOnumber,
  ILoadWorkOrderDetailsWithoutAssetTag,
  ILoadWorkOrderHistory,
  ILoadWorkOrdersDashboard
} from '../types/WorkOrdersDetails/ILoadWorkOrderDetails'
import {
  FetchDuplicateWorkOrders,
  FetchLinkedWorkOrderDetails,
  FetchWorkOrderHistory,
  FetchWorkOrdersDashboard,
  FetchWorkOrdersDetails,
  FetchWorkOrdersDetailsByWOnumber,
  FetchWorkOrdersDetailsWithoutAssetTag
} from '../types/WorkOrdersDetails/FetchWorkOrderDetails'
import workOrderDetailsApi from '../api/workOrdersDetailsApi'
import { AssetPayloadType } from '../types/AssetCategories/AssetDetailsType'
import { AssetDetails } from '../types/WorkOrdersDetails/Createworkorder'

const useWorkOrdersDashboard = (
  siteId: string
): [IResourceLoader<WorkOrdersDashboardType[]>, ILoadWorkOrdersDashboard] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<WorkOrdersDashboardType[]>(
    'Unable to load work orders dashboard'
  )

  const loader = useCallbackRef<FetchWorkOrdersDashboard>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getWorkOrdersDashboardDetails(
          apiClient,
          siteId
        )
    )
  )

  return [response, loader]
}

const useWorkOrderDetailsWithoutAssetTag = (
  category: string,
  subCategory: string,
  location: string,
  siteId: string,
  currentPageNumber?: number,
  itemsPerPage?: number
): [
  IResourceLoader<WorkOrderDetailsType>,
  ILoadWorkOrderDetailsWithoutAssetTag
] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<WorkOrderDetailsType>(
    'Unable to load work orders details without asset tag'
  )

  const loader = useCallbackRef<FetchWorkOrdersDetailsWithoutAssetTag>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getOpenWoWithoutAssetTag(
          apiClient,
          category,
          subCategory,
          location,
          siteId,
          currentPageNumber,
          itemsPerPage
        )
    )
  )

  return [response, loader]
}

const useWorkOrdersDetails = (
  workOrdersType: string,
  currentPage: number,
  itemsPerPage: number,
  siteId: string
): [IResourceLoader<WorkOrderDetailsType>, ILoadWorkOrderDetails] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<WorkOrderDetailsType>(
    'Unable to load work orders details'
  )

  const loader = useCallbackRef<FetchWorkOrdersDetails>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getWorkOrders(
          apiClient,
          workOrdersType,
          currentPage,
          itemsPerPage,
          siteId
        )
    )
  )

  return [response, loader]
}

const useClosedWorkOrdersDetails = (
  currentPage: number,
  itemsPerPage: number,
  siteId: string,
  startDate: string | null,
  endDate: string | null
): [IResourceLoader<WorkOrderDetailsType>, ILoadWorkOrderDetails] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<WorkOrderDetailsType>(
    'Unable to load closed work orders details'
  )

  const loader = useCallbackRef<FetchWorkOrdersDetails>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getClosedWorkOrders(
          apiClient,
          currentPage,
          itemsPerPage,
          siteId,
          startDate,
          endDate
        )
    )
  )

  return [response, loader]
}

const useWorkOrderDetailsWithWorkOrderNumber = (
  workOrderNumber: string,
): [IResourceLoader<WODetails>, ILoadWorkOrderDetailsByWOnumber] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<WODetails>(
    'Unable to load work orders details'
  )

  const loader = useCallbackRef<FetchWorkOrdersDetailsByWOnumber>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getWorkOrderByAssetTag(
          apiClient,
          workOrderNumber
        )
    )
  )

  return [response, loader]
}

const useWorkOrderHistory = (
  workOrderNumber: string,
): [IResourceLoader<WorkOrderHistory>, ILoadWorkOrderHistory] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<WorkOrderHistory>(
    'Unable to load work orders details'
  )

  const loader = useCallbackRef<FetchWorkOrderHistory>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getWorkOrderHistory(
          apiClient,
          workOrderNumber
        )
    )
  )

  return [response, loader]
}

const useDuplicateWorkOrders = (assetPayload: AssetPayloadType | undefined, assetDetails: AssetDetails | undefined) : [IResourceLoader<WorkOrderDetailsType>, ILoadDuplicateWorkOrders] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<WorkOrderDetailsType>(
    'Unable to load duplicate work orders'
  )

  const loader = useCallbackRef<FetchDuplicateWorkOrders>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getDuplicateWOwithoutAssetTag(
          apiClient,
          assetPayload,
          assetDetails
        )
    )
  )

  return [response, loader]
}

const useLinkedWorkOrdersDetails = (
  workOrderNumber: string,
): [IResourceLoader<LinkedWorkOrderDetails[]>, ILoadLinkedWorkOrderDetails] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<LinkedWorkOrderDetails[]>(
    'Unable to load linked work orders details'
  )

  const loader = useCallbackRef<FetchLinkedWorkOrderDetails>(() =>
    response.load(
      async () =>
        await workOrderDetailsApi.getLinkedWorkOrder(
          apiClient,
          workOrderNumber
        )
    )
  )

  return [response, loader]
}

const workOrderHooks = {
  useWorkOrdersDashboard,
  useWorkOrderDetailsWithoutAssetTag,
  useWorkOrderDetailsWithWorkOrderNumber,
  useWorkOrdersDetails,
  useClosedWorkOrdersDetails,
  useWorkOrderHistory,
  useDuplicateWorkOrders,
  useLinkedWorkOrdersDetails
}

export default workOrderHooks
