import { useNavigate } from 'react-router-dom'
import { Container, Display2 } from '@jsluna/react'
import {
  WODetails,
  WOdetailsType
} from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import WorkOrderCard from './cards/WorkOrderCard'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import VisitCard from './cards/VisitCard'

const WorkOrdersList: React.FC<{ workOrdersDetails: WOdetailsType }> = ({
  workOrdersDetails
}): JSX.Element => {
  const context = useContext(AppContext)
  const navigateTo = useNavigate()

  const message = () => {
    switch (workOrdersDetails.workOrderType) {
      case 'asset_wo':
        return `${workOrdersDetails.numberOfWorkOrders} current open problems`
      case 'Open':
        return `${workOrdersDetails.numberOfWorkOrders} open problems`
      case 'ReviewFix':
        return `${workOrdersDetails.numberOfWorkOrders} recently closed problems`
      case 'OverSLA':
        return `${workOrdersDetails.numberOfWorkOrders} recalled problems`
      case 'escalated':
        return `${workOrdersDetails.numberOfWorkOrders} escalated problems`
      case 'PPM_Visits':
        return `Maintenance visits for next 28 days`
      case 'PPM_Remedial_FollowUp':
        return `${workOrdersDetails.numberOfWorkOrders} store maintenance follow up work`
      case 'Closed':
        return `${workOrdersDetails.numberOfWorkOrders} closed problems`
      case 'work_requests':
        return `${workOrdersDetails.numberOfWorkOrders} work requests`
      case 'Automated':
        return `${workOrdersDetails.numberOfWorkOrders} automated work orders`
      case 'All':
        return ''
      default:
        return null
    }
  }

  const handleClick = (workOrder: WODetails) => {
    if (!window.location.pathname.includes('visits')) {
      context!.workOrders!.WorkOrderDetails = workOrder
      navigateTo(`/problem_overview/${workOrder.workOrderNumber}`)
    }
  }

  return (
    <div>
      <div className="ln-u-padding*2">
        <Display2>{message()}</Display2>
      </div>

      {workOrdersDetails?.workOrders?.map(workOrder => (
        <>
          {!window.location.pathname.includes('visits') ? (
              <Container
                className={
                  workOrder.fmAssistStatus === 'Processing' ? 'op-disable' : ''
                }
                onClick={() => handleClick(workOrder)}
              >
                <WorkOrderCard workOrder={workOrder} />
              </Container>
          ) : (
           <Container>
            <VisitCard visit={workOrder} />
           </Container>
          )}
        </>
      ))}
    </div>
  )
}

export default WorkOrdersList
