import { Container, Pagination, ProgressSpinner } from '@jsluna/react'
import BackLink from '../../components/BackLink'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { WOdetailsType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import workOrderHooks from '../../hooks/useWorkOrderDetails'
import WorkOrdersList from '../../components/WorkOrdersList'
import NoWorkOrdersMessage from '../../components/NoWorkOrdersMessage'
import ErrorMessage from '../../components/errors/ErrorMessage'

const OpenProblemsPage = () => {
  const context = useContext(AppContext)
  const [currentPage, setCurrentPage] = useState(1)
  var workOrdersType = 'Open'
  var pageCapacity = 10
  var workOrdersDetails: WOdetailsType
  const [response, loader] = workOrderHooks.useWorkOrdersDetails(
    workOrdersType,
    currentPage,
    pageCapacity,
    context!.store!.storeCode
  )
  useEffect(() => {
    loader.current(workOrdersType, currentPage, pageCapacity, context!.store!.storeCode)
  }, [context, loader, currentPage, workOrdersType, pageCapacity])

  if (response?.data?.allWorkOrdersViewModel) {
    workOrdersDetails = {
      numberOfWorkOrders: response.data?.totalCountOfOpenWorkOrders,
      workOrders: response.data?.allWorkOrdersViewModel,
      workOrderType: workOrdersType
    }
  }

  if(response.hasError){
    return <ErrorMessage />
  }

  const showWOList = () => {
    if (
      response?.data?.allWorkOrdersViewModel &&
      response.data.allWorkOrdersViewModel.length > 0
    ) {
      return (
        <div>
          <WorkOrdersList workOrdersDetails={workOrdersDetails} />
          <div className="ln-u-text-align-center">
            <Pagination
              showFirstAndLast
              current={currentPage}
              total={response.data.numberOfOpenPages}
              onChange={(page: number, e: Event) => {
                e.preventDefault()
                setCurrentPage(page)
              }}
            />
          </div>
        </div>
      )
    }
    return <NoWorkOrdersMessage />
  }

  return (
    <Container size="xs">
      <BackLink linkTo={'/problem_overview'} />
      {!response.hasLoaded ? (
        <div className="ln-u-text-align-center">
          <ProgressSpinner />
        </div>
      ) : (
        showWOList()
      )}
    </Container>
  )
}

export default OpenProblemsPage
