import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Display1 } from '@jsluna/react'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const OpenProblems: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)
  
  function trackGA() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Open Problems from Overview Dashboard',
        action: 'Open Problems from Overview Dashboard',
        label: 'Open Problems from Overview Dashboard'
      })
    }
  }

  return (
    <div className="OpenProb">
      <Link to="/problem_overview/open">
        <div
          className="ln-c-card ln-c-card--soft card-tiles open-tile"
          onClick={() => trackGA()}
        >
          <Display1>
            {workOrdersDashboard?.totalCountOfOpenWorkOrders
              ? workOrdersDashboard!.totalCountOfOpenWorkOrders
              : 0}{' '}
            open problem
            {workOrdersDashboard &&
            workOrdersDashboard.totalCountOfOpenWorkOrders > 1
              ? 's'
              : ''}
          </Display1>
        </div>
      </Link>
    </div>
  )
}

export default OpenProblems
