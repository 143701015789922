import moment from 'moment'
import secrets from './configuration'
import { CryptoJS } from './aes'

//.env variable
// require('dotenv').config()

export const callFMAssistStatus = status => {
  switch (status) {
    case 'New':
      return 'tag_default'
    case 'On Hold':
      return 'tag_default'
    case 'Being Estimated':
      return 'tag_default'
    case 'Being Fixed':
      return 'tag_being_fixed'
    case 'Review Fix':
      return 'tag_review_fix'
    case 'Fix Incomplete':
      return 'tag_fix_incomplete'
    case 'Fix Complete':
      return 'tag_fix_complete'
    case 'Assigned To Engineer':
      return 'tag_default'
    case 'Cancelled':
      return 'tag_cancelled'
    case 'Linked WO':
      return 'linked_wo'
    default:
      return 'tag_default'
  }
}

export const fixedEncodeURIComponent = str =>
  encodeURI(str).replace(/[#*]/g, c => `%${c.charCodeAt(0).toString(16)}`)

export const viewLabelChange = str => {
  if (typeof str !== 'string') return ''
  let inputData = str.toLowerCase()
  inputData = inputData.charAt(0).toUpperCase() + inputData.slice(1)
  return inputData
}

// export const handleFormatWoNumber = woNumber => {
//   if (woNumber) {
//     String.prototype.chunk = function (n) {
//       var wo_array = []
//       for (var i = 0, len = this.length; i < len; i += n) {
//         wo_array.push(this.substr(i, n))
//       }
//       return wo_array
//     }
//   }
//   return woNumber.chunk(2).join(' ')
// }

export const checkMimeType = target => {
  //getting file object
  let files = target.files
  //define message container
  let err = ''
  // list allow mime type
  const types = ['image/jpg', 'image/jpeg']
  // loop access array
  for (var x = 0; x < files.length; x++) {
    // compare file type find doesn't matach
    if (types.every(type => files[x].type !== type)) {
      // create error message and assign to container
      err +=
        files[x].type +
        ' is not a supported format. Only .jpg and .jpeg is allowed'
    }
  }

  if (err !== '') {
    // if message not same old that mean has error
    target.value = null // discard selected file
    alert(err)
    return false
  }
  return true
}

export const toggleKeys = key => {
  const toggleFlag = JSON.parse(
    localStorage.getItem('OpenWorkOrdersDashboardStatus'),
    localStorage.getItem('FixedProblemsDashboardStatus')
    // localStorage('ProblemstoReviewDashboardStatus')
  )
  const visibility =
    toggleFlag && toggleFlag.filter(data => data.feature === key)
  const data = visibility && visibility.map(input => input.isEnable)
  return data
}

export const maxLengthCheck = data => {
  if (data === '') return ''
  let input
  if (data.length <= 256) {
    input = data
  } else {
    input = data.substring(0, 256)
  }
  return input
}

export const returnColleagueName = ColleagueName => {
  if (ColleagueName === '') return ''
  const currentUser = localStorage.getItem('current_user')
  const data = ColleagueName === currentUser ? 'you' : ColleagueName
  return data
}

export const returnTimeDifference = date => {
  const startDate = moment(new Date()) //todays date
  const endDate = moment(date)
  const hourDifference = startDate.diff(endDate, 'hours') // hour difference.
  const minuteDifference = startDate.diff(endDate, 'minutes')
  const sameDayFlag = endDate.isSame(moment(), 'day') // same day check.
  const previousDayFlag = moment().subtract(1, 'days').format('MM-DD-YYYY') // check present and previous date.
  const eventPreviousDayFlag = moment(date)
    .subtract(1, 'days')
    .format('MM-DD-YYYY')
  const oldDayFlag = previousDayFlag === eventPreviousDayFlag
  const eventFlag =
    moment().subtract(1, 'days').format('MM-DD-YYYY') ===
    moment(date).format('MM-DD-YYYY')
  let outputDay = ''

  if (hourDifference < 5 && hourDifference >= 1 && sameDayFlag) {
    // x hours ago.
    outputDay =
      hourDifference === 1 ? '1 hour ago' : `${hourDifference} hours ago`
  } else if (hourDifference > 4 && sameDayFlag) {
    // today.
    outputDay = `today at ${moment(date).format('H:mm A')} `
  } else if (!sameDayFlag && eventFlag) {
    // yesterday.
    outputDay = `yesterday at ${moment(date).format('H:mm A')} `
  } else if (!sameDayFlag && !oldDayFlag) {
    // send passed date.
    outputDay = `on ${moment(date).format('ddd DD MMM')} at ${moment(
      date
    ).format('h:mm A')}`
  } else if (hourDifference < 1) {
    outputDay =
      minuteDifference === 0
        ? 'a minute ago'
        : `${minuteDifference} minutes ago`
  }

  return outputDay
}

export const handleDateDesign = (date, status, state = '') => {
  const endDate = moment(date)
  const sameDayFlag = endDate.isSame(moment(), 'day') // same day check.
  const tomorrowDate = moment().add(1, 'days').format('MM-DD-YYYY')
  const yesterdayFlag =
    moment().subtract(1, 'days').format('MM-DD-YYYY') ===
    moment(date).format('MM-DD-YYYY')
  const tomorrowCheck = tomorrowDate === moment(date).format('MM-DD-YYYY')

  const showDay = date => {
    if (sameDayFlag) return <span className={status}>today</span>
    if (yesterdayFlag) return <span className={status}>yesterday</span>
    if (tomorrowCheck) return <span className={status}>tomorrow</span>
    return <span>
      {state === 'furtherwork' ? ' ' : 'by '}
      <span className={status}>{moment(date).format('ddd DD MMM')}</span>
    </span>
  }
    return (
      <span>
        {showDay(date)}
        {state === 'noTime' ? null : (
          <span>
            {' '}
            at <span className={status}>{moment(date).format('h:mm A')}</span>
          </span>
        )}
      </span>
    )
}

export const handleExpectedFixDate = (slaDate, etaDate, lastPauseDate) => {
  let presentDay = moment(new Date())
  let displayString = ''
  const overDueCheckStatus = checkWOstatus(slaDate, etaDate)
  const dueDaysCount =
    overDueCheckStatus && overDueCheckStatus.split(/(\d+)/).length > 1
      ? overDueCheckStatus.split(/(\d+)/).splice(1, 2).join('')
      : ''
  if (
    overDueCheckStatus.includes('over sla') &&
    lastPauseDate &&
    etaDate < presentDay
  ) {
    displayString = `${`${dueDaysCount} overdue`.fontcolor('#d7000').bold()}
                    <br />Engineer visited ${handleDateDesign(
                      lastPauseDate,
                      ''
                    )}`
  } else if (
    overDueCheckStatus.includes('over sla') &&
    lastPauseDate &&
    etaDate > presentDay
  ) {
    displayString = `${`${dueDaysCount} overdue`.fontcolor('#d7000').bold()}
                    <br />Engineer visited ${handleDateDesign(
                      lastPauseDate,
                      ''
                    )}. Further visit needed by ${handleDateDesign(
      etaDate,
      '#0c0c0c',
      'furtherwork'
    )}`
  } else if (lastPauseDate && etaDate < presentDay) {
    displayString = `Engineer visited ${handleDateDesign(
      lastPauseDate,
      ''
    )}`
  } else if (lastPauseDate && etaDate > presentDay) {
    displayString = `Engineer visited ${handleDateDesign(
      lastPauseDate,
      ''
    )}. Further visit needed by ${handleDateDesign(
      etaDate,
      '#0c0c0c',
      'furtherwork'
    )}`
  } else if (overDueCheckStatus.includes('within sla')) {
    displayString = `Engineer needs to visit by ${handleDateDesign(
      slaDate,
      '#0c0c0c',
      'within'
    )}`
  } else if (overDueCheckStatus.includes('only sla')) {
    displayString = `Engineer needs to visit by ${handleDateDesign(
      slaDate,
      '',
      'within'
    )}`
  } else if (overDueCheckStatus.includes('over eta no sla')) {
    displayString = `Engineer should have visited ${handleDateDesign(
      etaDate,
      '#d7000'
    )}` // warning
  } else if (overDueCheckStatus.includes('within eta no sla')) {
    displayString = `Engineer is visiting ${handleDateDesign(
      etaDate,
      ''
    )}`
  } else if (overDueCheckStatus.includes('show eta')) {
    displayString = `Engineer is visiting ${handleDateDesign(
      etaDate,
      '#0c0c0c'
    )}`
  } else if (overDueCheckStatus.includes('over sla')) {
    displayString = `${`${dueDaysCount} overdue`.fontcolor('#d7000').bold()}`
  } else if (overDueCheckStatus.includes('no sla no eta')) {
    displayString = 'Engineer visit date to be confirmed'
  }
  return displayString
}

export const checkImageCount = workOrderImageDetails => {
  const imageCount =
    workOrderImageDetails &&
    workOrderImageDetails.data &&
    workOrderImageDetails.data.length > 0
      ? workOrderImageDetails.data.filter(
          item =>
            (item.includes('%23') &&
              item.split('%23')[1].split('-')[0].split('_')[3].includes('A')) ||
            (item.includes('%23') &&
              item.split('%23')[1].split('-')[0].split('_')[3].includes('S'))
        ).length
      : ''
  let imageStatement = ''
  if (imageCount === 1) {
    imageStatement = `${imageCount} image attached`
  } else if (imageCount > 1) {
    imageStatement = `${imageCount} images attached`
  }
  return imageStatement
}

export const checkWOstatus = workOrder => {
  const currentDate = moment(new Date()) //todays date
  const slaDate = moment(workOrder.fixDueDate)
  const etaDate = moment(workOrder.eta)
  let dueDays = ''
  let overdue = ''

  if (workOrder.fmAssistStatus === 'Fix Complete') {
    return ['fix complete', null]
  }

  if (workOrder.fmAssistStatus === 'Cancelled') {
    return ['cancelled', null]
  }

  if (workOrder.fixDueDate === '' && workOrder.eta === '') {
    overdue = 'no sla no eta'
  }

  if (workOrder.fixDueDate && workOrder.eta === '') {
    if (slaDate < currentDate) {
      overdue = 'over sla no eta'
      dueDays = daysCalculator(currentDate, slaDate)
    } else {
      overdue = 'only sla'
    }
  }

  if (workOrder.eta && workOrder.fixDueDate === '') {
    if (etaDate < currentDate) {
      overdue = 'over eta no sla'
    } else {
      overdue = 'within eta no sla'
    }
  }

  if (workOrder.fixDueDate && workOrder.eta) {
    if (etaDate > currentDate && slaDate > currentDate) {
      overdue = 'show eta'
    } else if (etaDate < currentDate && slaDate > currentDate) {
      overdue = 'within sla'
    } else if (slaDate <= currentDate) {
      overdue = 'over sla'
      dueDays = daysCalculator(currentDate, slaDate)
    }
  }

  return [overdue, dueDays]
}

export const daysCalculator = (presentDay, slaDate) => {
  let dueDays = ''
  if (presentDay.diff(slaDate, 'hours') < 24) {
    if (
      presentDay.diff(slaDate, 'hours') === 0 ||
      presentDay.diff(slaDate, 'hours') === 1
    ) {
      dueDays = `1 hour`
    } else {
      dueDays = `${presentDay.diff(slaDate, 'hours')} hours`
    }
  } else if (
    presentDay.diff(slaDate, 'days') === 0 ||
    presentDay.diff(slaDate, 'days') === 1
  ) {
    dueDays = `1 day`
  } else if (presentDay.diff(slaDate, 'days') > 1) {
    dueDays = `${presentDay.diff(slaDate, 'days')} days`
  }
  return dueDays
}

export const fixCompleted = fixedDate => {
  return `${`Fixed on ${moment(fixedDate).format('ddd DD MMM')} at ${moment(
    fixedDate
  ).format('h:mm A')}`}`
}

export const cancelledDate = cancelledOn => {
  return `${`Cancelled on ${moment(cancelledOn).format(
    'ddd DD MMM'
  )} at ${moment(cancelledOn).format('h:mm A')}`}`
}

export const highlightProblemCard = (slaDate, etaDate) => {
  let highlightCard = ''
  const overDueCheckStatus = checkWOstatus(slaDate, etaDate)
  if (overDueCheckStatus.includes('over sla')) {
    highlightCard = 'opp-card-warning' // warning
  }
  return highlightCard
}

export const reformatCapsMessage = message => {
  let inputData = message.toLowerCase()
  return inputData.charAt(0).toUpperCase() + inputData.slice(1)
}
export const encryptString = (data) => {
  var key = CryptoJS.enc.Utf8.parse(secrets.encryption_key);
  var iv = CryptoJS.enc.Utf8.parse(secrets.encryption_iv);
  var value = CryptoJS.AES.encrypt(String(data), key, {
    keySize: 128 / 8,
    iv: iv,
  });
  var encryptedData = value.toString();
  return encryptedData;
}