import { useMsal } from "@azure/msal-react";

export const SignOutButton = () => {

    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
    }

    return (     
        <a onClick={() => handleLogout()} key="logout" href="#signout">Sign out </a>
    )
};

export default SignOutButton;