import { ApiClient } from './ApiClient'
import { IGetApiRequest, IPostApiRequest, IPutApiRequest } from '../types/IWebApiRequest'

const get = async <T>(
  apiClient: ApiClient,
  req: IGetApiRequest
): Promise<T> => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  // append query and optional parameters
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  return await apiClient.get(fullUrl).then(res => res as T)
}

const getWithStatus = async (
  apiClient: ApiClient,
  req: IGetApiRequest
) => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  // append query and optional parameters
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  return await apiClient.getWithStatus(fullUrl).then(res => res)
}

const post = async <T>(
  apiClient: ApiClient,
  req: IPostApiRequest
): Promise<T> => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  // append query and optional parameters
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  return await apiClient.post(fullUrl, req.body, req.contentType).then(res => res as T)
}

const put = async <T>(
  apiClient: ApiClient,
  req: IPutApiRequest
): Promise<T> => {
  const encoded = req.params.map(n => encodeURI(n)).join('/')
  // append query and optional parameters
  const fullUrl = req.params.length ? `${req.url}/${encoded}` : req.url

  let response =  await apiClient.put(fullUrl, req.body).then(res => res as T)

  return response
}


const webApi = {
  get,
  post,
  put,
  getWithStatus
  // TODO: add POST etc...
}

export default webApi
