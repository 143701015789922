import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import IResourceLoader from '../types/IResourceLoader'
import storeCareTeamApi from '../api/storeCareTeamApi'
import { StoreCareTeamType } from '../types/StoreCareTeam/StoreCareTeamType'
import { FetchStoreCareTeam } from '../types/StoreCareTeam/FetchStoreCareTeam'
import { ILoadStoreCareTeam } from '../types/StoreCareTeam/ILoadStoreCareTeam'

const useStoreCareTeam = (siteId: string): [IResourceLoader<StoreCareTeamType>, ILoadStoreCareTeam] => {
  const apiClient = useApiClient();
  const response = useResourceLoader<StoreCareTeamType>('Unable to load store care team details')

  const loader = useCallbackRef<FetchStoreCareTeam>(() =>
    response.load(async () => await storeCareTeamApi.getStoreCareTeam(apiClient, siteId))
  )

  return [response, loader]
}

export default useStoreCareTeam
