import { Card, Display1 } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import {
  callFMAssistStatus,
  handleDateDesign,
  reformatCapsMessage
} from '../../utils/functions'
import { WODetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import ExtraWODetailsMessage from './ExtraWODetailsMessage'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const WorkOrderCard: React.FC<{ workOrder: WODetails }> = ({ workOrder }) => {
  const context = useContext(AppContext)
  const isWorkOrderDetailsPage = () => {
    // return (window.location.pathname.includes('problem_overview')) && window.location.pathname.split('problem_overview/')[1].match(/\d+/g)
    return /\d+/g.test(window.location.pathname)
  }

  const handleClick = () => {
    if (!isWorkOrderDetailsPage) {
      context!.workOrders!.WorkOrderDetails = workOrder
    }
  }

  return (
    <Card
      className={
        isWorkOrderDetailsPage()
          ? 'opp-card-container opp-border-mob'
          : 'op-spacer'
      }
      style={isWorkOrderDetailsPage() ? {} : { cursor: 'pointer' }}
      key={workOrder.workOrderNumber}
      onClick={() => handleClick()}
    >
      <div
        className={isWorkOrderDetailsPage() ? 'ln-u-soft opp-btb' : 'opp-width'}
      >
        {workOrder?.subcategory ? (
          <Display1>{reformatCapsMessage(workOrder?.subcategory)}</Display1>
        ) : null}

        <>
          {workOrder.fmAssistStatus !== 'Processing' ? (
            <div className="ln-u-padding-top">
              {workOrder.assetNumber ? (
                <span>
                  <span className="ln-u-color-grey-50">
                    {workOrder.assetNumber}
                  </span>
                  {' / '}
                </span>
              ) : null}
              WO {workOrder.workOrderNumber}
            </div>
          ) : null}
        </>

        <div>{workOrder.problemType}</div>
        <div className="ln-u-padding-bottom*2">{workOrder.location}</div>
        <div>
          <span className={callFMAssistStatus(workOrder.fmAssistStatus)}>
            {workOrder.fmAssistStatus}
          </span>
          {workOrder.isLinkedWo ? (
            <span className={'linked_wo'}>{'Linked work order'}</span>
          ) : null}
        </div>
        <ExtraWODetailsMessage workOrder={workOrder} />

        {isWorkOrderDetailsPage() ? (
          <div className="ln-u-padding-top*2">
            <div>{workOrder.problemDescription}</div>
            {workOrder.history?.workOrderHistoryDetails[0] ? (
              <div className="ln-u-padding-top*2 ln-u-text-align-right">
                Last updated by{' '}
                {workOrder.history?.workOrderHistoryDetails[0].eventPerformedBy}
                ,{' '}
                {handleDateDesign(
                  workOrder.history?.workOrderHistoryDetails[0].eventDate,
                  ''
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      {isWorkOrderDetailsPage() ? null : (
        <div className="rgt-arrow">
          <div className="ln-u-text-align-right">
            <ArrowRight aria-label="Right Arrow" />
          </div>
        </div>
      )}
    </Card>
  )
}

export default WorkOrderCard
