import React, { useContext } from 'react'
import { Container, Heading5 } from '@jsluna/react'
import { AppContext } from '../context/AppContext'
import helpNumbers from '../utils/helpNumbers'

const ContactHelpDesk = () => {
    const context = useContext(AppContext)
    const probablyPhone =
        /iphone|android|ie|blackberry|fennec/.test(
          navigator.userAgent.toLowerCase(),
        ) && 'ontouchstart' in document.documentElement
    const getHelpDeskData = context!.createWO!.assetRequirements
    const getAssetData = context!.createWO!.assetDetails
    const content =  getHelpDeskData ?? getAssetData
    const contractorName = content?.contractorName ?? ''
    const displayCntr = contractorName === 'Millers Vanguard' ? "Miller's Vanguard engineers" : contractorName
    const header =  contractorName === 'Millers Vanguard' ? 'food production' : 'petrol station valeting equipment'
    return (
        <div>
        <Container size="xs" data-test="MillerHelpdesk">

            <Heading5 element="p">{`Report a ${header} problem`}</Heading5>
            <p>
            {`Please contact ${displayCntr}, to give you
            specialist advice and support.`}
            </p>
            {`Contact ${displayCntr} on:`} <br />
            {contractorName === 'Millers Vanguard' ?
                <span>
                {!probablyPhone ? (
                <h4>{helpNumbers.FoodProductionHelpDesk}</h4>
                ) : (
                    <h4>
                    <a
                        href={'tel:' + helpNumbers.FoodProductionHelpDesk}
                    >
                        {' '}
                        {helpNumbers.FoodProductionHelpDesk}
                    </a>
                    </h4>
                )}
            </span>
            : ''}
            {contractorName === 'Wilcomatic' ?
                <span>
                {!probablyPhone ? (
                <h4>{helpNumbers.WilcomaticHelpDesk}</h4>
                ) : (
                    <h4>
                    <a
                        href={'tel:' + helpNumbers.WilcomaticHelpDesk}
                    >
                        {' '}
                        {helpNumbers.WilcomaticHelpDesk}
                    </a>
                    </h4>
                )}
            </span>
            : ''}
            {contractorName === 'Millers Vanguard' ?
            <React.Fragment>
            {`In Northern Ireland stores please contact the FM Control Centre on:`} <br />
            <span>
            {!probablyPhone ? (
                <h4>{helpNumbers.FMControlCentre}</h4>
            ) : (
                <h4>
                    <a href={'tel:' + helpNumbers.FMControlCentre}>
                    {helpNumbers.FMControlCentre}
                    </a>
                </h4>
                )}
            </span>
            </React.Fragment>
            : "" }
        </Container>
        </div>
    )
}

export default ContactHelpDesk