import { Link } from 'react-router-dom'
import { Header, Container, FilledButton } from '@jsluna/react'
import ErrorIcon from '../../assets/ErrorIcon'

const WorkOrderNotFoundMessage = () => {
  return (
    <div>
      <Header />
      <Container size="xs">
        <div className="generic-container">
          <ErrorIcon />
          <div className="ln-u-text-align-left margin-top-small generic-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
            <h5>Work Order not found</h5>
            <p>
              We couldn't find the work order you searched for. Try searching by the work order number, in the store it has been logged in. 
              Alternatively view all work orders in the 'Find work order' page and use the filters.
            </p>
          </div>
          <Link to="/find_workorder ">
            <FilledButton className="generic-home-button">Find work order</FilledButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default WorkOrderNotFoundMessage
