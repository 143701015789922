import { Link } from 'react-router-dom'
import { Container } from '@jsluna/react'
import { ArrowRight, InfoCircle } from '@jsluna/icons'

const RejectedRecallNotification = ({ notificationDetails }: any) => {
  return (
    <div className="alert" role="alert">
      <Container size="xs">
        <Link
          to={{
            pathname: `/problem_overview/closed/WO${notificationDetails.description}`
          }}
        >
          <div className="notification-container">
            <div>
              <InfoCircle className="ln-u-margin-left icon-right" />
              {' Recall has been processed, please review '}
              <strong>
                <span className="recall-rejected">
                  {`WO ${notificationDetails.description}`}
                </span>{' '}
              </strong>{' '}
              {'for details'}
            </div>
            <div>
              <ArrowRight />
            </div>
          </div>
        </Link>
      </Container>
    </div>
  )
}

export default RejectedRecallNotification
