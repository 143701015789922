import { ProgressIndicator, ProgressBar } from '@jsluna/react'

const ProgressLoader: React.FC<{currentStep: number, numberOfSteps: number}> = ({ currentStep, numberOfSteps }) => {
  const progress = (100 / numberOfSteps) * currentStep

  return (
    <div className="ln-u-display-flex">
      <ProgressIndicator
        value={progress}
        loading={true && progress <= 100}
        preventFocus="true"
        tabIndex="-1"
      >
        <ProgressBar />
      </ProgressIndicator>
    </div>
  )
}

export default ProgressLoader
