import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const WorkRequests: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  function trackGAWorkRequests() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Work Requests from Overview Dashboard',
        action: 'Work Requests from Overview Dashboard',
        label: 'Work Requests from Overview Dashboard',
      })
    }
  }


  return (
    <div className="ClosedProb">
      <Link to="/problem_overview/work_requests">
        <div
          className="ln-c-card ln-c-card--soft card-tiles open-tile"
          onClick={() => trackGAWorkRequests()}
        >
          <h5>
          {workOrdersDashboard?.totalCountOfWorkRequestWorkOrders
              ? workOrdersDashboard!.totalCountOfWorkRequestWorkOrders
              : 0}{' '}
            work request
            {workOrdersDashboard && workOrdersDashboard.totalCountOfWorkRequestWorkOrders > 1 ? 's' : ''}
          </h5>
        </div>
      </Link>
    </div>
  )
}

export default WorkRequests
