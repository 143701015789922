import { Container, ProgressSpinner, Pagination } from '@jsluna/react'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/AppContext'
import AssetCard from '../components/cards/AssetCard'
import BackLink from '../components/BackLink'
import NoAssetMessage from '../components/NoAssetMessage'
import workOrderHooks from '../hooks/useWorkOrderDetails'
import WorkOrdersList from '../components/WorkOrdersList'
import { WOdetailsType } from '../types/WorkOrdersDetails/WorkOrderDetailsType'

const AssetStatusPage = () => {
  const context = useContext(AppContext)
  var payload = context!.assetPayload

  const [currentPage, setCurrentPage] = useState(1)
  var pageCapacity = 10
  var workOrdersDetails: WOdetailsType

  const [response, loader] = workOrderHooks.useWorkOrderDetailsWithoutAssetTag(
    payload!.category,
    payload!.subcategory,
    payload!.location,
    payload!.siteId,
    currentPage,
    pageCapacity
  )
  useEffect(() => {
    loader.current(
      payload!.category,
      payload!.subcategory,
      payload!.location,
      payload!.siteId,
      currentPage,
      pageCapacity
    )
  }, [context, loader, currentPage])

  if (
    response?.data?.allWorkOrdersViewModel &&
    response.data!.allWorkOrdersViewModel.length > 0
  ) {
    workOrdersDetails = {
      numberOfWorkOrders: response.data?.totalCountOfWorkOrders,
      workOrders: response.data?.allWorkOrdersViewModel,
      workOrderType: 'asset_wo'
    }
  }

  const showWOList = () => {
    if (
      response?.data?.allWorkOrdersViewModel &&
      response.data.allWorkOrdersViewModel.length > 0
    ) {
      return (
        <div>
          <WorkOrdersList workOrdersDetails={workOrdersDetails} />
          <div className="ln-u-text-align-center">
            <Pagination
              showFirstAndLast
              current={currentPage}
              total={response.data.numberOfPages}
              onChange={(page: number, e: Event) => {
                e.preventDefault()
                setCurrentPage(page)
              }}
            />
          </div>
        </div>
      )
    }
    return <NoAssetMessage />
  }

  return (
    <Container size="xs">
      <BackLink />
      <AssetCard />
      {!response.hasLoaded ? (
        <div className="ln-u-text-align-center">
          <ProgressSpinner />
        </div>
      ) : (
        showWOList()
      )}
    </Container>
  )
}

export default AssetStatusPage
