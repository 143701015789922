import { Container, Card } from '@jsluna/react'
import NoProblemsFoundIcon from '../../assets/NoProblemsFoundIcon'

const NoProblemFound = () => (
    <Container size="xs">
        <div className="awoinfo-container">
            <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
                <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft no-problem-found-container">
                    <NoProblemsFoundIcon aria-label="Thumb Icon" />
                    <h5>
                        No results found{' '}
                    </h5>
                    <p>
                        There are no results with the filters / search term you've added. Please
                        remove or expand out your filters or use either the filters or search to
                        start to find some work orders.
                    </p>
                </Card>
            </div>
        </div>
    </Container>
)

export default NoProblemFound
