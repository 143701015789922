import { FC, ReactNode, createContext, useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { ApiClient } from '../api/ApiClient'
import { IStore } from '../types/IStore'
import { UserDetailsType } from '../types/UserDetails/UserDetailsType'
import { NotificationsType } from '../types/Notifications/NotificationsType'
import {
  AssetDetailsType,
  AssetPayloadType
} from '../types/AssetCategories/AssetDetailsType'
import {
  WODetailsWithAssetNumber,
  WorkOrdersDetailsType
} from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import { WorkOrderCreation } from '../types/WorkOrdersDetails/Createworkorder'
import { Filters } from '../types/SearchWorkOrdersTypes'

type AppContextType = {
  apiClient: ApiClient
  apiScope: string
  tutorialServiceScope: string
  store?: IStore | null
  user?: UserDetailsType | undefined
  notifications?: NotificationsType | undefined
  assets?: AssetDetailsType[] | undefined
  assetPayload?: AssetPayloadType | undefined
  workOrders?: WorkOrdersDetailsType
  createWO?: WorkOrderCreation
  asset?: string,
  storeCheckAssetData: WODetailsWithAssetNumber | boolean,
  filters: Filters
}

export const AppContext = createContext<AppContextType | null>(null)

type AppProviderProps = {
  apiScope: string
  tutorialServiceScope: string
  apiBaseUrl: string
  store?: IStore | null
  children?: ReactNode
  user?: UserDetailsType | undefined
  notifications?: NotificationsType | undefined
  assets?: AssetDetailsType[] | undefined
  assetPayload?: AssetPayloadType | undefined
  workOrders?: WorkOrdersDetailsType
  createWO?: WorkOrderCreation
  asset?: string
  storeCheckAssetData: WODetailsWithAssetNumber | boolean,
  filters: Filters
}

export const AppProvider: FC<AppProviderProps> = ({
  apiScope,
  tutorialServiceScope,
  apiBaseUrl,
  store,
  children,
  user,
  notifications,
  assets,
  assetPayload,
  workOrders,
  createWO,
  asset,
  storeCheckAssetData,
  filters
}) => {
  const { instance } = useMsal()
  const apiClient = new ApiClient(
    instance,
    apiScope,
    tutorialServiceScope,
    apiBaseUrl
  )

  return (
    <AppContext.Provider
      value={{
        apiClient,
        apiScope,
        tutorialServiceScope,
        store,
        user,
        notifications,
        assets,
        assetPayload,
        workOrders,
        createWO,
        asset,
        storeCheckAssetData,
        filters
      }}
    >
      {' '}
      {children}{' '}
    </AppContext.Provider>
  )
}

export function useApiClient() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}
