import { Link } from 'react-router-dom'

const ChangeStore = () => {
  return (
    <>
      <Link
        to="/changestore"
        className="ln-c-topbar__text ln-u-body-1 ln-u-text-decoration-underline"
      >
        Change store
      </Link>
    </>
  )
}

export default ChangeStore
