import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const ClosedProblems: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  const trackGA = () => {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Closed Problems from Overview Dashboard',
        action: 'Closed Problems from Overview Dashboard',
        label: 'Closed Problems from Overview Dashboard'
      })
    }
  }

  return (
    <div className="ClosedProb">
      <Link to="/problem_overview/closed">
        <div
          className="ln-c-card ln-c-card--soft card-tiles open-tile"
          onClick={() => trackGA()}
        >
          <h5>
            {workOrdersDashboard?.totalCountOfClosedWorkOrders
              ? workOrdersDashboard!.totalCountOfClosedWorkOrders
              : 0}{' '}
            closed problem
            {workOrdersDashboard &&
            workOrdersDashboard.totalCountOfClosedWorkOrders > 1
              ? 's'
              : ''}
          </h5>
        </div>
      </Link>
    </div>
  )
}

export default ClosedProblems
