import { Link } from 'react-router-dom'
import { Header, Container, FilledButton } from '@jsluna/react'
import ErrorIcon from '../../assets/ErrorIcon'

const ErrorMessage = () => {
  return (
    <div>
      <Header />
      <Container size="xs">
        <div className="generic-container">
          <ErrorIcon />
          <div className="ln-u-text-align-left margin-top-small generic-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
            <h5>We found a problem</h5>

            <p>
              Having repeated problems or need urgent access? Please contact the
              Sainsbury's Tech Service Desk (STSD).
            </p>
          </div>
          <Link to="/fmassist_landing">
            <FilledButton className="generic-home-button">Home</FilledButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default ErrorMessage
