import { FilledButton, Container } from '@jsluna/react'
import helpNumbers from '../../src/utils/helpNumbers'
import { useLocation, useNavigate } from 'react-router-dom'
import ErrorIcon from '../assets/ErrorIcon'

const AssetNotFoundMessage = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const probablyPhone = 
        /iphone|android|ie|blackberry|fennec/.test(
          navigator.userAgent.toLowerCase(),
        ) && 'ontouchstart' in document.documentElement
    const path = location?.state?.path
    const redirect = () => {
    navigate(path, {state: {assetId: location?.state?.assetID } })
    }
    return (
      <div>
        <Container size="xs">
          <div className="awoinfo-container">
            <ErrorIcon />
            <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
              <h5>We couldn&apos;t find what you were looking for</h5>
              <p>
                We&apos;ve checked the asset tag{' '}
                <strong>{location?.state?.assetID}</strong> and not
                found any information.
              </p>
              <h5>Try again?</h5>
              <p>
                Either rescan the piece of equipment or try entering either the
                asset tag manually.
              </p>
              <p>
                If you&apos;re still having problems, please contact the FM Control Centre on
                {!probablyPhone ? (
                  <strong> {helpNumbers.FMControlCentre} </strong>
                ) : (
                    <strong>
                      <a href={"tel:" + helpNumbers.FMControlCentre}> {helpNumbers.FMControlCentre}</a>
                    </strong>
                  )}
                .
              </p>
            </div>
            <FilledButton
              className="awoinfo-missing-button"
              onClick={() => {
                redirect()
              }}
            >
              Back
            </FilledButton>
          </div>
        </Container>
      </div>
    )
  }

export default AssetNotFoundMessage