import {
  Container,
  Pagination,
  ProgressSpinner
} from '@jsluna/react'
import { DateRangePicker } from '@jsluna/date-picker'
import '@jsluna/date-picker/react-dates-initialize'
import BackLink from '../../components/BackLink'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { WOdetailsType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import workOrderHooks from '../../hooks/useWorkOrderDetails'
import WorkOrdersList from '../../components/WorkOrdersList'
import NoWorkOrdersMessage from '../../components/NoWorkOrdersMessage'
import ErrorMessage from '../../components/errors/ErrorMessage'
import moment from 'moment'

const ClosedProblemsPage = () => {
  const context = useContext(AppContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [startDate, setStartDate] = useState<string | null>('')
  const [endDate, setEndDate] = useState<string | null>('')
  const [focusedInput, setFocusedInput] = useState(null)

  var workOrdersType = 'Closed'
  var pageCapacity = 10
  var workOrdersDetails: WOdetailsType
  const [response, loader] = workOrderHooks.useClosedWorkOrdersDetails(
    currentPage,
    pageCapacity,
    context!.store!.storeCode,
    startDate,
    endDate
  )
  useEffect(() => {
    window.scroll(0, 0)
    loader.current(
      workOrdersType,
      currentPage,
      pageCapacity,
      context!.store!.storeCode
    )
  }, [context, loader, currentPage, startDate, endDate, workOrdersType, pageCapacity])

  if (response?.data?.allWorkOrdersViewModel) {
    workOrdersDetails = {
      numberOfWorkOrders: response.data?.totalCountOfWorkOrders,
      workOrders: response.data?.allWorkOrdersViewModel,
      workOrderType: workOrdersType
    }
  }

  if (response.hasError) {
    return <ErrorMessage />
  }

  const showWOList = () => {
    if (
      response?.data?.allWorkOrdersViewModel &&
      response.data.allWorkOrdersViewModel.length > 0
    ) {
      return (
        <div>
          <WorkOrdersList workOrdersDetails={workOrdersDetails} />
          <div className="ln-u-text-align-center">
            <Pagination
              showFirstAndLast
              current={currentPage}
              total={response.data.numberOfPages}
              onChange={(page: number, e: Event) => {
                e.preventDefault()
                setCurrentPage(page)
              }}
            />
          </div>
        </div>
      )
    }
    return <NoWorkOrdersMessage />
  }

  return (
    <Container size="xs">
      <BackLink linkTo={'/problem_overview'} />
      <h5>Choose a date range</h5>
        <div>
      <DateRangePicker
          startDate={startDate}
          startDateId="drp-start-date"
          endDate={endDate}
          endDateId="drp-end-date"
          orientation={
            window.matchMedia("(max-width: 700px)").matches
                ? "vertical"
                : "horizontal"
          }
          onDatesChange={({ startDate, endDate }: any) => {
            setStartDate(startDate)
            setEndDate(endDate)
          }}
          isOutsideRange={(day: any) =>
            day.isBefore(moment().subtract(6, 'month')) ||
            day.isAfter(moment())
          }
          focusedInput={focusedInput}
          onFocusChange={(input: any) => setFocusedInput(input)}
          numberOfMonths={2}
          displayFormat="DD/MM/YYYY"
          keepOpenOnDateSelect={false}
        />
      </div>
      {!response.hasLoaded ? (
        <div className="ln-u-text-align-center">
          <ProgressSpinner />
        </div>
      ) : (
        showWOList()
      )}
    </Container>
  )
}

export default ClosedProblemsPage
