import { ApiClient } from './ApiClient'
import webApi from './webApi'
import {
  LinkedWorkOrderDetails,
  WODetails,
  WODetailsWithAssetNumberAndStatus,
  WorkOrderDetailsType,
  WorkOrderDetailsWithStatusType,
  WorkOrderHistory,
  WorkOrdersDashboardType
} from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import { Filters } from '../types/SearchWorkOrdersTypes'
import moment from 'moment'
import { AssetPayloadType } from '../types/AssetCategories/AssetDetailsType'
import { AssetDetails } from '../types/WorkOrdersDetails/Createworkorder'

const getWorkOrdersDashboardDetails = async (
  apiClient: ApiClient,
  siteId: string
): Promise<WorkOrdersDashboardType[]> =>
  await webApi.get<WorkOrdersDashboardType[]>(apiClient, {
    url: `WorkOrders/GetSurfaceDashboardMetrics/?siteNum=${siteId}`,
    params: []
  })

const getOpenWoWithoutAssetTag = async (
  apiClient: ApiClient,
  _category: string,
  _subCategory: string,
  _location: string,
  _siteId: string,
  currentPageNumber?: number,
  itemsPerPage?: number
): Promise<WorkOrderDetailsType> => {
  const category = encodeURIComponent(_category)
  const subCategory = encodeURIComponent(_subCategory)
  const location = encodeURIComponent(_location)
  const siteId = encodeURIComponent(_siteId)

  return await webApi.get<WorkOrderDetailsType>(apiClient, {
    url: `WorkOrders/OpenWorkOrdersWithoutAssetNumber/?siteNum=${siteId}&category=${category}&subCategory=${subCategory}&location=${location}&pageNumber=${currentPageNumber}&pageSize=${itemsPerPage}`,
    params: []
  })
}

const getWorkOrders = async (
  apiClient: ApiClient,
  workOrdersType: string,
  currentPage: number,
  itemsPerPage: number,
  siteId: string
): Promise<WorkOrderDetailsType> =>
  await webApi.get<WorkOrderDetailsType>(apiClient, {
    url: `WorkOrders/${siteId}/${currentPage}/${itemsPerPage}/${workOrdersType}`,
    params: []
  })

const getClosedWorkOrders = async (
  apiClient: ApiClient,
  currentPage: number,
  itemsPerPage: number,
  siteId: string,
  startDate: string | null,
  endDate: string | null
): Promise<WorkOrderDetailsType> => {
  if (startDate && endDate) {
    startDate = moment(startDate).format('YYYYMMDD')
    endDate = moment(endDate).format('YYYYMMDD')
    return await webApi.get<WorkOrderDetailsType>(apiClient, {
      url: `WorkOrders/GetAllClosedWorkOrders/${siteId}/${currentPage}/${itemsPerPage}/${startDate}/${endDate}`,
      params: []
    })
  }
  return await webApi.get<WorkOrderDetailsType>(apiClient, {
    url: `WorkOrders/GetAllClosedWorkOrders/${siteId}/${currentPage}/${itemsPerPage}`,
    params: []
  })
}

const getWorkOrderByAssetTag = async (
  apiClient: ApiClient,
  workOrderNumber: string
): Promise<WODetails> => {
  return await webApi.get<WODetails>(apiClient, {
    url: `WorkOrders/GetOpenWorkOrderDetailsAsync/${workOrderNumber}`,
    params: []
  })
}

const getWorkOrderHistory = async (
  apiClient: ApiClient,
  workOrderNumber: string
): Promise<WorkOrderHistory> => {
  return await webApi.get<WorkOrderHistory>(apiClient, {
    url: `WorkOrders/GetWorkOrderHistoryDetailsAsync/${workOrderNumber}`,
    params: []
  })
}

const searchWorkOrders = async (
  apiClient: ApiClient,
  currentPage: number,
  itemsPerPage: number,
  site: string,
  filtersList: Filters
): Promise<WorkOrderDetailsWithStatusType> => {
  const queryParams: string[] = []
  filtersList['workOrderNumber'] = filtersList['workOrderNumber'].replace(
    /\D/g,
    ''
  )

  for (const [key, value] of Object.entries(filtersList)) {
    if (value) {
      queryParams.push(
        encodeURIComponent(key) +
          '=' +
          encodeURIComponent(
            moment.isMoment(value) ? value.format('YYYYMMDD') : value
          )
      )
    }
  }
  if (queryParams.length === 0) {
    queryParams.push(
      encodeURIComponent('startDate') +
        '=' +
        encodeURIComponent(moment().subtract(3, 'M').format('YYYYMMDD'))
    )
    queryParams.push(
      encodeURIComponent('endDate') +
        '=' +
        encodeURIComponent(moment().format('YYYYMMDD'))
    )
  }
  const resp = await webApi.getWithStatus(apiClient, {
    url: `WorkOrders/GetAllWorkOrders/${site}/${currentPage}/${itemsPerPage}/?${queryParams.join('&')}`,
    params: []
  })

  const responseStatus = resp?.status
  const response = await resp.json()
  return {
    responseStatus,
    response
  }
}

const escalateWorkOrder = async (
  apiClient: ApiClient,
  payload: any
): Promise<any> => {
  return await webApi.put(apiClient, {
    url: `WorkOrders/EscalateWorkOrder`,
    params: [],
    body: payload
  })
}

const recallWorkOrder = async (
  apiClient: ApiClient,
  payload: any
): Promise<any> => {
  var response = await webApi.put(apiClient, {
    url: `WorkOrders/RecallWorkOrder`,
    params: [],
    body: payload
  })
  return response
}

const deleteWorkOrder = async (
  apiClient: ApiClient,
  payload: any
): Promise<any> => {
  return await webApi.put(apiClient, {
    url: `WorkOrders/CancelWorkOrder`,
    params: [],
    body: payload
  })
}

const createWorkOrder = async (
  apiClient: ApiClient,
  formData: FormData,
): Promise<any> => {
  return await webApi.post(apiClient, {
    url: `WorkOrders`,
    params: [],
    body: formData,
    contentType: null
  })
}

const getLinkedWorkOrder = async (
  apiClient: ApiClient,
  workOrderNumber: string
): Promise<LinkedWorkOrderDetails[]> => {
  return await webApi.get<LinkedWorkOrderDetails[]>(apiClient, {
    url: `WorkOrders/GetLinkedWoDetailsAsync/${workOrderNumber}`,
    params: []
  })
}

const checkAssetAction = async (
  apiClient: ApiClient,
  assetId: string,
  pageNumber: number,
  pageSize: number,
  siteId: string
): Promise<WODetailsWithAssetNumberAndStatus> => {
  const resp = await webApi.getWithStatus(apiClient, {
    url: `WorkOrders/OpenWorkOrdersWithAssetNumber/${siteId}/${assetId}/${pageNumber}/${pageSize}`,
    params: []
  })
  const responseStatus = resp?.status
  const response = await resp.json()
  return {
    responseStatus,
    response
  }
}

const getNotificationDataAsset = async (
  apiClient: ApiClient,
  assetNumber: string,
  siteId: string,
  currentPageNUmber?: number,
  itemsPerPage?: number
): Promise<WorkOrderDetailsWithStatusType> => {
  const queryParams =
    currentPageNUmber && itemsPerPage
      ? `&pageNumber=${currentPageNUmber}&pageSize=${itemsPerPage}`
      : ''
  const resp = await webApi.getWithStatus(apiClient, {
    url: `WorkOrders/RecallsAndDuplicates/?siteNum=${siteId}&assetNumber=${assetNumber}${queryParams}`,
    params: []
  })

  const responseStatus = resp?.status
  const response = await resp.json()
  return {
    responseStatus,
    response
  }
}

const getDuplicateWOwithoutAssetTag = async (
  apiClient: ApiClient,
  assetPayload: AssetPayloadType | undefined,
  assetDetails: AssetDetails | undefined
): Promise<WorkOrderDetailsType> => {
  let parameters = assetDetails
    ? `siteNum=${assetDetails.siteName}&assetNumber=${assetDetails!.assetNumber}`
    : `siteNum=${assetPayload!.siteId}&category=${assetPayload!.category}&subCategory=${assetPayload!.subcategory}&location=${assetPayload!.location}`

  return await webApi.get(apiClient, {
    url: `WorkOrders/RecallsAndDuplicates/?${parameters}`,
    params: []
  })
}

const workOrderDetailsApi = {
  getWorkOrdersDashboardDetails,
  getOpenWoWithoutAssetTag,
  getWorkOrders,
  getClosedWorkOrders,
  getWorkOrderByAssetTag,
  getWorkOrderHistory,
  searchWorkOrders,
  escalateWorkOrder,
  recallWorkOrder,
  deleteWorkOrder,
  createWorkOrder,
  getLinkedWorkOrder,
  checkAssetAction,
  getNotificationDataAsset,
  getDuplicateWOwithoutAssetTag
}

export default workOrderDetailsApi
