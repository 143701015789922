import { ISiteDetails } from '../types/SiteDetails/ISiteDetails'
import { ApiClient } from './ApiClient'
import webApi from './webApi'

export const getSiteDetails = async (
  apiClient: ApiClient,
  assignedSites?: any
): Promise<ISiteDetails[]> => {
  const response = await webApi.get<ISiteDetails[]>(apiClient, {
    url: `SiteDetails?assignedSites=${assignedSites}`,
    params: []
  })

  return response
}

const storesApi = {
  getSiteDetails
}

export default storesApi
