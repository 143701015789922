import { ApiClient } from './ApiClient'
import webApi from './webApi'

const uploadImages = async (
  apiClient: ApiClient,
  payload: any,
  woNumber: string,
  siteName: string,
  retries = 1
): Promise<any> => {
  return await webApi
    .post(apiClient, {
      url: `images/${siteName}/${woNumber}`,
      params: [],
      body: payload,
      contentType: null
    })
    .catch(err => {
      if (retries <= 5)
        uploadImages(apiClient, payload, woNumber, siteName, retries + 1)
      else console.log('error on submitting image:' + err)
    })
}

const imagesApi = {
  uploadImages
}

export default imagesApi
