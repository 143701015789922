import { FilledButton, Container } from '@jsluna/react'
import { useNavigate } from 'react-router-dom'
import ErrorIcon from '../assets/ErrorIcon';

const ScannerTimeOutPage = () => {
    const navigate = useNavigate();

    const redirect = () => {
        navigate(-1);
    }

    return (
        <div>
            <Container size="xs">
                <div className="scanTO-container">
                    <ErrorIcon />
                    <div className="ln-u-text-align-left margin-top-small scanTO-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
                        <h5>We didn&apos;t manage to scan a barcode in time</h5>
                    </div>
                    <FilledButton className="scanTO-rescan-button" onClick={() => { redirect() }}>Rescan</FilledButton>
                </div>
            </Container>
        </div>
    )
}

export default ScannerTimeOutPage;