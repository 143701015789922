import { SearchField } from '@jsluna/form'
import { Search } from '@jsluna/icons'
import React from 'react';
import { SearchInputText } from '../../types/SearchWorkOrdersTypes';

const SearchWorkOrder: React.FC<SearchInputText> = ({id, name, label, placeholder, value, handleChange}) => {
    return (
        <>
            <SearchField
                id={id}
                name={name}
                label={label}
                hideLabel
                placeholder={placeholder}
                hasIcon
                value={value}
                onChange={(event) => handleChange(event)}
                icon={<Search />}
            />
        </>
    )
}

export default SearchWorkOrder;