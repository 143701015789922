import React from 'react';
import PropTypes from 'prop-types';

const CameraIcon = ({ width, height, viewBox }) =>
    (
        <svg
            width={width}
            height={height}
            viewBox={viewBox}
            role="img"
        //xmlns="http://www.w3.org/2000/svg"
        >
            <defs />
            <g id="🚀-FM-Assist---User-stories" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="R6---D---Reported-problem-detail-(edit)" transform="translate(-451.000000, -688.000000)">
                    <g id="Add-a-comment" transform="translate(392.000000, 500.000000)">
                        <g id="Group-2" transform="translate(59.000000, 187.000000)">
                            <g id="button-/-text-/-outlined-/-orange-/-default">
                                <text id="text" fontFamily="MaryAnn-ExtraBold, Mary Ann" fontSize="18" fontWeight="600" line-spacing="24"></text>
                            </g>
                            <g id="Group" transform="translate(12.000000, 15.000000)" fill="#f06c00" fillRule="nonzero">
                                <path d="M20.6972477,4.34782717 L19.5137615,4.34782717 L18,1.68303072 C17.409642,0.640900401 16.318331,-0.000770131141 15.1376147,6.93700317e-07 L8.86238532,6.93700317e-07 C7.68166898,-0.000770131141 6.59035805,0.640900401 6,1.68303072 L4.48623853,4.34782717 L3.30275229,4.34782717 C1.47869257,4.34782717 0,5.85486297 0,7.71388585 L0,16.6339413 C0,18.4929642 1.47869257,20.0000007 3.30275229,20.0000007 L20.6972477,20.0000007 C22.5213074,20.0000007 24,18.4929642 24,16.6339413 L24,7.71388585 C24,5.85486297 22.5213074,4.34782717 20.6972477,4.34782717 Z M21.7981651,16.6339413 C21.7981651,17.2536156 21.3052676,17.7559609 20.6972477,17.7559609 L3.30275229,17.7559609 C2.69473239,17.7559609 2.20183486,17.2536156 2.20183486,16.6339413 L2.20183486,7.71388585 C2.20183486,7.09421156 2.69473239,6.59186629 3.30275229,6.59186629 L5.11926606,6.59186629 C5.51611124,6.59548687 5.88415416,6.38114545 6.08256881,6.03085651 L7.9266055,2.80505028 C8.12502015,2.45476134 8.49306307,2.24041992 8.88990826,2.24399535 L15.1376147,2.24399535 C15.5344599,2.24041992 15.9025028,2.45476134 16.1009174,2.80505028 L17.9449541,6.03085651 C18.1433688,6.38114545 18.5114117,6.59548687 18.9082569,6.59186629 L20.7247706,6.59186629 C21.3327906,6.59186629 21.8256881,7.09421156 21.8256881,7.71388585 L21.7981651,16.6339413 Z" id="Shape1"></path>
                                <path d="M12,5 C8.6862915,5 6,7.6862915 6,11 C6,14.3137085 8.6862915,17 12,17 C15.3137085,17 18,14.3137085 18,11 C18,9.40870106 17.367859,7.88257758 16.2426407,6.75735931 C15.1174224,5.63214104 13.5912989,5 12,5 Z M12,14.7674419 C9.91929931,14.7674419 8.23255814,13.0807007 8.23255814,11 C8.23255814,8.91929931 9.91929931,7.23255814 12,7.23255814 C14.0807007,7.23255814 15.7674419,8.91929931 15.7674419,11 C15.7674419,11.9991877 15.3705161,12.9574513 14.6639837,13.6639837 C13.9574513,14.3705161 12.9991877,14.7674419 12,14.7674419 L12,14.7674419 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );

CameraIcon.defaultProps = {
    width: 48,
    height: 48,
    viewBox: '-2 0 50 50',
};

CameraIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default CameraIcon;
