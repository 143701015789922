import { useEffect, useState } from 'react'
import { Container } from '@jsluna/react'
import Scanner from '../../components/SearchWorkOrder/Scanner'
import BackLink from '../../components/BackLink'
import Loading from '../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'

var timer = null

const ScannerPage = () => {
    const navigate = useNavigate()
    let greenAudio = null;
    const [showCurrentComp, setShowCurrentComp] = useState(false);
    const [results, setResults] = useState([]);
    const [scanning, setScanning] = useState(true);

    useEffect(() => {
        setShowCurrentComp(true);
        timer = setTimeout(() => redirectToScanTimeOut(), 30000)
        return () => {
            setShowCurrentComp(false)
            clearTimeout(timer)
        }
    })

    const _onDetected = (result) => {
        greenAudio.play();
        setScanning(!scanning)
        setResults(results.concat([result]))
        
        if (result?.codeResult?.code.length !== 0) {
            navigate('/find_workorder', { state: {asset: result?.codeResult?.code } })
        }
    }

    const redirectToScanTimeOut = () => {
        navigate('/scantimeout')
    }

    return (
        <>
        { showCurrentComp ? 
            (
                <div>
                    <Container size="xs">
                        <BackLink
                            onClick={() => {
                                navigate('/find_workorder')
                            }}
                        />
                        <div className="overflow">
                            <ul className="results">
                                <audio ref={(green) => {greenAudio = green}}>
                                    <source src="https://s3.amazonaws.com/freecodecamp/simonSound1.mp3" type="audio/mpeg" >
                                    </source>
                                </audio>
                            </ul>
                            {scanning ? (
                                <Scanner onDetected={_onDetected} />
                            ) : null}
                        </div>
                    </Container>
                </div>
            )
            :
            (   <Loading /> )
        }
        </>
    )
}

export default ScannerPage;