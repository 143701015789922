import { FilledButton, Container, LinkButton } from '@jsluna/react'
import { useLocation } from 'react-router-dom';
import ErrorIcon from '../assets/ErrorIcon';

const AssestWOInformation = () => {
    const location = useLocation();
        return (
            <div>
                <Container size="xs">
                    <div className="awoinfo-container">
                        <ErrorIcon />
                        <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
                            <h5>There doesn&apos;t seem to be any information for that piece of equipment</h5>
                            <p>We&apos;ve checked the equipment tag <strong>{location?.state?.assetID}</strong> and not found any information. If this is the correct equipment tag report the piece of equipment as not having an equipment tag.</p>
                        </div>
                        <FilledButton className="awoinfo-missing-button">Report missing info</FilledButton>
                        <p><LinkButton className="withOAtag">Report without asset tag</LinkButton></p>
                    </div>
                </Container>
            </div>
        )
}

export default AssestWOInformation;
