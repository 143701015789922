import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import { PublicClientApplication } from '@azure/msal-browser'

import { MsalProvider } from '@azure/msal-react'

import { AppProvider } from './context/AppContext'
import { config } from './config'

import App from './App'
import { NotificationsType } from './types/Notifications/NotificationsType'
import { WODetailsWithAssetNumber, WorkOrdersDetailsType } from './types/WorkOrdersDetails/WorkOrderDetailsType'
import { WorkOrderCreation } from './types/WorkOrdersDetails/Createworkorder'

const msalConfig = {
  auth: {
    clientId: config?.clientId ?? '73315a8d-f440-44e9-bca9-5571a35239cb',
    authority: config?.clientAuthority ?? 'https://login.microsoftonline.com/b2eb8529-51f8-4d43-92bf-85201eea6023',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

var notifications = {} as NotificationsType
var workOrders = {} as WorkOrdersDetailsType
var wo = {} as WorkOrderCreation
var storeSearchAssetData = {} as WODetailsWithAssetNumber | boolean
var filters = {
  workOrderNumber: "",
  category: "",
  location: "",
  startDate: "",
  endDate: "",
  assetNumber: ""
}

root.render(
  <Router>
    <MsalProvider instance={msalInstance}>
      <AppProvider
        apiScope={config?.scope ?? 'api://3b080c8e-9e07-4a35-a5cd-ab1c098d9f3b/.default'}
        apiBaseUrl={config?.apiBaseUrl ?? ''}
        store={null}
        user={undefined}
        notifications={notifications}
        assets={undefined}
        assetPayload={undefined}
        workOrders={workOrders}
        createWO={wo}
        tutorialServiceScope={config.tutorialServiceScope ?? ''}
        asset=''
        storeCheckAssetData={storeSearchAssetData}
        filters={filters}
      >
        <App />
      </AppProvider>
    </MsalProvider>
  </Router>
)
