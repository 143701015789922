import { AutocompleteField, FilledButton, TextButton } from '@jsluna/react'
import { Link } from 'react-router-dom'
import { useContext, useState } from 'react'
import { AppContext, useApiClient } from '../../context/AppContext'
import assetsApi from '../../api/assetsApi'

type Props = {
  currentStep: number
  currentStepChange?: (newType: number) => void
}

const WOLogging1Step = ({ currentStep, currentStepChange }: Props) => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()

  const [assetIsWorking, setAssetWorkingStatus] = useState('')
  const [assetProblemType, setAssetProblemType] = useState('')
  const [problemParentCategoryName, setProblemParentCategoryName] = useState('')
  const [clickedSubmit, setClickedSubmit] = useState(false)

  const ASSET_STATUS = [
    { label: 'Working', value: 'Working' },
    { label: 'Not working', value: 'Not Working' }
  ]

  let PROBLEM_TYPES = []

  if (
    context?.createWO?.assetDetails?.problemTypes &&
    context?.createWO?.assetDetails?.problemTypes.length > 0
  ) {
    PROBLEM_TYPES = context?.createWO.assetDetails.problemTypes.map(
      (woProblemType: any) => {
        let inputData = woProblemType.problemName.toLowerCase()
        return {
          label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
          value: woProblemType.problemName,
          problemParentCategory: woProblemType.problemParentCategoryName
        }
      }
    )
  }
  if (
    context?.createWO?.problemTypes &&
    context.createWO.problemTypes.length > 0
  ) {
    PROBLEM_TYPES = context.createWO.problemTypes.map((woProblemType: any) => {
      let inputData = woProblemType.problemName.toLowerCase()
      return {
        label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
        value: woProblemType.problemName,
        problemParentCategory: woProblemType.problemParentCategoryName
      }
    })
  }

  const handleNext = async () => {
    setClickedSubmit(true)
    if (
      (context?.createWO?.assetDetails?.isEquipmentWorking &&
        assetIsWorking !== '' &&
        assetProblemType !== '') ||
      (context &&
        (context?.createWO?.assetDetails === null ||
          context?.createWO?.assetDetails === undefined ||
          context?.createWO?.assetDetails?.assetNumber.startsWith('VR')) &&
        assetProblemType !== '')
    ) {
      let storeType = ''
      if (context!.assetPayload!.storeType === 'SUPERMARKET|PFS') {
        storeType =
          context!.createWO!.assetDetails!.siteName.indexOf('P') > -1
            ? 'PFS'
            : 'SUPERMARKET'
      } else {
        storeType = context!.assetPayload!.storeType
      }

      const workOrderDescription = {
        problemType: assetProblemType,
        location: context.assetPayload?.location,
        assetCategory: problemParentCategoryName,
        siteType:
          storeType === 'PFS' && context!.assetPayload!.siteId.indexOf('|') > -1
            ? context!.assetPayload!.siteId.split('|')[1]
            : context!.assetPayload!.siteId.substring(0, 5),
        assetSubCategory: context.assetPayload?.subcategory,
        operationalStatus: assetIsWorking
      }
      context.createWO!.step1 = workOrderDescription

      const woScripts = await assetsApi
        .getWorkOrderScripts(apiClient, workOrderDescription)
        .then(data => {
          return data
        })

      if (context && context.createWO) {
        context.createWO.woScripts = woScripts
        context.createWO.workOrderLog = workOrderDescription
        currentStepChange?.(currentStep + 1)
      }
    }
  }

  return (
    <div>
      {context?.createWO?.assetDetails?.isEquipmentWorking ||
      context?.createWO?.assetRequirements?.isEquipmentWorking ? (
        <AutocompleteField
          name={'isAssetWorking'}
          label="Is the equipment working?"
          placeholder="Choose a working status"
          options={ASSET_STATUS}
          onSelect={(val: { label: string; value: string }) =>
            setAssetWorkingStatus(val?.value ? val.value : '')
          }
          error={
            clickedSubmit && assetIsWorking === ''
              ? 'Please select if the equipment is working or not, so we know how serious it is.'
              : ''
          }
          required
        />
      ) : null}

      <AutocompleteField
        name={'PROBLEM_TYPES'}
        label="Type of problem"
        placeholder="Choose a problem type"
        options={PROBLEM_TYPES}
        onSelect={(val: {
          label: string
          value: string
          problemParentCategory: string
        }) => {
          setAssetProblemType(val?.value ? val.value : '')
          setProblemParentCategoryName(
            val?.problemParentCategory ? val.problemParentCategory : ''
          )
        }}
        error={
          clickedSubmit && assetProblemType === ''
            ? 'Please select the type of problem, so that we can start to understand the problem.'
            : ''
        }
        required
      />

      <FilledButton
        fullWidth
        onClick={() => handleNext()}
        className="ln-u-display-inline-flex ln-u-justify-content-center ln-u-align-items-center"
      >
        Next
      </FilledButton>

      <Link to={'/fmassist_landing'}>
        <TextButton className="rp_cancel_btn" fullWidth>
          Cancel
        </TextButton>
      </Link>
    </div>
  )
}

export default WOLogging1Step
