import { useApiClient } from '../context/AppContext'
import useResourceLoader from './useResourceLoader'
import useCallbackRef from './useCallbackRef'
import storesApi from '../api/storesApi'
import { ISiteDetails } from '../types/SiteDetails/ISiteDetails'
import { ILoadSiteDetails } from '../types/SiteDetails/ILoadSiteDetails'
import { FetchSiteDetails } from '../types/SiteDetails/FetchSiteDetails'
import IResourceLoader from '../types/IResourceLoader'

const useSiteDetails = (): [
  IResourceLoader<ISiteDetails[]>,
  ILoadSiteDetails
] => {
  const apiClient = useApiClient()
  const response = useResourceLoader<ISiteDetails[]>(
    'Unable to load site details'
  )

  const loader = useCallbackRef<FetchSiteDetails>((assignedSites?: any) =>
    response.load(
      async () => await storesApi.getSiteDetails(apiClient, assignedSites)
    )
  )

  return [response, loader]
}

export default useSiteDetails
