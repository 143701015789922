
const error = (...params: any[]) =>
  console && console.error(...params);

const warning = (...params: any[]) =>
  console && console.warn(...params);

const info = (...params: any[]) =>
  console && console.log(...params);

const logger = {
  error,
  info,
  warning,
}

export default logger;
