import { useContext } from 'react'
import { Container, Card, Display1, Body1 } from '@jsluna/react'
import BackLink from '../components/BackLink'
import { AppContext } from '../context/AppContext'

const NotificationDetailPage = () => {
  const context = useContext(AppContext)
  var notification = context?.notifications?.selectedNotification

  return (
    <div>
        <Container size="xs">
          <BackLink linkTo={`/messages`}
          />
           <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft">
           <div className="notify-wrap">
          <Display1> {notification?.summary} </Display1>
          <div className="ln-u-padding-top*2">
            <Body1 className="truncate">
              {notification?.description}
            </Body1>
          </div>
        </div>
          </Card>
        </Container>
    </div>
  )
}

export default NotificationDetailPage
