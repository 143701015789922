import { useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import {
  Card,
  Form,
  OutlinedButton,
  RadioButtonField,
  AutocompleteField,
  FilledButton
} from '@jsluna/react'
import { AppContext, useApiClient } from '../context/AppContext'
import useAssetCategories from '../hooks/useAssetCategories'
import useAssetLocations from '../hooks/useAssetLocations'
import assetsApi from '../api/assetsApi'
import { AssetDetailsType } from '../types/AssetCategories/AssetDetailsType'

const NoAssetTagForm = () => {
  const context = useContext(AppContext)
  const { pathname } = useLocation()
  const apiClient = useApiClient()
  const navigate = useNavigate()

  context!.createWO!.problemTypes = null
  context!.createWO!.assetDetails = undefined

  var isCheckEquipmentPage = window.location.pathname === '/check_equipment'

  let assetCategoriesOptions = []
  const [assetSubCategoriesOptions, setAssetSubCategoriesOptions] = useState<
    { label: string; value: string } | { label: string; value: string }[]
  >()
  let assetLocationsOptions = []

  const assignCurrentStoreType = () => {
    if (
      context?.user?.siteType === undefined ||
      context?.user?.siteType === null
    ) {
      return ''
    }
    if (context.user.siteType.includes('|')) {
      return context.user.siteType.split('|')[0]
    }
    return context?.user?.siteType
  }

  const [currentStoreType, setCurrentStoreType] = useState(
    assignCurrentStoreType()
  )
  const [assetCategory, setAssetCategory] = useState('')
  const [assetSubCategory, setAssetSubCategory] = useState('')
  const [assetLocation, setAssetLocation] = useState('')
  const [errorAssetCategory, setErrorAssetCategory] = useState(false)
  const [errorAssetSubCategory, setErrorAssetSubCategory] = useState(false)
  const [errorAssetLocation, setErrorAssetLocation] = useState(false)
  const [categoryDescription, setCategoryDescription] = useState('')
  const [subCategoryDescription, setSubCategoryDescription] = useState('')
  const [disableNextButton, setDisableNextButton] = useState(false)

  const [assetCategoriesResponse, acLoader] =
    useAssetCategories(currentStoreType)

  useEffect(() => {
    acLoader.current()
  }, [context, acLoader, currentStoreType])

  if (assetCategoriesResponse?.status === 'success') {
    for (let i = 0; i < assetCategoriesResponse?.data!.length; i += 1) {
      let inputData = assetCategoriesResponse?.data![i].toLowerCase()
      assetCategoriesOptions.push({
        label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
        value: assetCategoriesResponse?.data![i]
      })
    }
    // }
    // if error -> send to error page
  }

  const [assetLocationsResponse, locLoader] =
    useAssetLocations(currentStoreType)
  useEffect(() => {
    locLoader.current()
  }, [context, locLoader, currentStoreType])
  if (assetLocationsResponse) {
    if (assetLocationsResponse.status === 'success') {
      for (let i = 0; i < assetLocationsResponse?.data!.length; i += 1) {
        let inputData = assetLocationsResponse?.data![i].toLowerCase()
        assetLocationsOptions.push({
          label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
          value: assetLocationsResponse?.data![i]
        })
      }
    }
    // if error -> send to error page
  }

  const handleAssetCategoryChange = async (category: string) => {
    setAssetCategory(category ? category : '')
    setErrorAssetCategory(false)
    const assetSubCategories: { label: string; value: string }[] = []

    let assetSubCategoriesResponse = await assetsApi
      .getAssetSubCategories(apiClient, category, currentStoreType)
      .then(value => {
        return value
      })

    await assetsApi.getAssetDescription(apiClient, category).then(value => {
      setCategoryDescription(value[0])
      return value
    })

    for (let i = 0; i < assetSubCategoriesResponse!.length; i += 1) {
      let inputData = assetSubCategoriesResponse[i].toLowerCase()
      assetSubCategories.push({
        label: inputData.charAt(0).toUpperCase() + inputData.slice(1),
        value: assetSubCategoriesResponse[i]
      })
    }
    setAssetSubCategoriesOptions(assetSubCategories)
  }

  const handleAssetSubCategoryChange = async (subcategory: string) => {
    setAssetSubCategory(subcategory ? subcategory : '')
    setErrorAssetSubCategory(false)

    await assetsApi.getAssetDescription(apiClient, subcategory).then(value => {
      setSubCategoryDescription(value[0])
      return value
    })

    let assetRequirements = await assetsApi
      .getAssetRequirements(apiClient, assetCategory, subcategory)
      .then(value => {
        return value
      })
    setDisableNextButton(assetRequirements.isBarcodeRequired)
    if (context) {
      context!.createWO!.assetRequirements = assetRequirements
    }
  }

  function validation() {
    if (assetCategory === '' || assetCategory === undefined) {
      setErrorAssetCategory(true)
    }
    if (assetSubCategory === '' || assetSubCategory === undefined) {
      setErrorAssetSubCategory(true)
    }
    if (assetLocation === '' || assetLocation === undefined) {
      setErrorAssetLocation(true)
    }
    if (
      assetCategory === '' ||
      assetSubCategory === '' ||
      assetLocation === ''
    ) {
      return false
    }

    const payload = {
      category: assetCategory,
      subcategory: assetSubCategory,
      location: assetLocation,
      storeType: currentStoreType,
      siteId:
        currentStoreType === 'PFS'
          ? context!.store!.storeCode.split('|')[1]
          : context!.store!.storeCode.substring(0, 5)
    }
    context!.assetPayload = payload

    return true
  }

  const searchAsset = async () => {
    const pass = validation()

    if (pass) {
      var foundAssetResponse: AssetDetailsType[]
      foundAssetResponse = await assetsApi
        .searchAsset(apiClient, context!.assetPayload)
        .then(value => {
          return value
        })

      if (context) {
        context.assets = foundAssetResponse
      }

      navigate('/search_assets')
    }
  }

  const handleNext = async () => {
    if (isCheckEquipmentPage) {
      const pass = validation()
      if (pass) navigate('status')
    }

    const pass = validation()
    if (pass) {
      let problemTypes = await assetsApi
        .getAssetProblemTypes(apiClient, context!.assetPayload)
        .then(value => {
          return value
        })

      if (context) {
        context.createWO!.problemTypes = problemTypes
      }
      if (pathname.indexOf('report_problem') > -1) {
        navigate('/report_problem/whats_happening')
      }
    }
  }

  return (
    <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft card-space">
      <h5 className="asset-title"> No asset tag?</h5>
      <Form
        onSubmit={(e: Event) => {
          e.preventDefault()
        }}
      >
        {context?.user?.site && context?.user.site.includes('|') ? (
          <RadioButtonField
            label="Is it in the PFS or store?"
            name="reason-trading"
            options={[
              { value: 'PFS', label: 'PFS' },
              { value: 'Store', label: 'Store', defaultChecked: true }
            ]}
            onChange={(storeType: { target: { value: string } }) => {
              const siteType =
                storeType.target.value === 'PFS'
                  ? 'PFS'
                  : assignCurrentStoreType()
              setCurrentStoreType(siteType)
            }}
            fullWidth
            outlined
          />
        ) : (
          ''
        )}

        <AutocompleteField
          name="What sort of asset is it?"
          label="What sort of asset is it?"
          placeholder="Choose an asset"
          options={assetCategoriesOptions}
          onChange={(category: { label: string; value: string }) => {
            setAssetCategory(category?.value)
            setErrorAssetCategory(false)
          }}
          onSelect={(category: { label: string; value: string }) => {
            handleAssetCategoryChange(category?.value)
          }}
          //value={assetCategory}
          error={
            errorAssetCategory
              ? 'Please select the sort of asset, having the problem, so we can collect relevant information.'
              : false
          }
          required
        />
        <div className="ln-u-padding-bottom*2">{categoryDescription}</div>

        <AutocompleteField
          name="What is the asset type?"
          label="What is the asset type?"
          placeholder="Choose asset type"
          options={assetSubCategoriesOptions}
          onChange={(subCategory: { label: string; value: string }) => {
            setAssetSubCategory(subCategory?.value)
            setErrorAssetSubCategory(false)
          }}
          onSelect={(subCategory: { label: string; value: string }) => {
            handleAssetSubCategoryChange(subCategory?.value)
          }}
          error={
            errorAssetSubCategory
              ? 'Please select the asset type, so we can help you better.'
              : false
          }
          required
        />
        <div className="ln-u-padding-bottom*2">{subCategoryDescription}</div>

        <AutocompleteField
          name="Where is the asset located?"
          label="Where is the asset located?"
          placeholder="Choose asset location"
          options={assetLocationsOptions}
          onChange={(location: { label: string; value: string }) => {
            setAssetLocation(location?.value)
            setErrorAssetLocation(false)
          }}
          onSelect={(location: { label: string; value: string }) => {
            setAssetLocation(location?.value ? location.value : '')
            setErrorAssetLocation(false)
          }}
          value={assetLocation}
          error={
            errorAssetLocation
              ? 'Please select a location, so we can collect relevant information.'
              : false
          }
          required
        />

        {disableNextButton ? (
          <div className="ln-u-padding-bottom*2">
            This type of asset needs an asset tag to be identified to report a
            problem accurately.
          </div>
        ) : null}

        {isCheckEquipmentPage ? (
          <OutlinedButton
            type="submit"
            fullWidth
            className="ln-u-margin-bottom*2"
            onClick={() => {
              handleNext()
            }}
          >
            Next
          </OutlinedButton>
        ) : (
          <FilledButton
            type="submit"
            fullWidth
            className="ln-u-margin-bottom*2"
            onClick={() => searchAsset()}
          >
            Search for Asset
          </FilledButton>
        )}

        {!disableNextButton && !isCheckEquipmentPage ? (
          <OutlinedButton
            type="submit"
            fullWidth
            className="ln-u-margin-bottom*2"
            onClick={() => {
              handleNext()
            }}
          >
            Next
          </OutlinedButton>
        ) : null}
      </Form>
    </Card>
  )
}

export default NoAssetTagForm
