import { Link } from 'react-router-dom'
import { Container } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import { WorkOrderDetailsType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'

const DuplicateWONotification: React.FC<{
  duplicateWorkOrders: WorkOrderDetailsType
}> = ({ duplicateWorkOrders }) => {
    
const getPathname = () => {
    if (duplicateWorkOrders.totalCountOfWorkOrders === 1) {
        return `/problem_overview/${duplicateWorkOrders.allWorkOrdersViewModel[0].workOrderNumber}`
    }
    if (duplicateWorkOrders.totalCountOfWorkOrders > 1) {
        return `/check_equipment/status`
      }
}

  const getMessage = () => {
    if (duplicateWorkOrders.totalCountOfWorkOrders === 1) {
      return (
        <span>
          Problem identified for{' '}
          {duplicateWorkOrders.allWorkOrdersViewModel[0].subcategory}: {' '}
          <b>
            WO {duplicateWorkOrders.allWorkOrdersViewModel[0].workOrderNumber}.
          </b>
        </span>
      )
    }
    if (duplicateWorkOrders.totalCountOfWorkOrders > 1) {
      return `${duplicateWorkOrders.totalCountOfWorkOrders} others problems exist for this asset. `
    }
  }

  return (
    <div className="alert" role="alert">
      <Container size="xs">
        <Link
          to={
            {
              pathname: getPathname()
            }
          }
        >
          <div className="notification-container">
            <div>
              {getMessage()}
              <b>Please review before continuing.</b>
            </div>
            <div>
              <ArrowRight />
            </div>
          </div>
        </Link>
      </Container>
    </div>
  )
}

export default DuplicateWONotification
