import React from 'react';
import PropTypes from 'prop-types';

const LinkedWOIcon = ({ width, height, viewBox }) =>
    (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.45054 18.4345C6.52589 18.4345 5.65864 18.0798 5.00871 17.4373C3.66376 16.1077 3.66376 13.941 5.00871 12.6114L7.98565 9.67249C9.33265 8.34289 11.5223 8.34289 12.8673 9.67249C13.267 10.0677 13.5643 10.5602 13.7284 11.0953C13.8432 11.4703 13.6279 11.8655 13.2486 11.979C12.8693 12.0905 12.4695 11.8797 12.3547 11.5047C12.2583 11.1886 12.0902 10.9089 11.8544 10.6758C11.4751 10.3008 10.9687 10.0961 10.4275 10.0961C9.88622 10.0961 9.37981 10.3028 9.00052 10.6778L6.02563 13.6167C5.23834 14.393 5.23834 15.6597 6.02563 16.436C6.40492 16.811 6.91133 17.0177 7.45259 17.0177C7.99385 17.0177 8.50026 16.811 8.87955 16.436L10.3537 14.9787C10.6346 14.7011 11.0877 14.7011 11.3685 14.9787C11.6494 15.2564 11.6494 15.7043 11.3685 15.982L9.89442 17.4393C9.24244 18.0818 8.3752 18.4345 7.45054 18.4345Z" fill="#404040"/>
        <path d="M13.577 14.762C12.6933 14.762 11.8097 14.4296 11.1351 13.7648C10.7354 13.3695 10.4381 12.877 10.2741 12.3419C10.1592 11.967 10.3745 11.5717 10.7538 11.4582C11.1331 11.3447 11.5329 11.5575 11.6477 11.9325C11.742 12.2446 11.9163 12.5304 12.148 12.7595C12.5273 13.1344 13.0337 13.3412 13.5749 13.3412C14.1162 13.3412 14.6226 13.1344 15.0019 12.7615L17.9768 9.82056C18.762 9.04428 18.762 7.77751 17.9768 7.00124C17.1915 6.22496 15.9122 6.22294 15.1249 7.00124L13.6508 8.45853C13.3699 8.7362 12.9168 8.7362 12.6359 8.45853C12.355 8.18085 12.355 7.73292 12.6359 7.45525L14.11 5.99796C15.457 4.66633 17.6467 4.66836 18.9916 5.99796C20.3366 7.32959 20.3366 9.49424 18.9916 10.8238L16.0168 13.7627C15.3443 14.4296 14.4606 14.762 13.577 14.762Z" fill="#404040"/>
        </svg>

    );

    

LinkedWOIcon.defaultProps = {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35'
};

LinkedWOIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string
};

export default LinkedWOIcon;
