import {
    ProgressSpinner
  } from '@jsluna/react'

const LoadingMessage = () => {
  return (
    <div className="awoinfo-container">
      <div className="margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
        <h4 className="ln-u-text-align-center">
          Your problem is being reported ...
          <ProgressSpinner size="small" className="ln-u-push-right-sm" />
        </h4>
      </div>
    </div>
  )
}

export default LoadingMessage
