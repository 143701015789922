import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const WorkRequests: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  const trackGA = () => {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Automated WO from Overview Dashboard',
        action: 'Automated WO from Overview Dashboard',
        label: 'Automated WO from Overview Dashboard'
      })
    }
  }

  return (
    <div className="ClosedProb">
      <Link to="/problem_overview/automated_work_order">
        <div
          className="ln-c-card ln-c-card--soft card-tiles open-tile"
            onClick={() => trackGA()}
        >
          <h5>
            {workOrdersDashboard?.totalCountOfAutomatedWorkOrders
              ? workOrdersDashboard!.totalCountOfAutomatedWorkOrders
              : 0}{' '}
            automated work order
            {workOrdersDashboard &&
            workOrdersDashboard.totalCountOfAutomatedWorkOrders > 1
              ? 's'
              : ''}
          </h5>
        </div>
      </Link>
    </div>
  )
}

export default WorkRequests
