import DropdownQuestion from './QuestionComponents/DropdownQuestion'
import RadioQuestion from './QuestionComponents/RadioQuestion'
import TextInputQuestion from './QuestionComponents/TextInputQuestion'

const QuestionElement: React.FC<{ questionDetails: any, verificationTriggered : number, passExtraVerification:(verified: boolean)=>void }> = ({
    questionDetails, verificationTriggered, passExtraVerification
  }): JSX.Element => {
    switch (questionDetails.questionType) {
      case 'dropdown':
        return <DropdownQuestion questionDetails={questionDetails} verificationTriggered={verificationTriggered} passExtraVerification={passExtraVerification} />
      case 'textarea':
        return <TextInputQuestion questionDetails={questionDetails} verificationTriggered={verificationTriggered} passExtraVerification={passExtraVerification} />
      case 'radio':
        return <RadioQuestion questionDetails={questionDetails} verificationTriggered={verificationTriggered} passExtraVerification={passExtraVerification} />
    }

  return <></>
}

export default QuestionElement
