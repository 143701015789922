import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import QuestionElement from './QuestionElement'

export type Questionary = {
  displayQuestion: string
  displayAnswer: { label: string|null; value: string|null }[]
  questionType: string
  dependentQuesArr: []
}

const ExtraQuestionary: React.FC<{ verificationTriggered : number, passExtraAnswersVerification:(verified: boolean)=>void  }> = ({
  verificationTriggered, passExtraAnswersVerification: passExtraVerification
}): JSX.Element => {

  const context = useContext(AppContext)
  let updatedQuestionnaireListData: Questionary[]
  let updatedAnsFormat: { label: string|null; value: string|null }[]
  let verifications: boolean[]
  verifications = []

  const updateVerificationStatus = (value: boolean) => {
    verifications.push(value)

    if (questions === undefined || questions.length === 0) {
      passExtraVerification(true)
    }
    if(verifications.includes(false)){
      passExtraVerification(false)
    }
    else{
      passExtraVerification(true)
    }
  }
  

  const handleQuestionType = (ansData: any) => {
    let quesType = ''
    if (ansData) {
      if (ansData.length === 1) {
        if (ansData[0].label === '') {
          quesType = 'textarea'
        } else {
          quesType = 'dropdown'
        }
      } else if (ansData.length > 2) {
        quesType = 'dropdown'
      } else {
        quesType = 'radio'
      }
    }
    return quesType
  }

  const handleQuestionnaireListData = (data: any) => {
    updatedQuestionnaireListData = []
    const QuestionnaireListArr = data.filter(
      (item: { questionType: string }) => item.questionType === 'Direct'
    )
    if (QuestionnaireListArr && QuestionnaireListArr.length > 0) {
      QuestionnaireListArr.map((item: { answer: string; question: string }) => {
        const ansArr = item.answer.split(';')
        updatedAnsFormat = []
        if (ansArr && ansArr.length > 0) {
          ansArr.map((ans: string) => {
            const frmtdAns = ans.split('<')
            updatedAnsFormat.push({
              label: frmtdAns[0],
              value: ans
            })
          })
        }
        updatedQuestionnaireListData.push({
          displayQuestion: item.question,
          displayAnswer: updatedAnsFormat,
          questionType: handleQuestionType(updatedAnsFormat),
          dependentQuesArr: []
        })
      })
      const uniqueQues = updatedQuestionnaireListData.filter(
        (
          set => f =>
            !set.has(f.displayQuestion) && set.add(f.displayQuestion)
        )(new Set())
      )
      if (context) {
        context!.createWO!.modifiedWoScripts = uniqueQues
      }
    }
  }

  handleQuestionnaireListData(context!.createWO!.woScripts)

  let questions = context!.createWO!.modifiedWoScripts

  if (questions === undefined || questions.length === 0) {
    return <></>
  }

  return (
    <div>
      <div className="rw-container">
        <h5>
          It will help to get the problem fixed quicker, if we understand the
          problem a little more...
        </h5>
      </div>

      {
        questions.map((questionDetails: any) => 
            <QuestionElement questionDetails={questionDetails} verificationTriggered={verificationTriggered} passExtraVerification={val => updateVerificationStatus(val)} />
        )
      }
    </div>
  )
}

export default ExtraQuestionary
