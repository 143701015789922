import React, { useContext } from "react";
import { useNavigate } from "react-router-dom"
import { AppContext, useApiClient } from "../context/AppContext";
import workOrderDetailsApi from "../api/workOrdersDetailsApi";
import assetsApi from "../api/assetsApi";

const ScanResultValidationPage: React.FC<{result: any}> = ({result}) => {
    const apiClient = useApiClient()
    const context = useContext(AppContext)
    const navigateTo = useNavigate();
    let assetCode = result?.code
    if (context) context.asset = result.code
    localStorage.setItem('assetCode',assetCode)
    const siteId = context?.store?.storeCode ? context.store.storeCode : context?.user?.site
    if (result.format === 'code_39') {
      const path = window.location.pathname === '/check_equipment/camera_scanner' ? `/check_equipment/${assetCode}` : '/report_problem/whats_happening';
      if (window.location.pathname === '/report_problem/camera_scanner') {
        assetsApi.getAssetDetails(apiClient, assetCode, siteId).then(data => {
        if (data?.responseStatus === 200 && data?.response && data?.response?.isEquipmentWorking) {
          localStorage.setItem('WorkOrderType', 'WOWithAsset')
          context!.createWO!.assetDetails = data?.response;
          const payload = {
            category: data?.response?.category,
            subcategory: data?.response?.subCategory,
            location: data?.response?.department,
            siteId: context!.store!.storeCode,
            storeType: context!.user!.siteType,
          }
          context!.assetPayload = payload
          navigateTo(path);
        }
        else if (data?.responseStatus === 200 && !data?.response) {
          navigateTo('/location_not_matched')
        }
        else if (data?.responseStatus === 204) {
          navigateTo('/asset_not_present',{ state: {
            assetID: assetCode,
            path: window.location.pathname
          },
        })
      } else if (data?.responseStatus === 500) {
        navigateTo('/apiError', {state: {errorCode: '500'}})
      } else {
        navigateTo('/error')
      }
    })
  }
      else {
        workOrderDetailsApi.checkAssetAction(apiClient, assetCode, 1, 10, siteId).then(data => {
        if (data?.responseStatus === 200) {
          if (data?.response) {
              localStorage.setItem('WorkOrderType', 'WOWithAsset')
              navigateTo(path)
          }
          else if (!data?.response) {
              navigateTo('/location_not_matched')
          }
       }
        else if (data?.responseStatus === 204) {
          navigateTo(
            '/check_equipment/awoinfo', { state: { assetID: assetCode, }}
          )
        }
        else if (data?.responseStatus === 500) {
          navigateTo('/fivehundred')
        }
        else {
          navigateTo('/error')
        }
      })
    }
  }
    return <div>{null}</div>
  }

export default ScanResultValidationPage;