import { ProgressIndicator, ProgressSpinner } from '@jsluna/react'

const LoadingBar = () => (
  <ProgressIndicator
    className="ln-u-justify-content-center"
    loading
    preventFocus
  >
    <ProgressSpinner size="icon" className="ln-u-push-right-sm" />
    Loading...
  </ProgressIndicator>
)

const Loading = (message:any) => {
  if (!message) return <LoadingBar />
  return (
    <div>
      <p className="c-loadingindictor_message">{message.message}</p>
      <LoadingBar />
    </div>
  )
}

export default Loading
