import { useState } from "react";
import { IErrorResponse } from "../types/IErrorResponse";
import logger from "../logging/logger";
import IResourceLoader from "../types/IResourceLoader";

export enum ResourceStatus {
  idle = 'idle',
  busy = 'busy',
  success = 'success',
  failure = 'failure'
}

const isError = (object: any): object is IErrorResponse =>
  object.message !== undefined

const useResourceLoader = <T>(errorMessage: string): IResourceLoader<T> => {
  const [status, setStatus] = useState<ResourceStatus>(ResourceStatus.idle)
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<string>('')

  async function load(loader: () => Promise<T>): Promise<void> {
    setStatus(ResourceStatus.busy);
    setError('');

    await loader()
      .then((res: T) => {

        if (isError(res)) {
          throw new Error(res.message)
        }

        setData(res);
        setStatus(ResourceStatus.success);
        setError("");
      })
      .catch(err => {
        logger.error(err);
        setStatus(ResourceStatus.failure);
        setError(errorMessage);
      });
  }

  return {
    status,
    error,
    data,
    load,
    isReady: status === ResourceStatus.success,
    isBusy: status === ResourceStatus.busy,
    hasError: status === ResourceStatus.failure,
    hasLoaded: status === ResourceStatus.success
  }
}

export default useResourceLoader;
