import { FC } from 'react'
import { IStore } from '../../../types/IStore'


interface StoreProps {
  currentStore: IStore
}

const NavStoreName: FC<StoreProps> = ({currentStore}) => {

  
  if (currentStore) {
    const storeCode = currentStore.storeCode.substring(1,5)

    if (!storeCode) return <span />

    const storeText =
      !currentStore.storeName || currentStore.storeName === ''
        ? `Store ${storeCode}`
        : `${currentStore.storeName} (${storeCode})`

    return <span className="cc-nav-fix">{storeText}</span>
  } else {
    return <></>
  }
}

export default NavStoreName
