import { useContext, useState } from 'react'
import {
  Container,
  Card,
  Form,
  OutlinedButton,
  TextButton,
  Display1,
  Body1,
  Label
} from '@jsluna/react'
import { Link } from 'react-router-dom'
import { AppContext, useApiClient } from '../context/AppContext'
import BackLink from '../components/BackLink'
import helpNumbers from '../utils/helpNumbers'
import assetsApi from '../api/assetsApi'
import { useNavigate } from 'react-router'
import { AssetDetails } from '../types/WorkOrdersDetails/Createworkorder'

const SearchAssetsPage = () => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const navigate = useNavigate()

  const noAssetData = {
    assetNumber: '',
    assetTypeDescription: 'UNKNOWN ASSET',
    department: '',
    manufacturer: '',
    serialNumber: ''
  }

  const [selectedAsset, setSelectedAsset] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [unknownAsset, setUnknownAsset] = useState(false)
  var assetDetails: AssetDetails | undefined
  let searchAssetList

  if (context?.assets) {
    searchAssetList = [...context?.assets, noAssetData]
  }

  const handleSubmitAsset = async() => {
    setSubmitted(true)
    if (selectedAsset === 'Unknown-asset') {
      setUnknownAsset(true)
    }

    assetDetails = await assetsApi.getAssetDetails(apiClient, selectedAsset, context!.store!.storeCode).then(data => {return data?.response})

    if(context){
      context!.createWO!.assetDetails = assetDetails
    }

    navigate('/report_problem/whats_happening')
  }

  const handleSubmitWithoutAsset = async() => {
    let problemTypes = await assetsApi.getAssetProblemTypes(apiClient, context!.assetPayload).then(value => {return value})

      if(context){
        context!.createWO!.problemTypes = problemTypes
      }
      navigate('/report_problem/whats_happening')
  }

  if (unknownAsset) {
    return (
      <Container size="xs">
        <BackLink linkTo={'/report_problem/enter_asset'} />
        <Display1 className="pageTitle">Is it one of these assets?</Display1>
        <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft  ln-u-margin-top*2">
          <Form
            onSubmit={(e: Event) => {
              e.preventDefault()
            }}
          >
            <Display1>You have selected an unknown asset</Display1>
            <Body1>
              <div className="ln-u-padding-ends*2">
              In order to report a problem with <b>{context?.assetPayload?.category}</b>, we need to understand the exact asset that has the problem,so requires an asset tag.
              </div>
              <div className="ln-u-padding-ends*2">In order for you to be able to report this problem, please call the FM Control Centre on :</div>
              <Display1>{helpNumbers.FMControlCentre}</Display1>
                  <Link to={'/fmassist_landing'}>
                    <OutlinedButton
                      type="submit"
                      fullWidth
                      className="ln-u-margin-ends*3"
                    >
                      Home
                    </OutlinedButton>
                  </Link>
            </Body1>
          </Form>
        </Card>
      </Container>
    )
  }

  return (
    <Container size="xs">
      <BackLink linkTo={'/report_problem/enter_asset'} />
      <Display1 className="pageTitle">Is it one of these assets?</Display1>
      <Card className="ln-c-card ln-u-soft-xl ln-u-push-bottom-lg ln-c-card--soft  ln-u-margin-top*2">
        <Form
          onSubmit={(e: Event) => {
            e.preventDefault()
          }}
        >
          {searchAssetList && context?.assets && context?.assets.length > 0 ? (
            <>
              <Label>Assets with those details</Label>

              <ul className="ln-o-bare-list ln-o-bare-list--spaced-sm">
                {searchAssetList.map(itemList => {
                  const mnfctr = itemList.manufacturer
                    ? itemList.manufacturer
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                      itemList.manufacturer.toLowerCase().slice(1)
                    : ''
                  const astdsc = itemList.assetTypeDescription
                    ? itemList.assetTypeDescription
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                      itemList.assetTypeDescription.toLowerCase().slice(1)
                    : ''
                  return (
                    <li className="ln-o-bare-list__item">
                      <div className="ln-c-form-option ln-c-form-option--radio ln-c-form-option--full ln-c-form-option--outlined">
                        <input
                          type="radio"
                          id={
                            itemList.assetNumber
                              ? itemList.assetNumber
                              : 'Unknown-asset'
                          }
                          name={
                            itemList.assetNumber
                              ? itemList.assetNumber
                              : 'Unknown-asset'
                          }
                          className="ln-c-form-option__input"
                          onChange={() =>
                            setSelectedAsset(
                              itemList.assetNumber
                                ? itemList.assetNumber
                                : 'Unknown-asset'
                            )
                          }
                          checked={
                            itemList.assetNumber
                              ? selectedAsset === itemList.assetNumber
                              : selectedAsset === 'Unknown-asset'
                              ? true
                              : false
                          }
                          value={
                            itemList.assetNumber
                              ? itemList.assetNumber
                              : 'Unknown-asset'
                          }
                        />
                        <label
                          className="ln-c-form-option__label"
                          htmlFor={
                            itemList.assetNumber
                              ? itemList.assetNumber
                              : 'Unknown-asset'
                          }
                        >
                          <span>{itemList.assetNumber}</span>
                          <span>
                            {mnfctr} {itemList.serialNumber ? '/' : ''}{' '}
                            {itemList.serialNumber}
                          </span>
                          <span>{astdsc}</span>
                          <span>{itemList.department}</span>
                        </label>
                      </div>
                    </li>
                  )
                })}
              </ul>

              {submitted && selectedAsset === '' ? (
                <div
                  id="radio-button-field-5Validation"
                  className="ln-c-field-info warning-info ln-c-field-info--error m-top-1 ln-u-padding-bottom*2"
                  role="alert"
                >
                  Please select the right asset to continue reporting a problem
                </div>
              ) : (
                ''
              )}

              <OutlinedButton
                type="submit"
                fullWidth
                className="ln-u-margin-bottom*2"
                onClick={() => handleSubmitAsset()}
              >
                Select asset
              </OutlinedButton>
              <Link to={'/report_problem/enter_asset'}>
                <TextButton className="withOAtag" fullWidth>
                  Cancel
                </TextButton>
              </Link>
            </>
          ) : (
            <Body1>
              <Label>0 assets with those details</Label>
              <div>We've not found any assets with those details.</div>

              {context?.createWO?.assetRequirements.isBarcodeRequired ? (
                <>
                  <div className="ln-u-padding-bottom">
                    In order for you to be able to report this problem, please
                    call the FM Control Centre on :
                  </div>
                  <Display1>{helpNumbers.FMControlCentre}</Display1>
                  <Link to={'/fmassist_landing'}>
                    <OutlinedButton
                      type="submit"
                      fullWidth
                      className="ln-u-margin-ends*3"
                    >
                      Home
                    </OutlinedButton>
                  </Link>
                </>
              ) : (
                <>
                  <div>
                    However you can continue to report your problem with the
                    information we've captured.
                  </div>
                  <OutlinedButton
                    type="submit"
                    fullWidth
                    className="ln-u-margin-ends*3"
                    onClick={() => handleSubmitWithoutAsset()}
                  >
                    Next
                  </OutlinedButton>
                </>
              )}
            </Body1>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default SearchAssetsPage
