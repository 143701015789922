import { SelectField, TextAreaField } from '@jsluna/react'
import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'

const DefaultQuestionary: React.FC<{ verificationTriggered: number, passDefaultAnswersVerification:(verified: boolean)=>void }> = ({
  verificationTriggered, passDefaultAnswersVerification: passDefaultVerification
}): JSX.Element => {
  const context = useContext(AppContext)
  const [problemDescription, setProblemDescription] = useState('')

  const verify = () => {
    if (verificationTriggered > 0 && problemDescription === '') {
        passDefaultVerification(false)
      return false
    }
    if (verificationTriggered === 0){
      passDefaultVerification(false)
      return true
    }
    else{
      context!.createWO!.step2 = problemDescription
      passDefaultVerification(true)
      return true
    }
  }

  const storeTypeOptions = [
    { label: 'Store', value: 'SUPERMARKET' },
    { label: 'PFS', value: 'PFS' }
  ]
  const locationOption = context?.createWO?.workOrderLog.location

  return (
    <div>
      <SelectField
        name="store"
        label="Is it at the PFS or store?"
        options={storeTypeOptions}
        value={context?.assetPayload?.storeType}
        disabled
      />
      <SelectField
        name={'problem_area'}
        label="Where is the problem happening?"
        options={[{ label: `${locationOption}`, value: `${locationOption}` }]}
        value={locationOption}
        disabled
      />

      {/* <GPSLocator */}

      <TextAreaField
        name={'problemLocation'}
        label="Details of the problem"
        placeholder="Problem Description ..."
        onChange={(entry: { target: { value: string } }) => {
          setProblemDescription(entry.target.value)
        }}
        characterCount
        maxLength={256}
        error={
          verify()
            ? ''
            : `Let us know what's happening, so we can try and fix it.`
        }
        //   value={(step2Data !== undefined && typeof (step2Data) !== undefined && typeof (step2Data) === 'string' && step2Data) || (step2Data !== undefined && typeof (step2Data) !== undefined && typeof (step2Data) === 'object' && step2Data.problemLocation) || ''}
        required
      />
    </div>
  )
}

export default DefaultQuestionary
