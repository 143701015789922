import { WODetails } from '../types/WorkOrdersDetails/WorkOrderDetailsType'
import { callFMAssistStatus, handleDateDesign } from '../utils/functions'

const HistoryLog: React.FC<{ workOrderDetails: WODetails }> = ({
  workOrderDetails
}) => {

  return (
    <>
      {workOrderDetails.history &&
        workOrderDetails.history.workOrderHistoryDetails.map(history => (
          <div className="ln-u-soft ln-u-border-ends opp-btb">
            <span className={callFMAssistStatus(history.historyType)}>
              {history.historyType}
            </span>
            <div className="ln-u-padding-top*2">{history.comments}</div>
            <div className="ln-u-padding-top*2">
              Updated by <b>{history.eventPerformedBy}</b>{' '}
              {handleDateDesign(history.eventDate, '')}
            </div>
          </div>
        ))}
      <div className="ln-u-soft ln-u-border-ends opp-btb">
        <span className={callFMAssistStatus('New')}>New</span>
        <div className="ln-u-padding-top*2">
          {workOrderDetails!.problemDescription}
        </div>
        <div className="ln-u-padding-top*2">
          <b>{workOrderDetails!.requesterName}</b> reported{' '}
          {handleDateDesign(workOrderDetails!.dateCreated, '')}
        </div>
      </div>
    </>
  )
}
export default HistoryLog
