import { FilledButton, Container } from '@jsluna/react'
import configuration from '../../src/utils/configuration'
import ErrorIcon from '../assets/ErrorIcon'

const UnauthorisedPage = () => {
  const redirect = () => {
    window.location.href.indexOf('https://fmassist.sainsburys.co.uk') > -1
      ? window.location.replace(configuration.hubProdURL)
      : window.location.replace(configuration.hubtest_link)
  }

    return (
      <Container size="xs">
        <div className="unauthorised-container">
          <ErrorIcon />
          <div className="ln-u-text-align-left margin-top-small unauthorise-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
            <h5>You need authorisation to access this content </h5>
            <p>
              FM Assist is for store use only. Your account currently
              doesn&apos;t have access to FM Assist.
            </p>
            <h5>Need access?</h5>
            <p>Please contact the Sainsbury&apos;s Tech Service Desk (STSD).</p>
          </div>
          <FilledButton
            className="unauthorise-colleague-button"
            onClick={() => {
              redirect()
            }}
          >
            Colleague hub
          </FilledButton>
        </div>
      </Container>
    )
}

export default UnauthorisedPage;
