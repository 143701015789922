import configuration from "../utils/configuration"

const VerisaeLanding =()=>{
    window.location.href.indexOf('https://fmassist.sainsburys.co.uk/verisae') > -1
      ? window.location.replace(configuration.verisaeSSOURL)
      : window.location.replace(configuration.verisae_url)
    return (
        <div>Redirecting..
        </div>
      )
}

export default VerisaeLanding