import { Link } from 'react-router-dom'
import { Container } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'

const MaintenanceNotification = ({notificationDetails}: any) => {
  return (
    <div className="alert" role="alert">
      <Container size="xs">
        <Link
          to={{
            pathname: '/messages',
            // state: { notificationDetails.index, notificationDetails }
          }}
        >
        <div className="notification-container">
          <div>{notificationDetails.summary}</div>
          <div>
            <ArrowRight />
          </div>
        </div>
        </Link>
      </Container>
    </div>
  )
}

export default MaintenanceNotification
