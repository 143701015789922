import { Card, Display1, Body1 } from '@jsluna/react'
import { ArrowRight } from '@jsluna/icons'
import { NotificationCardType } from '../../types/Notifications/NotificationsType'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'

const NotificationCard = ({
  key,
  title,
  description,
  workOrderNumber,
  notification
}: NotificationCardType) => {
  const context = useContext(AppContext)
  function logChosenNotificationDetails() {
    if (context) {
      context.notifications!.selectedNotification = notification
    }
  }

  return (
    <Card
      className="op-spacer"
      style={{ cursor: 'pointer' }}
      key={key}
      onClick={() => logChosenNotificationDetails()}
    >
      <div className="notify-wrap">
        <Display1> {title} </Display1>
        <div className="ln-u-padding-top*2">
          <Body1 className="truncate">
            {workOrderNumber ? (
              <div>
                <Body1 className="recall-rejected">{`WO ${workOrderNumber}`}</Body1>
                <Body1> {` for details`} </Body1>
              </div>
            ) : null}
            {description ?? null}
          </Body1>
        </div>
      </div>
      <div className="rgt-arrow">
        <div className="ln-u-text-align-right">
          <ArrowRight aria-label="Right Arrow" />
        </div>
      </div>
    </Card>
  )
}

export default NotificationCard
