import { Card, Display1 } from '@jsluna/react'
import { reformatCapsMessage } from '../../utils/functions'
import { WODetails } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import moment from 'moment'

const VisitCard: React.FC<{ visit: WODetails }> = ({ visit }) => {
  return (
    <>
      <div className="ln-u-padding-left*3">
        {moment(visit.fixDueDate).format('ddd DD MMM')}
      </div>
      <Card className="op-spacer" key={visit.workOrderNumber}>
        <div className="opp-width">
          {visit?.subcategory ? (
            <Display1>{reformatCapsMessage(visit?.subcategory)}</Display1>
          ) : null}

          <div className="ln-u-padding-top">
            {visit.assetNumber ? (
              <span>
                <span className="ln-u-color-grey-50">{visit.assetNumber}</span>
                {' / '}
              </span>
            ) : null}
            WO {visit.workOrderNumber}
          </div>

          <div className="ln-u-padding-top*4">
            <Display1>{visit?.providerName}</Display1>
          </div>
          <div className="ln-u-color-grey-50">
            Engineer needs to visit by{' '}
            {moment(visit.fixDueDate).format('ddd DD MMM')}
          </div>
        </div>
      </Card>
    </>
  )
}

export default VisitCard
