export default {
  hubtest_link: 'https://hub-test.retail-azure.js-devops.co.uk',
  hubProdURL: 'https://hub.sainsburys.co.uk/',
  timeout_in_minutes: '15',
  verisae_url:
    'https://myapps.microsoft.com/signin/Verisae/8d7847a1-599f-4736-bffd-05942a81d73e?tenantId=e11fd634-26b5-47f4-8b8c-908e466e9bdf',
  verisaeSSOURL:
    'https://myapps.microsoft.com/signin/Verisae/0cfe1c46-9a32-495c-95cd-1697603058e4?tenantId=e11fd634-26b5-47f4-8b8c-908e466e9bdf',
  encryption_key: 'u001AT%87ub@*%#@',
  encryption_iv: ')~%^2rT^I42&^r11'
}
