import { ProgressIndicator, ProgressSpinner } from '@jsluna/react'
import VerisaeLogo from '../assets/icons/Verisae_logo.png'
import { useUserActions } from '../hooks'
import { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import VerisaeLanding from './VerisaeLanding'
import ErrorMessage from '../components/errors/ErrorMessage'
import { AppContext } from '../context/AppContext'

const VerisaeLoading = () => {
  const context = useContext(AppContext)
  const [userActionsResource, loadUserActions] = useUserActions()

  useEffect(() => {
    loadUserActions.current('verisae')
  }, [loadUserActions])

  // if (context && userActionsResource.hasLoaded && !userActionsResource.hasError) {
    ReactGA.event({
      category: 'Verisae',
      action: 'Open verisae app ',
      label: 'Verisae App',
    })
    return <VerisaeLanding />
  // }

  // if (userActionsResource.hasError) return <ErrorMessage />

  // return (
  //   <div id="main-content" className="ln-u-soft-top ln-c-header-body">
  //     <div className="cb-c-banner-stack" />
  //     <div className="ln-o-container ln-u-padding-sides*2 ln-u-padding-bottom*4 margin-top">
  //       <div className="ln-u-text-align-center">
  //         <img className="logo" src={VerisaeLogo} alt="VerisaeLogo" />
  //         <div className="ln-u-text-align-left">
  //           <h2 className="verisaelogo">Verisae</h2>
  //           <p className="positionImage">Get FM Problems fixed!</p>
  //           <ProgressIndicator
  //             className="ln-u-justify-content-center"
  //             loading
  //             preventfocus
  //           >
  //             <ProgressSpinner className="ln-u-push-right-sm" />
  //           </ProgressIndicator>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default VerisaeLoading
