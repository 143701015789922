import { Link } from 'react-router-dom'
import { Header, Container, FilledButton } from '@jsluna/react'
import ErrorIcon from '../../assets/ErrorIcon'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import helpNumbers from '../../utils/helpNumbers'

const NoStoreFoundMessage = () => {
  const context = useContext(AppContext)
  const probablyPhone =
        /iphone|android|ie|blackberry|fennec/.test(
          navigator.userAgent.toLowerCase(),
        ) && 'ontouchstart' in document.documentElement

  return (
    <div>
      <Header />
      <Container size="xs">
        <div className="generic-container">
          <ErrorIcon />
          <div className="ln-u-text-align-left margin-top-small generic-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
            <h5>Unable to see work order</h5>
            <p>
              You don't have access to see work orders in {context?.store?.storeName} ({context?.store?.storeCode}). 
              Check the store in FM Assist or Refrigeration app and try again. If you should have access, contact the Sainsbury's Tech Service Desk (STSD) on 
              {!probablyPhone ? (
                  <strong> {helpNumbers.ServiceDesk}</strong>
                ) : (
                    <strong>
                      <a href={"tel:" + helpNumbers.ServiceDesk}> {helpNumbers.ServiceDesk} </a>
                    </strong>
                  )}{}.
            </p>
          </div>
          <Link to="/problem_overview/open">
            <FilledButton className="generic-home-button">View open work orders</FilledButton>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default NoStoreFoundMessage
