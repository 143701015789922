import { useContext } from 'react'
import { Container, Display1 } from '@jsluna/react'
import BackLink from '../components/BackLink'
import { AppContext } from '../context/AppContext'
import NotificationCard from '../components/cards/NotificationCard'
import { Link } from 'react-router-dom'

const NotificationPage = () => {
  const context = useContext(AppContext)

  return (
    <div>
      <Container size="xs">
        <BackLink linkTo={'/fmassist_landing'} />
        <div className="ln-u-padding-bottom*2">
          <Display1>Issues that could be affecting your store</Display1>
        </div>
        {context?.notifications?.maintenanceNotifications &&
          context?.notifications?.maintenanceNotifications.map(notification => (
            <Link
              to={`/messages/${notification.id}`}
              className="a-no-link-style"
            >
              <NotificationCard
                key={notification.id}
                title={notification.summary}
                notification={notification}
                description={notification.description}
              />
            </Link>
          ))}
        {context?.notifications?.rejectedRecallNotifications &&
          context?.notifications?.rejectedRecallNotifications.map(
            notification => (
              <Link
              to={`problem_overview/closed/WO${notification.description}`}
              className="a-no-link-style"
            >
              <NotificationCard
                key={notification.description}
                title={'Recall has been processed, please review'}
                notification={notification}
                workOrderNumber={notification.description}
              />
              </Link>
            )
          )}
      </Container>
    </div>
  )
}

export default NotificationPage
