import React from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'


function AssistViewFilter(props: any) {
  const { pathname } = useLocation()

  if (pathname === '/' || pathname.match(/^\/switchboard(\/.*)?$/)) {
    return null
  }

  return React.cloneElement(props.children, {
    ...props,
    ...props.children.props,
    className: classNames(props.className, props.children.props.className),
  })
}

export default AssistViewFilter