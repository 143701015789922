import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Display1, Body1 } from '@jsluna/react'
import { WorkOrdersDashboardType } from '../../types/WorkOrdersDetails/WorkOrderDetailsType'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

const RecentlyClosedProblems: React.FC<{
  workOrdersDashboard: WorkOrdersDashboardType | undefined
}> = ({ workOrdersDashboard }) => {
  const context = useContext(AppContext)

  function trackGACanBeRecalled() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Recently closed from Overview Dashboard',
        action: 'Recently closed from Overview Dashboard',
        label: 'Recently closed from Overview Dashboard'
      })
    }
  }

  function trackGARecalled() {
    if (!context?.user?.isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Recalled from Overview Dashboard',
        action: 'Recalled from Overview Dashboard',
        label: 'Recalled from Overview Dashboard'
      })
    }
  }

  return (
    <div className="RecentlyClosedProblems">
      <div className="ln-c-card ln-c-card--soft card-tiles">
        <Display1>Closed in the last 7 days </Display1>
        <div className="ln-u-padding-bottom*2">
          <Body1>Problems to check</Body1>
        </div>
        <div className="ln-o-grid ml-spacer">
          <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 canBeRecalled">
            <Link to="/problem_overview/recently_closed">
              <div
                className="ln-u-soft ln-u-bg-color-red"
                onClick={() => trackGACanBeRecalled()}
              >
                <span className="label">
                  {workOrdersDashboard!.totalCountOfReviewFixWorkOrders}
                </span>
                <p> Recently closed </p>
              </div>
            </Link>
          </div>
          <div className="ln-o-grid__item pl-spacer ln-u-1/2 ln-u-6/12 RecalledProb">
            <Link to="/problem_overview/recalled">
              <div
                className="ln-u-soft ln-u-bg-color-light-red"
                onClick={() => trackGARecalled()}
              >
                <span className="label">
                  {workOrdersDashboard!.totalCountOfRecalledWorkOrders}
                </span>
                <p> Recalled </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentlyClosedProblems
