import React from 'react'
import Quagga from 'quagga'
import PropTypes from 'prop-types'

class Scanner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            assetID: '',
        }
        this._onDetected = this._onDetected.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({ assetID: event.target.value })
    }

    render() {
        return (
            <div id="interactive" className="viewport">
                {null}
            </div>
        )
    }

    componentDidMount() {
        Quagga.init(
            {
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        width: 1280,
                        height: 720,
                        facingMode: 'environment', // or user 
                    },
                },
                numOfWorkers: 4,
                decoder: {
                    readers: ['code_39_reader'],
                },
                locate: true,
                multiple: false,
                locator: {
                    halfSample: true,
                    patchSize: 'large', // x-small, small, medium, large, x-large
                    debug: {
                        showCanvas: false,
                        showPatches: false,
                        showFoundPatches: false,
                        showSkeleton: false,
                        showLabels: false,
                        showPatchLabels: false,
                        showRemainingPatchLabels: false,
                        boxFromPatches: {
                            showTransformed: false,
                            showTransformedBox: false,
                            showBB: false,
                        },
                    },
                },
            },
            function () {
                Quagga.start()
            },
        )
        Quagga.onProcessed(function (result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        parseInt(drawingCanvas.getAttribute('width')),
                        parseInt(drawingCanvas.getAttribute('height')),
                    )
                    result.boxes
                        .filter(function (box) {
                            return box !== result.box
                        })
                        .forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                color: '#f06c00',
                                lineWidth: 2,
                            })
                        })
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: '#00F',
                        lineWidth: 2,
                    })
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: 'x', y: 'y' },
                        drawingCtx,
                        { color: 'red', lineWidth: 3 },
                    )
                }
            }
        })
        Quagga.onDetected(this._onDetected)
    }

    componentWillUnmount() {
        Quagga.offDetected(this._onDetected)
        Quagga.stop()
    }

    _onDetected(result) {
        const drawingCanvas = Quagga.canvas.dom.overlay
        drawingCanvas.style.display = 'none'
        Quagga.stop()
        this.props.onDetected(result)
    }
}

Scanner.propTypes = {
    onDetected: PropTypes.func.isRequired,
}
export default Scanner;