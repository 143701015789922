import { Link, NavLink, useLocation } from 'react-router-dom'
import { Display3 } from '@jsluna/typography'
import {
  Header as LunaHeader,
  HeaderLogo,
  HeaderNav,
  HeaderMainBar,
  HeaderGlobalItem
} from '@jsluna/header'
import { Body1, Caption } from '@jsluna/react'
import HeaderGlobalBar from './CustomHeaderGlobalBar'
import {
  ChangeStore,
  NavCurrentUser,
  NavStoreName,
  SainsburysLogo
} from './nav'
import SignOutButton from '../auth/SignOutButton'
import { useContext } from 'react'
import { AppContext, useApiClient } from '../../context/AppContext'
import ReactGA from 'react-ga'
import FMAssistLoading from '../../pages/FmAssistLoading'
import { Tutorial, OpenButton } from '@jsainsburyplc/retail-stateful-tutorial'
import AssistViewFilter from '../AssistViewFilter'

const NavHeader = () => {
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const { pathname } = useLocation()
  if (context == null || context.user === undefined) return <FMAssistLoading />

  const userAccessFlag = context?.user?.isReadOnly === '0'
  const isAdmin = context?.user?.isAdmin
  const hasSecondaryStore = context?.user?.secondarySite != null
  const environment =
    window.location.href.indexOf('https://fmassist.sainsburys.co.uk') > -1
      ? 'prod'
      : 'dev'
  const tutorialVersion = 1 //context.user.tutorialsVersion ?? 1

  const trackGA = () => {
    if (!isAdmin) {
      ReactGA.event({
        category: 'FM Assist - Start of Reporting a Problem (Home)',
        action: 'Report a Problem button clicked',
        label: 'Report a Problem'
      })
    }
  }

  const TrackTutorial = () => {
    if (!isAdmin) {
      ReactGA.event({
        category: 'FM Assist - New Tutorial Services',
        action: 'Clicked on Tutorial Services',
        label: 'Tutorial Services'
      })
    }
  }

  const fetch = (url: any, options: any) => {
    return apiClient.fetchAuth(url, options)
  }

  return (
    <Tutorial
      project="fm-assist"
      version={tutorialVersion}
      fetch={fetch}
      environment={environment}
    >
      <LunaHeader divided hasGlobalBar hasMainBar>
        <HeaderGlobalBar
          label="Group navigation"
          element="nav"
          className={undefined}
          size={undefined}
        >
          <HeaderGlobalItem>
            <SainsburysLogo />
          </HeaderGlobalItem>
          <HeaderGlobalItem align="right" drawer="true" tabbar="md">
            <span id="assist-user-store">
              <NavCurrentUser /> in{' '}
              <NavStoreName currentStore={context!.store!} />
            </span>
          </HeaderGlobalItem>
          <HeaderGlobalItem>
            <OpenButton>
              {(showTutorial: any) => (
                <Body1
                  onClick={() => {
                    TrackTutorial()
                    showTutorial()
                  }}
                  aria-label="Tutorial"
                  style={{ cursor: 'pointer' }}
                  className=" ln-c-topbar__text ln-u-text-decoration-underline"
                >
                  Tutorial
                </Body1>
              )}
            </OpenButton>
          </HeaderGlobalItem>
          {isAdmin || hasSecondaryStore ? (
            <HeaderGlobalItem>
              <span id="assist-nav-link-stores">
                <ChangeStore />
              </span>
            </HeaderGlobalItem>
          ) : null}

          <HeaderGlobalItem>
            <span id="assist-nav-link-signOut">
              <SignOutButton />
            </span>
          </HeaderGlobalItem>
        </HeaderGlobalBar>

        <HeaderMainBar size="nav">
          <HeaderLogo>
            <Display3 id="fm-assist-logo">
              <NavLink
                to="/"
                style={{ textDecoration: 'none' }}
                className="ln-u-color-alpha"
              >
                FM Assist
              </NavLink>
            </Display3>
          </HeaderLogo>
          <HeaderNav openDropdownOnHover drawer tabBar="sm" label="Main">
            <AssistViewFilter active={pathname.indexOf('/fmassist_landing') > -1}>
              <Link id="fm-assist-landing" to="/fmassist_landing">
                Home
              </Link>
            </AssistViewFilter>
            {userAccessFlag ? (
              <AssistViewFilter
                active={pathname.indexOf('/report_problem') > -1}
              >
                <Link
                  to="/report_problem/enter_asset"
                  onClick={() => trackGA()}
                >
                  Report a problem
                </Link>
              </AssistViewFilter>
            ) : null}
            <AssistViewFilter active={pathname.indexOf('/problem_overview') > -1}>
              <NavLink to="/problem_overview">Problem overview</NavLink>
            </AssistViewFilter>
            <AssistViewFilter active={pathname.indexOf('/find_workorder') > -1}>
              <NavLink to="/find_workorder">Find work order</NavLink>
            </AssistViewFilter>
            <AssistViewFilter active={pathname.indexOf('/check_equipment') > -1}>
              <NavLink to="/check_equipment">Check equipment status</NavLink>
            </AssistViewFilter>
            <AssistViewFilter active={pathname.indexOf('/useful_numbers') > -1}>
              <NavLink to="/useful_numbers">Useful numbers</NavLink>
            </AssistViewFilter>
            {context?.notifications?.maintenanceNotifications ? (
              <AssistViewFilter active={pathname.indexOf('/messages') > -1}>
                <NavLink to="/messages">Messages</NavLink>
              </AssistViewFilter>
            ) : (
              []
            )}
          </HeaderNav>

          <HeaderNav
            align="center"
            drawer
            className="a-u-hidden@max-nav mobile-navBar"
          >
            <Caption>
              <span id="assist-user-store">
                <NavCurrentUser /> in{' '}
                <NavStoreName currentStore={context!.store!} />
              </span>
            </Caption>
            <Caption>
              <ChangeStore />
            </Caption>
            {/* <Caption>
            <WhatsNew />
          </Caption> */}
            {/* <Caption>
            {' '}
            <PassiveFeedback />
          </Caption> */}
            <Caption>
              {' '}
              <SignOutButton />
            </Caption>
          </HeaderNav>
        </HeaderMainBar>
      </LunaHeader>
    </Tutorial>
  )
}

export default NavHeader
