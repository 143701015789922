import { FilledButton, Container } from '@jsluna/react'
import helpNumbers from '../../src/utils/helpNumbers'
import { useNavigate } from 'react-router'
import ErrorIcon from '../assets/ErrorIcon'

const LocationNotMatched = () => {
    const navigate = useNavigate()
    const probablyPhone = 
        /iphone|android|ie|blackberry|fennec/.test(
          navigator.userAgent.toLowerCase(),
        ) && 'ontouchstart' in document.documentElement
    const redirect = () => navigate(-1)
    return (
      <div>
        <Container size="xs">
          <div className="awoinfo-container">
            <ErrorIcon />
            <div className="ln-u-text-align-left margin-top-small awoinfo-inner-container ln-u-padding-sides*2 ln-u-padding-bottom*4">
              <h5>This asset doesn&apos;t seem to be from your store</h5>
              <p>
                If you&apos;re working in another store at the moment, please
                get a colleague who normally works in this store to report this
                problem for you.
              </p>

              <p>
                If you normally work in this store, please contact the HR Help
                Desk to check your details on
                {!probablyPhone ? (
                  <strong> {helpNumbers.HRHelpDesk}</strong>
                ) : (
                    <strong>
                      <a href={"tel:" + helpNumbers.HRHelpDesk}> {helpNumbers.HRHelpDesk} </a>
                    </strong>
                  )}
                .
              </p>
              <p>
                If the asset is incorrect and is located in your main store,
                please contact the FM Control Centre on
                {!probablyPhone ? (
                  <strong> {helpNumbers.FMControlCentre}</strong>
                ) : (
                    <strong>
                      <a href={"tel:" + helpNumbers.FMControlCentre}> {helpNumbers.FMControlCentre}</a>
                    </strong>
                  )}
                .
              </p>
            </div>
            <FilledButton
              className="awoinfo-missing-button"
              onClick={() => {
                redirect()
              }}
            >
              Rescan
            </FilledButton>
          </div>
        </Container>
      </div>
    ) 
}

export default LocationNotMatched