import { useEffect, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import useNotifications from '../../hooks/useNotifications'
import FailureNotification from './FailureNotification'
import MaintenanceNotification from './MaintenanceNotification'
import RejectedRecallNotification from './RejectedRecallNotification'

const Notification = () => {
  const context = useContext(AppContext)
  const [response, loader] = useNotifications()

  useEffect(() => {
    loader.current(context!.store!.storeCode)
  }, [context!.store!.storeCode, loader])

  const failureNotifications = response?.data?.filter(
    data => data.notificationType === 'Failure'
  )
  if (context && failureNotifications && failureNotifications!.length > 0){
    context!.notifications!.failureNotifications = failureNotifications
  }

  const maintenanceNotifications = response?.data?.filter(
    data => data.notificationType === 'Maintenance'
  )
  if (context && maintenanceNotifications && maintenanceNotifications!.length > 0){
    context!.notifications!.maintenanceNotifications = maintenanceNotifications
  }

  const rejectedRecallNotifications = response?.data?.filter(
    data => data.notificationType === 'RejectedRecalls'
  )
  if (context && rejectedRecallNotifications && rejectedRecallNotifications!.length > 0){
    context!.notifications!.rejectedRecallNotifications = rejectedRecallNotifications
  }

  return (
    <div>
      {failureNotifications !== undefined && failureNotifications?.length > 0
        ? failureNotifications?.map(n => (
            <FailureNotification notificationDetails={n} />
          ))
        : null}

      {maintenanceNotifications !== undefined &&
      maintenanceNotifications?.length > 0
        ? maintenanceNotifications?.map(n => (
            <MaintenanceNotification notificationDetails={n} />
          ))
        : null}

      {rejectedRecallNotifications !== undefined &&
      rejectedRecallNotifications?.length > 0
        ? rejectedRecallNotifications?.map(n => (
            <RejectedRecallNotification notificationDetails={n} />
          ))
        : null}
    </div>
  )
}

export default Notification
