import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import assetsApi from '../api/assetsApi'
import IResourceLoader from '../types/IResourceLoader'
import { ILoadAssetCategories } from '../types/AssetCategories/ILoadAssetCategories'
import { FetchAssetCategories } from '../types/AssetCategories/FetchAssetCategories'

const useAssetLocations = (
  siteType: string
): [IResourceLoader<Array<string>>, ILoadAssetCategories] => {
  const apiClient = useApiClient()

  const response = useResourceLoader<Array<string>>(
    'Unable to load asset locations'
  )

  const loader = useCallbackRef<FetchAssetCategories>(() =>
    response.load(
      async () =>
        await assetsApi.getAssetLocations(apiClient, siteType)
    )
  )

  return [response, loader]
}

export default useAssetLocations
