import { ApiClient } from './ApiClient'
import webApi from './webApi'
import {
  AssetDetailsType,
  AssetRequirements
} from '../types/AssetCategories/AssetDetailsType'
import { AssetDetailsWithStatus } from '../types/WorkOrdersDetails/Createworkorder'

const getAssetCategories = async (
  apiClient: ApiClient,
  siteType: string
): Promise<Array<string>> =>
  await webApi.get<Array<string>>(apiClient, {
    url: `AssetDetails/GetAssetCategoriesAsync/${siteType}`,
    params: []
  })

const getAssetLocations = async (
  apiClient: ApiClient,
  siteType: string
): Promise<Array<string>> =>
  await webApi.get<Array<string>>(apiClient, {
    url: `AssetDetails/GetWorkCentersAsync/${siteType}`,
    params: []
  })

async function getAssetSubCategories(
  apiClient: ApiClient,
  assetCategory: string,
  siteType: string
): Promise<Array<string>> {
  const siteEncrypted = encodeURIComponent(siteType)
  const response = await webApi.get<Array<string>>(apiClient, {
    url: `AssetDetails/GetAssetSubCategoriesAsync?siteType=${siteEncrypted}&parentCategory=${assetCategory}`,
    params: []
  })
  return response
}

const getAssetDescription = async (apiClient: ApiClient, category: string) => {
  return await webApi.get<Array<string>>(apiClient, {
    url: `AssetDetails/GetCategoryDescriptionAsync/?category=${category}`,
    params: []
  })
}

const getAssetRequirements = async (
  apiClient: ApiClient,
  category: string,
  subcategory: string
) => {
  return await webApi.get<AssetRequirements>(apiClient, {
    url: `AssetDetails/IsCategoriesAsync?category=${subcategory}&parentCategory=${category}`,
    params: []
  })
}

const searchAsset = async (
  apiClient: ApiClient,
  payload: any
): Promise<AssetDetailsType[]> => {
  const category = encodeURIComponent(payload.category)
  const subcategory = encodeURIComponent(payload.subcategory)
  const location = encodeURIComponent(payload.location)
  const siteId = encodeURIComponent(payload.siteId)

  const response = await webApi.get<AssetDetailsType[]>(apiClient, {
    url: `AssetDetails/GetSearchAssetDetails/?siteId=${siteId}&category=${category}&subCategory=${subcategory}&location=${location}`,
    params: []
  })
  return response
}

const getAssetDetails = async (
  apiClient: ApiClient,
  payload: any,
  siteId: string
): Promise<AssetDetailsWithStatus> => {
  const resp = await webApi.getWithStatus(apiClient, {
    url: `AssetDetails/${payload}/${siteId}`,
    params: []
  })

  const responseStatus = resp?.status
    const response = responseStatus === 200 ? await resp?.json() : null
    return {
      responseStatus,
      response
    }
}

const getWorkOrderScripts = async (
  apiClient: ApiClient,
  payload: any
): Promise<{}> => {
  const response = await webApi.get<{}>(apiClient, {
    url: `AssetDetails/GetWOScriptsAsync/?problemType=${payload.problemType}&location=${payload.location}&assetCategory=${payload.assetCategory}&siteType=${payload.siteType}&assetSubCategory=${payload.assetSubCategory}`,
    params: []
  })
  return response
}

const getAssetProblemTypes = async(
  apiClient: ApiClient,
  payload: any
): Promise<{}> => {
  const subCategory = payload.subcategory.replace(/&/g, '%26')
  const response = await webApi.get<{}>(apiClient, {
    url: `AssetDetails/GetProblemTypesAsync?category=${subCategory}&parentCategory=${payload.category}`,
    params: []
  })
  return response
}

const getAssetCategoriesForSearch = async(
  apiClient: ApiClient,
  siteCode: string
): Promise<any> => {
  const response = await webApi.get(apiClient, {
    url: `SearchFilters/GetCategories/${siteCode}`,
    params: []
  })
  return response;
}

const getAssetLocationsForSearch = async(
  apiClient: ApiClient,
  siteCode: string
): Promise<any> => {
  const response = await webApi.get(apiClient, {
    url: `SearchFilters/GetLocations/${siteCode}`,
    params: []
  })
  return response;
}

const assetsApi = {
  getAssetCategories,
  getAssetSubCategories,
  getAssetLocations,
  searchAsset,
  getAssetDescription,
  getAssetRequirements,
  getAssetDetails,
  getWorkOrderScripts,
  getAssetProblemTypes,
  getAssetCategoriesForSearch,
  getAssetLocationsForSearch
}

export default assetsApi
