import { ApiClient } from './ApiClient'
import webApi from './webApi'
import { StoreCareTeamType } from '../types/StoreCareTeam/StoreCareTeamType'

async function getStoreCareTeam(
  apiClient: ApiClient,
  siteId: string
): Promise<StoreCareTeamType> {
  const response = await webApi.get<StoreCareTeamType>(apiClient, {
    url: `userManagement/StoreCareTeam?siteNum=${siteId}`,
    params: []
  })
  const responseData = await response
  return responseData
}

const storeCareTeam = {
  getStoreCareTeam
}

export default storeCareTeam
