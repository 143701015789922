import { ProgressIndicator, ProgressSpinner } from '@jsluna/react'
import FMLogo from '../assets/icons/FM_assist_logo.png'
import { useUserActions } from '../hooks'
import { useContext, useEffect } from 'react'

import {
  Container,
  SiteLayout,
  Footer
} from '@jsluna/react'

import ErrorMessage from '../components/errors/ErrorMessage'
import { AppContext, useApiClient } from '../context/AppContext'
import { useNavigate } from 'react-router'
import { IStore } from '../types/IStore'
import userActionsApi from '../api/userActionsApi'
import WorkOrderDetailsPage from './WorkorderDetailsPage'
import NavHeader from '../components/Header/NavHeader'

const FMAssistLoading = () => {
  const navigateTo = useNavigate()
  const context = useContext(AppContext)
  const apiClient = useApiClient()
  const [userActionsResource, loadUserActions] = useUserActions()

  useEffect(() => {
    loadUserActions.current('fmassist')
  }, [loadUserActions])

  if (
    context &&
    userActionsResource.hasLoaded &&
    !userActionsResource.hasError
  ) {
    const store: IStore = {
      storeCode: userActionsResource.data?.site ?? '',
      storeName: userActionsResource.data?.siteName,
      inScope: userActionsResource.data?.isSiteActive ?? false
    }
    context.store = store
    context.user = userActionsResource.data

    const loggingFromRefrigeration = async (url: URL, siteNumber: string | null, siteName: string | null) => {
      if (siteNumber && siteName && (context!.user!.isAdmin || context!.user!.newlyAssignedSites.includes(siteNumber))) {
        await userActionsApi.updateStoreDetails(apiClient, siteNumber, siteName, true).then(response => {
          const store: IStore = {
          storeCode: response?.siteId ?? '',
          storeName: response?.siteName,
          inScope: response !== undefined
        }
        context!.store = store
        navigateTo(window.location.pathname + window.location.search)
        })
      }
      else {
        const store: IStore = {
          storeCode: siteNumber ?? '',
          storeName: siteName ?? '',
          inScope: false
        }
        context!.store = store
        navigateTo('/noStoreFound')
      }
    }

    if (window.location.href.includes('app=refrigeration')) {
      const url = new URL(window.location.href)
      const siteNumber = url.searchParams.get('storeNumber')
      const siteName = url.searchParams.get('storeName')
      loggingFromRefrigeration(url, siteNumber, siteName)
    }
    else navigateTo('/fmassist_landing')
  }

  if (userActionsResource.hasError) return <ErrorMessage />

  if (window.location.search.includes('refrigeration') && context?.store?.storeCode) {
    return (
      <SiteLayout>
        <NavHeader />
        <Container size="md" soft>
          <main id="main-content" className="ln-u-push-top-md">
          <WorkOrderDetailsPage />
          </main>
        </Container>
        <Footer>&copy; Sainsbury&apos;s Supermarkets Ltd</Footer>
      </SiteLayout>
    )
  }

  if (window.location.pathname !== '/'){
    return null
  }

  return (
    <div id="main-content" className="ln-u-soft-top ln-c-header-body">
      <div className="cb-c-banner-stack" />
      <div className="ln-o-container ln-u-padding-sides*2 ln-u-padding-bottom*4 margin-top">
        <div className="ln-u-text-align-center">
          <img src={FMLogo} alt="FMImage" className="imageLogo" />
          <div className="ln-u-text-align-left">
            <h2 className="verisaelogo">FM Assist</h2>
            <p className="positionImage">Get FM problems fixed!</p>
            <ProgressIndicator
              className="ln-u-justify-content-center"
              loading
              preventfocus="true"
            >
              <ProgressSpinner className="ln-u-push-right-sm" />
            </ProgressIndicator>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FMAssistLoading