import { Container } from '@jsluna/react'
import ScanResultValidationPage from './ScanResultValidationPage'
import Scanner from '../components/SearchWorkOrder/Scanner'
import BackLink from '../components/BackLink'
import Loading from '../components/Loading/Loading'
import { useContext, useEffect, useState } from 'react'
import { AppContext, useApiClient } from '../context/AppContext'
import { useNavigate } from 'react-router-dom'
import userActions from '../api/userActionsApi'

const CameraScanner = () => {
    let greenAudio = null;
    const [showCurrentComp, setShowCurrentComp] = useState(false);
    const [results, setResults] = useState([]);
    const [scanning, setScanning] = useState(true);

    const navigate = useNavigate()
  const context = useContext(AppContext)
  const apiClient = useApiClient()

  const pageReloadNotificationData = async () => {
    await userActions.userValidate(apiClient, 'fmassist', null).then(response => {
      if (response?.isReadOnly === "0") {
        setShowCurrentComp(true)
      }
      else {
        navigate('/unauthorised')
      }
    })
  }

  const _onDetected = (result) => {
    greenAudio?.play();
    setScanning(!scanning)
    setResults(results.concat([result]))
  }

  const redirectToScanTimeOut = () => {
    navigate('/scantimeout');
  }

  if (window.performance && performance.navigation.type === 1) {
    pageReloadNotificationData()
}

useEffect(() => {
  if (context?.user) {
    const userFlag = context?.user?.isReadOnly === "0" ? true : false;
    if (userFlag) {
      setShowCurrentComp(true);
    }
    else {
      navigate('/unauthorised');
    }
  } else {
    pageReloadNotificationData()
  }
    const timer = setTimeout(() => redirectToScanTimeOut(), 30000)
    return () => {
    clearTimeout(timer)
    }
})

    return (
        <>
        { showCurrentComp ?
            (
                <div>
                    <Container size="xs">
                        <BackLink
                            onClick={() => {
                                navigate('/report_problem/enter_asset')
                            }}
                        />
                        <div className="overflow">
                            <ul className="results">
                                <audio ref={(green) => {greenAudio = green}}>
                                    <source src="https://s3.amazonaws.com/freecodecamp/simonSound1.mp3" type="audio/mpeg" >
                                    </source>
                                </audio>
                                {results.map(result => ( result?.codeResult?.code &&
                                    <ScanResultValidationPage
                                        key={result.codeResult.code}
                                        result={result.codeResult}
                                    />
                                    ))
                                }
              </ul>
              {scanning ? (
                <Scanner onDetected={_onDetected} />
              ) : null}
            </div>
                    </Container>
                </div>
            )
            :
            (   <Loading /> )
        }
        </>
    )
}

export default CameraScanner;