import { Fragment, useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Card, Body1, Heading5 } from '@jsluna/react'
import BackLink from '../components/BackLink'
import ProgressLoader from '../components/ProgressLoader'
import { AppContext } from '../context/AppContext'
import WOLogging1Step from '../components/WOlogging/WOLogging1Step'
import WOLogging2Step from '../components/WOlogging/WOLogging2Step'
import ContactHelpDesk from '../components/ContactHelpDesk'
import workOrderHooks from '../hooks/useWorkOrderDetails'
import DuplicateWONotification from '../components/notifications/DuplicateWONotification'

const CreateWorkOrder = () => {
  const context = useContext(AppContext)
  const [currentStep, setCurrentStep] = useState(1)
  const stepMessage = `Step ${currentStep} of 2`
  const [response, loader] = workOrderHooks.useDuplicateWorkOrders(
    context!.assetPayload,
    context?.createWO?.assetDetails
  )
  useEffect(() => {
    loader.current(context!.assetPayload)
  }, [context?.assetPayload, loader])

  let helpDeskAvailable: boolean
  if (
    context?.createWO?.assetRequirements?.isHelpdeskAvail ||
    context?.createWO?.assetDetails?.isHelpdeskAvail
  ) {
    helpDeskAvailable = true
  } else {
    helpDeskAvailable = false
  }

  return (
    <div>
      <Container size="xs" className={'provideWrap'}>
        <BackLink />

        {/* <Prompt
          //   when={promptCheckState && !isValidated && !enableWOFlag}
          message="Your new changes will be lost. Are you sure you want to leave this page?"
        /> */}
        <div className="ln-u-padding-bottom*3">
          <Heading5 className="steppageTitle">
            {helpDeskAvailable
              ? ''
              : currentStep === 1
                ? "What's (not) happening?"
                : currentStep === 2
                  ? "What's the problem?"
                  : ''}
          </Heading5>
          <Body1 className="steps">
            {helpDeskAvailable ? '' : stepMessage}
          </Body1>
        </div>
        <Fragment>
          <ProgressLoader currentStep={currentStep} numberOfSteps={2} />
        </Fragment>
        {response.data && response?.data?.totalCountOfWorkOrders > 0 ? (
          <DuplicateWONotification duplicateWorkOrders={response.data} />
        ) : null}
        <Card>
          <Heading5>
            {context?.createWO?.assetDetails?.assetTypeDescription
              ? context?.createWO?.assetDetails?.assetTypeDescription
              : context?.assetPayload?.subcategory}
          </Heading5>
          <div>
            in{' '}
            <b>
              {context?.createWO?.assetDetails?.department
                ? context?.createWO?.assetDetails?.department
                : context?.assetPayload?.location}
            </b>
          </div>
          {currentStep === 2 ? (
            <div>
              with the problem of{' '}
              <b>{context?.createWO?.workOrderLog?.problemType}</b>
            </div>
          ) : null}
          <div className="ln-u-padding-bottom*2">
            {context?.createWO?.assetDetails?.assetNumber}
          </div>
          <Link to="/report_problem/enter_asset">Not the right asset?</Link>
        </Card>
        <Card className="ln-u-margin-top*3">
          {helpDeskAvailable === true ? (
            <ContactHelpDesk />
          ) : currentStep === 1 ? (
            <WOLogging1Step
              currentStep={currentStep}
              currentStepChange={setCurrentStep}
            />
          ) : null}
          {currentStep === 2 ? <WOLogging2Step /> : null}
        </Card>
      </Container>
    </div>
  )
}

export default CreateWorkOrder
