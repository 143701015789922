import { useApiClient } from '../context/AppContext'
import { useCallbackRef, useResourceLoader } from '.'
import IResourceLoader from '../types/IResourceLoader'
import notificationApi from '../api/notificationApi'
import { NotificationType } from '../types/Notifications/NotificationsType'
import { FetchNotifications } from '../types/Notifications/FetchNotifications'
import { ILoadNotifications } from '../types/Notifications/ILoadNotifications'

const useNotifications = (): [IResourceLoader<NotificationType[]>, ILoadNotifications] => {
  const apiClient = useApiClient();
  const response = useResourceLoader<NotificationType[]>('Unable to load notifications')

  const loader = useCallbackRef<FetchNotifications>(async(siteId: string) : Promise<NotificationType[]> =>
    response.load(async () => await notificationApi.getNotifications(apiClient, siteId))
  )

  return [response, loader]
}

export default useNotifications
